import React, { memo, useState, useMemo } from 'react'
import { Select } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'
import WarningMessage from 'components/policies/settings/attributes/device/controls/WarningMessage'
import Retrievei18nSelect from 'common/utilityItems/Retrievei18nSelect'

const LabelSelect = (props) => {
  const {
    label,
    value,
    required,
    helpButton,
    message,
    type,
    options,
    ...params
  } = props
  const [search, setSearch] = useState('')
  const items = useMemo(
    () =>
      search
        ? options.filter((item) =>
            item.label.toLowerCase().includes(search.toLowerCase())
          )
        : options,
    [options, search]
  )

  return (
    <>
      {label && (
        <FlexRow className={'alignCenter marginBottom4'}>
          <TextBlack
            className={required ? 'requiredField' : undefined}
            disabled={params.disabled}
          >
            {label}
          </TextBlack>
          <FlexRow className={'paddingLeft4 lineBreak'}>{helpButton}</FlexRow>
        </FlexRow>
      )}
      <Select
        {...params}
        options={items}
        value={value?.[0] ? value : ['']}
        clearIcon={!!params.onClear}
        onSearch={setSearch}
        i18n={Retrievei18nSelect()}
      />
      {message && (
        <WarningMessage
          id={params.id + '.warning'}
          message={message}
          type={type}
        />
      )}
    </>
  )
}

export default memo(LabelSelect)
