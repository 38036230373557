import React, { useState, useMemo } from 'react'
import PolicySettings from 'components/policies/settings'
import PolicyCategories from 'components/policies/categories'
import { FlexColumnTree, FlexColumnRight, FlexRow } from 'styles/styles'
import PoliciesCategories from 'context/policies/PoliciesCategories'

const CreatePolicyStep3 = (props) => {
  const { selectedPolicyAttributes, setSelectedPolicyAttributes } = props

  const [, setState] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState([])

  const selectedAttributes = useMemo(
    () =>
      PoliciesCategories.filterPolicyAttributes(
        selectedCategory,
        selectedPolicyAttributes
      ),
    [selectedPolicyAttributes, selectedCategory]
  )

  const handleRemove = (id) =>
    setSelectedPolicyAttributes(
      selectedPolicyAttributes.filter(({ name }) => name !== id)
    )

  const handleAttributeChange = (attribute) => {
    const attr = selectedAttributes.find(({ name }) => name === attribute.name)
    if (attr) {
      Object.assign(attr, attribute)
      setState((x) => x + 1) // update state to re-render
    }
  }
  return (
    <>
      <FlexRow className={'settingsColumn'}>
        <FlexColumnTree>
          <PolicyCategories
            attributes={selectedPolicyAttributes}
            onChange={setSelectedCategory}
          />
        </FlexColumnTree>
        <FlexColumnRight>
          <PolicySettings
            entitlements={props.entitlements}
            attributes={selectedAttributes}
            onAttributeChange={handleAttributeChange}
            policyConstraints={props.policyConstraints}
            onWarning={props.handleWarning}
            onError={props.handleError}
            onRemove={handleRemove}
            search={true}
            devicePolicy={props.devicePolicy}
            showErrors={props.showErrors}
          />
        </FlexColumnRight>
      </FlexRow>
    </>
  )
}
export default CreatePolicyStep3
