import { SolutionActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case SolutionActions.SET_SOLUTIONS:
      return { ...state, solutions: action.payload }

    case SolutionActions.SET_ICONS:
      return {
        ...state,
        solutions: state.solutions.map((solution) => {
          const icon = action.payload[solution.uuid]
          return icon ? { ...solution, icon } : solution
        })
      }

    case SolutionActions.SET_MOC:
      return { ...state, moc: action.payload }

    default:
      break
  }
  return state
}
