import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import CreatePolicyStep2 from 'components/policies/table/CreatePolicySteps/CreatePolicyStep2'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import grantsContext from 'context/grants/grantsContext'
import { getAttributesWithEntitlement } from 'common/utilities'
import { PolicyAttributeEntitlementEnum } from 'components/policies/constants'

const AddPolicyModal = (props) => {
  const { t } = useContext(configContext)
  const key = (subkey: string): string => {
    return `policy.details.${subkey}`
  }
  const [selectedPolicyAttributes, setSelectedPolicyAttributes] = useState(
    props.selectedPolicyAttributes
  )

  const { hasEntitlement } = useContext(grantsContext)
  const { fleetMgtSvcClient, showError } = useContext(policiesContext)
  const [hasAdvanced, setHasAdvanced] = useState(false)
  const [templates, setTemplates] = useState(null)

  useEffect(() => {
    hasEntitlement(PolicyAttributeEntitlementEnum.ADVANCED, setHasAdvanced)
    getPolicyTemplates()
  }, [])

  const getPolicyTemplates = async () => {
    try {
      const res = await fleetMgtSvcClient.getPolicyTemplates(0, -1)
      if (res?.status === 200 && res.data?.items) {
        setTemplates(res.data.items)
      }
    } catch (error) {
      showError(error)
    }
  }

  const policyAttributes = useMemo(() => {
    if (!templates) {
      return []
    }
    return getAttributesWithEntitlement(
      hasAdvanced
        ? templates
        : templates.filter(
            (x) => x.entitlement !== PolicyAttributeEntitlementEnum.ADVANCED
          )
    )
  }, [hasAdvanced, templates])

  return (
    <Modal
      show={true}
      className={'addPolicyModal'}
      title={t(key('modalTitle'))}
      closeOnBlur={false}
      footer={
        <ButtonGroup>
          <Button
            onClick={() => {
              props.setShowAddPolicyModal(false)
              props.selectedAttrs(selectedPolicyAttributes)
            }}
            id={'addPolicyModalButton'}
          >
            {t('common.add')}
          </Button>
          <Button
            appearance={'secondary'}
            onClick={() => {
              props.setShowAddPolicyModal(false)
            }}
            id={'cancelPolicyModalButton'}
          >
            {t('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <CreatePolicyStep2
        setSelectedPolicyAttributes={setSelectedPolicyAttributes}
        selectedPolicyAttributes={selectedPolicyAttributes}
        policyAttributes={policyAttributes}
      />
    </Modal>
  )
}
export default AddPolicyModal
