import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Checkbox, Modal } from '@veneer/core'
import { isTrueString } from 'common/utilities'
import { FlexRow, TextBlack } from 'styles/styles'

export enum faxSendModemEnum {
  ECM = 'modem-ecm',
  JBIG_COMPRESSION = 'modem-jbig-compression'
}

const FaxSendModemModal = (props) => {
  const { onChange, onClose, getLocalized, checkConstraints } = props
  const [value, setValue] = useState(props.value)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  useEffect(() => {
    setSaveButtonDisabled(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const checkboxComponent = (key) => {
    return (
      <FlexRow key={key} className={'paddingTop16'}>
        <Checkbox
          id={key}
          label={getLocalized(key)}
          onChange={({ target }) => {
            setValue((prevValue) => {
              return { ...prevValue, [key]: target.checked + '' }
            })
          }}
          checked={isTrueString(value[key])}
        />
      </FlexRow>
    )
  }

  const onSave = () => {
    onChange(value)
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveButtonDisabled}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      className={'small-policy-modal'}
      show={true}
      data-testid={'id-fax-send-modem-modal'}
      title={getLocalized('modem-settings')}
    >
      <TextBlack>{getLocalized('modem-description-message')}</TextBlack>

      <TextBlack className={'paddingTop16 bold'}>
        {getLocalized('modem-scan-settings')}
      </TextBlack>

      {[faxSendModemEnum.ECM, faxSendModemEnum.JBIG_COMPRESSION]
        .filter((x) => checkConstraints(x))
        .map((x) => checkboxComponent(x))}
    </Modal>
  )
}

export default FaxSendModemModal
