import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Checkbox, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import BytesOutputItems from 'context/policies/dropboxItems/BytesOutputItems'
import 'styles/global.scss'
import { FlexRow, TextBlack } from 'styles/styles'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import PoliciesErrors, {
  MessageTypesEnum
} from 'context/policies/PoliciesErrors'
import { getError } from 'common/utilities'
import WarningMessage from '../WarningMessage'
import OutgoingServersItems from './OutgoingServersItems'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

export enum outgoingServerEnum {
  ADDRESS = 'address',
  PORT = 'port',
  SPLIT_EMAIL = 'splitEmail',
  SSL = 'ssl',
  VALIDATE_CERT = 'validateCert',
  AUTH = 'auth',
  EMAIL = 'email',
  ALERT = 'alert',
  FAX = 'fax',
  AUTOSEND = 'autosend',
  CREDENTIAL = 'credential',
  ORDER = 'Order', // EMAIL/FAX/ALERT/AUTOSEND + ORDER
  USAGE = 'usage' // UI & constraints only
}

export const usageItems = [
  {
    id: outgoingServerEnum.EMAIL,
    label: 'send-to-email',
    tag: 'usage-email'
  },
  {
    id: outgoingServerEnum.FAX,
    label: 'internet-fax',
    tag: 'usage-fax'
  },
  {
    id: outgoingServerEnum.ALERT,
    label: 'email-alerts',
    tag: 'usage-alert'
  },
  {
    id: outgoingServerEnum.AUTOSEND,
    label: 'autosend',
    tag: 'usage-autosend'
  }
]

const OutgoingServersModal = (props) => {
  const {
    value,
    edit,
    onClose,
    onChange,
    tableData,
    getLocalized,
    constraints
  } = props
  const [selectButtonDisabled, setSelectButtonDisabled] = useState(true)
  const [validateCertDisabled, setValidateCertDisabled] = useState(true)
  const [addressError, setAddressError] = useState(null)
  const [portError, setPortError] = useState(null)
  const [inputValue, setInputValue] = useState(value)
  const [serverUsageError, setServerUsageError] = useState(false)
  const [splitEmailError, setSplitEmailError] = useState(null)

  useEffect(() => {
    setSelectButtonDisabled(
      JSON.stringify(inputValue) === JSON.stringify(value)
    )
  }, [inputValue, value])

  const checkConstraints = (x) =>
    constraints
      ? !!constraints.isPolicyAttributeSettingSupported(
          PolicyAttributeEnum.Outgoing_Servers,
          PolicyAttributeEnum.Outgoing_Servers +
            `.${outgoingServerEnum.USAGE}.` +
            x
        )
      : true

  const handleDuplicateError = (element) => {
    if (
      edit &&
      value[outgoingServerEnum.ADDRESS]?.toLowerCase() ===
        element?.toLowerCase()
    ) {
      return false
    }

    if (
      usageItems.some(({ id }) =>
        tableData[id]?.some(
          (item) =>
            item[outgoingServerEnum.ADDRESS]?.toLowerCase() ===
            element?.toLowerCase()
        )
      )
    ) {
      setAddressError({ message: 'duplicate-error' })
      return true
    }

    return false
  }

  const handleAddressChange = (value) => {
    setAddressError(getError(PoliciesErrors.errorsNotSelected, value))

    handleDuplicateError(value)

    setInputValue((prevValues) => {
      return { ...prevValues, [outgoingServerEnum.ADDRESS]: value }
    })
  }

  const handlePortChange = (value) => {
    setPortError(getError(PoliciesErrors.errorsPortNumber, value))
    setInputValue((prevValues) => {
      return {
        ...prevValues,
        [outgoingServerEnum.PORT]: value
      }
    })
  }

  const handleEmailLimit = (data) => {
    setSplitEmailError(false)
    setInputValue((prevValue) => {
      return { ...prevValue, [outgoingServerEnum.SPLIT_EMAIL]: data.value }
    })
  }

  const handleSelect = () => {
    const valuePresent = BytesOutputItems.bytesSizeItems.some(
      (item) => item.value === inputValue[outgoingServerEnum.SPLIT_EMAIL]
    )
    setSplitEmailError(!valuePresent)

    const isServerUsageSelected = usageItems.some((item) => inputValue[item.id])
    setServerUsageError(!isServerUsageSelected)

    const retrieveAddressError = getError(
      PoliciesErrors.errorsNotSelected,
      inputValue[outgoingServerEnum.ADDRESS]
    )
    setAddressError(retrieveAddressError)

    const duplicateError = handleDuplicateError(
      inputValue[outgoingServerEnum.ADDRESS]
    )

    const retrievePortError = getError(
      PoliciesErrors.errorsPortNumber,
      inputValue[outgoingServerEnum.PORT]
    )
    setPortError(retrievePortError)

    if (
      !retrieveAddressError &&
      !retrievePortError &&
      isServerUsageSelected &&
      !duplicateError &&
      valuePresent
    ) {
      onChange(inputValue)
      onClose()
    }
  }

  const localizeSelectOptions = () => {
    return BytesOutputItems.bytesSizeItems.map((item) => {
      return {
        value: item.value,
        label: getLocalized(item.label)
      }
    })
  }

  const onBoolChange = (target) => {
    // If the checkbox is part of the server usage checkbox list, and the value to be set on it is false, and the current value of checkbox is true, and this checkbox is the last true checkbox in the list of checkboxes, then show the server usage error
    if (usageItems.find((item) => item.id === target.id)) {
      setServerUsageError(
        !target.checked &&
          inputValue[target.id] === true &&
          usageItems.filter((item) => inputValue[item.id] === true).length === 1
      )
    }

    setInputValue((prevValues) => {
      return {
        ...prevValues,
        [target.id]: target.checked
      }
    })
  }

  const onSslChange = (target) => {
    setValidateCertDisabled(!target.checked)
    if (!target.checked && inputValue[outgoingServerEnum.VALIDATE_CERT]) {
      onBoolChange({ id: outgoingServerEnum.VALIDATE_CERT, checked: false })
    }
    return onBoolChange(target)
  }

  const options =
    OutgoingServersItems.outgoingServerCredentialModes(constraints)

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(edit ? 'edit-server' : 'add-server')}
      className={'small-policy-modal'}
      data-testid={'id-outgoing-servers-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={handleSelect} disabled={selectButtonDisabled}>
            {getLocalized(edit ? 'common.save' : 'common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack>
        {getLocalized(
          edit ? 'edit-description-message' : 'add-description-message'
        )}
      </TextBlack>
      <TextBlack className={'paddingTop16 paddingBottom16 bold'}>
        {getLocalized('server-information')}
      </TextBlack>

      <LabelTextBox
        label={getLocalized('server-name-or-address')}
        id={outgoingServerEnum.ADDRESS}
        type={'text'}
        placeholder={getLocalized('enter-name-or-address')}
        value={inputValue[outgoingServerEnum.ADDRESS] || ''}
        onChange={(value) => {
          handleAddressChange(value)
        }}
        error={!!addressError}
        helperText={getLocalized(
          addressError?.message || 'enter-name-or-address',
          addressError?.params
        )}
        className={'paddingBottom16 maxTextWidth'}
      />

      <LabelTextBox
        label={getLocalized('port-number')}
        id={'portNumber'}
        type={'text'}
        placeholder={getLocalized('enter-port-number')}
        value={inputValue[outgoingServerEnum.PORT] || ''}
        onChange={(value) => {
          handlePortChange(value)
        }}
        error={!!portError}
        helperText={getLocalized(
          portError?.message || 'enter-port-number',
          portError?.params
        )}
        className={'paddingBottom16 maxTextWidth'}
      />

      <LabelSelect
        label={getLocalized('split-emails')}
        id={outgoingServerEnum.SPLIT_EMAIL}
        options={localizeSelectOptions()}
        onChange={handleEmailLimit}
        placeholder={getLocalized('common.select-option')}
        value={[inputValue[outgoingServerEnum.SPLIT_EMAIL]]}
        error={splitEmailError}
        helperText={
          splitEmailError && getLocalized('common.errors.not-selected')
        }
        className={'paddingBottom16 maxTextWidth'}
      />

      <Checkbox
        id={outgoingServerEnum.SSL}
        label={getLocalized('enable-smtp-sls')}
        onChange={({ target }) => onSslChange(target)}
        checked={inputValue[outgoingServerEnum.SSL]}
      />

      <FlexRow className={'paddingTop16 devSettingsIndent1'}>
        <Checkbox
          id={outgoingServerEnum.VALIDATE_CERT}
          label={getLocalized('validate-certs')}
          onChange={({ target }) => onBoolChange(target)}
          disabled={!inputValue[outgoingServerEnum.SSL] && validateCertDisabled}
          checked={inputValue[outgoingServerEnum.VALIDATE_CERT]}
        />
      </FlexRow>

      <TextBlack className={'paddingTop16 bold'}>
        {getLocalized('server-authentication')}
      </TextBlack>

      <FlexRow className={'paddingTop16'}>
        <Checkbox
          id={outgoingServerEnum.AUTH}
          label={getLocalized('server-requires-authentication')}
          onChange={({ target }) => {
            onBoolChange(target)
          }}
          disabled={
            !options.find(
              ({ value }) =>
                value === OutgoingServersItems.allCredentialModes[0].value
            )
          }
          checked={inputValue[outgoingServerEnum.AUTH]}
        />
      </FlexRow>

      <LabelRadioButtons
        onChange={(_, value) => {
          setInputValue((prevValue) => {
            return { ...prevValue, [outgoingServerEnum.CREDENTIAL]: value }
          })
        }}
        className={'paddingTop16 devSettingsIndent1'}
        value={inputValue[outgoingServerEnum.CREDENTIAL]}
        id={outgoingServerEnum.CREDENTIAL}
        options={options.map((item) => {
          return {
            value: item.value,
            label: getLocalized(item.label),
            disabled:
              !inputValue[outgoingServerEnum.AUTH] ||
              item.value === OutgoingServersItems.allCredentialModes[1].value
          }
        })}
      />

      <TextBlack className={'requiredField paddingTop16 bold'}>
        {getLocalized('server-usage')}
      </TextBlack>
      {usageItems.map(
        (item) =>
          !!checkConstraints(item.id) && (
            <FlexRow className={'paddingTop16'} key={item.id}>
              <Checkbox
                id={item.id}
                label={getLocalized(item.label)}
                onChange={({ target }) => onBoolChange(target)}
                checked={inputValue[item.id]}
              />
            </FlexRow>
          )
      )}

      {serverUsageError && (
        <WarningMessage
          type={MessageTypesEnum.ERROR}
          message={getLocalized('server-usage-required')}
        />
      )}
    </Modal>
  )
}
export default OutgoingServersModal
