export const MOC = {
  ID: 'id',
  NAME: 'name',
  LABEL: 'label',

  XML: 'xml',
  MOC: 'moc',
  DESCR: 'descr',
  LOCALE: 'locale',
  FRAMES: 'frames',
  FRAME: 'frame',
  FIDGETS: 'fidgets',
  FIDGET: 'fidget',
  SETS: 'sets',
  SET: 'set',
  ITEMS: 'items',
  ITEM: 'item',
  ERRORS: 'errors',
  ERROR: 'error',
  PROPS: 'props',
  PROP: 'prop',

  SCHEMA_VER: 'schemaVersion',
  XMLNS: 'xmlns',
  /*
  XMLNS_VAL: 'http://www.hp.com/schemas/jetadvantage/link/moc/2019/07/18',
  XMLNS_XSI: 'xmlns:xsi',
  SCHEMA_LOCATION: 'xsi:schemaLocation',
  NO_NAMESPACE_SCHEMA_LOCATION: 'xsi:noNamespaceSchemaLocation',
  */

  DEF_LANG: 'en_US',
  OPTIONS: 'options',

  TYPE: 'type',
  TYPES: {
    CHECK: 'check',
    LIST: 'list',
    INPUT: 'input',
    NUMBER: 'number',
    PASSWORD: 'password',
    FILELIST: 'filelist'
  },

  VALUE: 'value',
  VALUES: 'values',
  EDITABLE: 'editable',
  VISIBLE: 'visible',
  SELECTED: 'selected',
  REQUIRED: 'required',
  MULTIVALUED: 'multiValued',
  ISSUES: 'issues',

  BOOL: 'BOOL',
  INT: 'INT',
  PSTR: 'PSTR',
  STR: 'STR',
  FILELIST: 'FILELIST',
  XBOOL: 'XBOOL',
  XPATH: 'XPATH',
  KEY: 'key',
  SAVED: 'saved',
  DEFAULTS: 'defaults',
  EXPRESSION: 'expression',
  REFERENCEDITEMS: 'referenceditems',
  LASTEDITABLE: 'lasteditable',
  LASTVISIBLE: 'lastvisible',
  LASTISSUES: 'lastissues',
  TRUE: 'true',
  FALSE: 'false',

  SEPARATOR: ';',
  FILESIZELIMIT: 40960,

  MOC_PATH: '/moc',
  FRAMES_REL_PATH: '/frames',
  FRAME_FULL_PATH: '/moc/frames/frame',
  FIDGETS_REL_PATH: '/fidgets',
  FIDGET_REL_PATH: '/fidgets/fidget',
  FIDGET_FULL_PATH: '/moc/frames/frame/fidgets/fidget',
  DESCR_REL_PATH: '/descr',
  LOCALE_REL_PATH: '/descr/locale',
  SETS_REL_PATH: '/sets',
  SET_REL_PATH: '/sets/set',
  ITEMS_REL_PATH: '/items',
  ITEM_REL_PATH: '/items/item',
  PROPS_REL_PATH: '/props',
  PROP_REL_PATH: '/props/prop',
  ERRORS_REL_PATH: '/errors',
  ERROR_REL_PATH: '/errors/error',
  ITEM_PROP_REL_PATH: '/items/item/props/prop',
  ERROR_PROP_REL_PATH: '/errors/error/props/prop',

  ERROR_MESSAGE: 'message',
  ERROR_MESSAGES: {
    UNKNOWN_ID: 'solutions.configure.moc.unknown.id', //{param}
    DUPLICATED_ID: 'solutions.configure.moc.duplicated.id', //{param}
    DUPLICATED_STR: 'solutions.configure.moc.duplicated.str', //{param}
    VOID_XPATH: 'solutions.configure.moc.void.xpath',
    WRONG_XPATH: 'solutions.configure.moc.wrong.xpath',
    WRONG_BOOL_XPATH: 'solutions.configure.moc.wrong.bool.xpath',
    WRONG_ATTR: 'solutions.configure.moc.wrong.attr', //{attr}, {elem}
    WRONG_ATTR_VAL: 'solutions.configure.moc.wrong.attr.val', //{value}, {attr}, {elem}, {param}
    NO_ATTR: 'solutions.configure.moc.no.attr', //{attr}, {elem}
    NO_ELEMENT: 'solutions.configure.moc.no.element', //{elem}, {param}
    FEW_ELEMENTS: 'solutions.configure.moc.few.elements', //{elem}, {param}
    WRONG_ELEMENT: 'solutions.configure.moc.wrong.element', //{elem}, {param}
    NO_DEFAULT: 'solutions.configure.moc.no.default',
    MISSED_TRANSLATION: 'solutions.configure.moc.missed.translation', //{param}
    WRONG_LANGUAGE: 'solutions.configure.moc.wrong.language', //{param}
    NO_REQUIRED_ERROR: 'solutions.configure.moc.no.required.error',
    WRONG_REQUIRED_ERROR: 'solutions.configure.moc.wrong.required.error'
  },

  ERROR_WARNINGS: 'warnings',
  ERROR_ERRORS: 'errors',

  ERROR_PARAMS: {
    PARAMETER: 'param',
    ATTRIBUTE: 'attr',
    VALUE: 'value',
    ELEMENT: 'elem'
  },

  DOT: '.',
  CSV: ', '
}

class MocUtils {
  //For needs of inspectMoc()
  /*
  //Parameter with counter
  static paramEx(param, count, limit = 1) {
    return count > limit ? `${param} (${count})` : param
  }
  */

  //Look for pattern
  static extractMiddles(str, beg, end) {
    let start = 0,
      middles = []
    do {
      let i = str.indexOf(beg, start)
      if (i >= 0) {
        i += beg.length
        let j = str.indexOf(end, i)
        if (j > 0) {
          middles.push(str.substring(i, j))
          start = j + 1
          continue
        }
      }
      break
    } while (start < str.length)
    return middles
  }
  /*
  //Look for fidget's ids, set's and item's keys in XPaths string
  static extractIds(xpath, pattern) {
    let Ids = []
    const middles = MocUtils.extractMiddles(xpath, '/' + pattern[0] + '[', ']')
    for (const index of middles) {
      const ids = MocUtils.extractMiddles(index, '@' + pattern[1] + "='", "'")
      for (const i of ids) {
        if (Ids.indexOf(i) < 0) {
          Ids.push(i)
        }
      }
    }
    return Ids
  }

  //Accumulate statistics
  static addStat(stat, key) {
    stat[key] = Object.prototype.hasOwnProperty.call(stat, key) ? stat[key] + 1 : 1
  }

  //Subtract statistics
  static subStat(stat2, stat) {
    for (const item in stat) {
      if (Object.prototype.hasOwnProperty.call(stat2, item)) {
        stat2[item] = stat2[item] - stat[item]
      }
    }
  }

  //Analyze statistics to find max value
  static getMaxStat(stat) {
    let max = 0
    for (const item in stat) {
      if (stat[item] > max) {
        max = stat[item]
      }
    }
    return max
  }
  */

  //Common helpers

  //Comparison of configuration values arrays
  static arraysEqual(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every((value, index) =>
        value instanceof Object
          ? Object.keys(value)[0] === Object.keys(array2[index])[0] &&
            Object.values(value)[0] === Object.values(array2[index])[0]
          : value === array2[index]
      )
    )
  }

  //Merge of configuration objects
  static mergeObjects(A, B) {
    for (const b in B) {
      const hasProperty = Object.prototype.hasOwnProperty.call(A, b)
      const isObject = B[b] === Object(B[b]) && !Array.isArray(B[b])
      if (hasProperty && isObject) {
        MocUtils.mergeObjects(A[b], B[b])
      } else {
        A[b] = B[b]
      }
    }
  }
  /*

  static mergeError(
    results,
    error,
    critical = true,
    param = null,
    fidget = MOC.MOC,
    set = MOC.DOT,
    attr = MOC.DOT
  ) {
    const message =
      '$t("' + error + '"' + (param ? ',' + JSON.stringify(param) : '') + ')'
    const type = critical ? MOC.ERROR_ERRORS : MOC.ERROR_WARNINGS
    if (
      type in results &&
      fidget in results[type] &&
      set in results[type][fidget] &&
      attr in results[type][fidget][set]
    ) {
      results[type][fidget][set][attr].push(message)
    } else {
      MocUtils.mergeObjects(results, {
        [type]: { [fidget]: { [set]: { [attr]: [message] } } }
      })
    }
  }
   */

  //Calculate hash code for array of error strings
  static hashCode(str, hash = 0) {
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash = hash & hash // Convert to 32bit integer
    }
    return hash
  }

  static parentNode2x(node) {
    return node.parentNode
      ? node.parentNode.parentNode
        ? node.parentNode.parentNode
        : node.parentNode
      : node
  }

  //For FILELIST set

  //Retrieve file size from FILELIST item string
  static getFileSize(fileNameSize) {
    const i = fileNameSize.indexOf(MOC.SEPARATOR)
    return i > 0 ? parseInt(fileNameSize.substring(i + 1)) : 0
  }
}

export default MocUtils
