import { Item } from '../PoliciesConfiguration'
import { PolicyAttributeEnum } from '../PoliciesCategories'

export default class DefPrintOptions {
  static stapleCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.none`,
      label: 'staple-none'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.top-left`,
      label: 'staple-top-left'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.top-right`,
      label: 'staple-top-right'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.two-left`,
      label: 'staple-two-left'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.two-right`,
      label: 'staple-two-right'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.staple.two-top`,
      label: 'staple-two-top'
    }
  ]

  static holePunchCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.none`,
      label: 'hole-punch-none'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.two-left`,
      label: 'hole-punch-two-left'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.two-right`,
      label: 'hole-punch-two-right'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.two-top`,
      label: 'hole-punch-two-top'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.two-bottom`,
      label: 'hole-punch-two-bottom'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.four-left`,
      label: 'hole-punch-four-left'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.four-right`,
      label: 'hole-punch-four-right'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.four-top`,
      label: 'hole-punch-four-top'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch.four-left-or-top`,
      label: 'hole-punch-four-left-or-top'
    }
  ]

  static outputBinCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.auto`,
      label: 'auto'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.lower`,
      label: 'lower'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.upper`,
      label: 'upper'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.auto-select`,
      label: 'auto-select'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.standard`,
      label: 'standard'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.first`,
      label: 'first'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.alt`,
      label: 'alt'
    },
    {
      value: `${PolicyAttributeEnum.Def_Print_Options}.output-bin.second`,
      label: 'second'
    }
  ]
}
