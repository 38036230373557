import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export default class LinkSettingItems {
  static items: Item[] = [
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.auto`,
      label: 'mode-auto',
      option: 'automatic'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.10t-full`,
      label: 'mode-10t-full',
      option: 'link10full'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.10t-half`,
      label: 'mode-10t-half',
      option: 'link10half'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.100tx-full`,
      label: 'mode-100tx-full',
      option: 'link100full'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.100tx-half`,
      label: 'mode-100tx-half',
      option: 'link100half'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.100tx-auto`,
      label: 'mode-100tx-auto',
      option: 'link100auto'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.1000t-full`,
      label: 'mode-1000t-full',
      option: 'link1000full'
    },
    {
      value: `${PolicyAttributeEnum.Link_Setting}.mode.energy-efficient`,
      label: 'mode-energy-efficient',
      option: 'link10auto'
    }
  ]

  static modeCodes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? LinkSettingItems.items
      : constraints.filterSettingOptions(
          LinkSettingItems.items,
          ConstraintsResourceEnum.adapterConfigs,
          ConstraintsSettingEnum.ipv4LinkConfig
        )
}
