export const errorProcessor = (payload, t) => {
  let msgText = t(payload?.text || 'error.code.other') // default
  let msgCode = null

  if (payload && payload.response) {
    const { response } = payload
    msgCode = response.status // default
    if (response.statusText) {
      msgCode += ` - ${response.statusText}` // with text
    }

    const { data } = response
    if (data) {
      const assetKey = `error.code.${data.code}`
      const localizedText = t(assetKey)
      if (localizedText.indexOf(assetKey) === -1) {
        msgText = localizedText // message by known error code
        msgCode = null
      } else {
        if (data.message) {
          msgText = data.message // unknown message
        }
        if (data.code) {
          msgCode += `, ${data.code}` // unknown code
        }
      }
    }
  }

  return {
    error: { message: msgCode ? `${msgText} (${msgCode})` : msgText }
  }
}
