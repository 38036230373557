export enum EmailMessageEnum {
  FROM = 'from',
  TO = 'to',
  CC = 'cc',
  BCC = 'bcc',
  DEF_FROM = 'def-from',
  DEF_NAME = 'def-name',
  SUBJECT = 'subject',
  MESSAGE = 'message',
  EDITABLE = '-editable',
  OBJECT = '.message.'
}
