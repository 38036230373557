import React, { useContext, useMemo, useState } from 'react'
import { Accordion } from '@veneer/core'
import PolicyAttribute from 'components/policies/settings/attributes'
import Help from 'components/policies/modal/help'
import PolicyHeaderRightWrapper from 'components/policies/settings/attributes/header/right/PolicyHeaderRightWrapper'
import PolicyHeaderContent from 'components/policies/settings/attributes/header/content'
import configContext from 'context/config/configContext'
import { PolicyAttributeComplianceEnum } from 'components/policies/constants'
import { isAdvanced } from 'common/utilities'
import PoliciesConfiguration, {
  PolicyAttributeDescriptionEx
} from 'context/policies/PoliciesConfiguration'
import SettingsProvider from 'context/settings/SettingsProvider'
import 'styles/global.scss'

const reasonUnentitled = 'Feature unentitled'
const reasonUnsupported = 'Feature unsupported'
const statusFailures = ['failure', 'runtime-error']

const keyUnentitled = 'unentitled'

const DeviceCompliancePreview = (props) => {
  const { t } = useContext(configContext)
  const key = (key: string, args?: Record<string, string>): string => {
    return t(`policy.settings.notification.${key}`, args)
  }

  const [helpText, setHelpText] = useState(null)

  const getStatusEx = (status, reason) => {
    return reason === reasonUnsupported
      ? 'unsupported'
      : reason === reasonUnentitled
      ? keyUnentitled
      : statusFailures.includes(status)
      ? 'unmatched'
      : undefined
  }

  const accordionItems = useMemo(() => {
    const items = []
    for (const attribute of PoliciesConfiguration.sortPolicyAttributes(
      props.attributes,
      t
    )) {
      const description: PolicyAttributeDescriptionEx =
        PoliciesConfiguration.getPolicyAttributeDescription(attribute.name)
      // Display those deviceSettings which present in the compliance result
      description.deviceSettings = description.deviceSettings.filter((x) =>
        attribute.settings.some((y) => y.name.startsWith(x.attribute))
      )
      // Show expected values (from policies)
      attribute.deviceSettings = attribute.settings.map((x) => {
        return { name: x.name, value: x.expected }
      })

      // Add compliance info
      const notifications = []
      switch (attribute.status) {
        case PolicyAttributeComplianceEnum.NONCOMPLIANT: {
          // Need to analyze reasons
          const reasons = []
          attribute.settings.forEach((x, i) => {
            const status = getStatusEx(x.status, x.reason)
            if (status) {
              const compliance = key(`noncompliant.${status}`, {
                policyName: props.name || key('device-policy')
              })
              if (status !== keyUnentitled) {
                attribute.deviceSettings[i].compliance = compliance
              }
              if (!reasons.includes(compliance)) {
                reasons.push(compliance)
              }
            }
          })
          notifications.push({
            type: 'negative',
            title: key('noncompliant.title'),
            description: reasons.join('\n')
          })
          break
        }

        case PolicyAttributeComplianceEnum.OVERRIDDEN:
          notifications.push({
            type: 'warning',
            title: key('overridden.title'),
            description: attribute.overriddenBy?.name
              ? key('overridden.global', {
                  policyName: attribute.overriddenBy.name
                })
              : key('overridden.device')
          })
          break

        case PolicyAttributeComplianceEnum.UNKNOWN:
          notifications.push({
            type: 'informative',
            title: key('unknown.title'),
            description: key('unknown.description')
          })
          break

        case PolicyAttributeComplianceEnum.COMPLIANT:
        default:
          break
      }

      items.push({
        id: attribute.name,
        expanded: false,
        content: (
          <PolicyAttribute
            item={attribute}
            description={description}
            preview={true}
            notifications={notifications}
          />
        ),
        header: {
          centralArea: (
            <PolicyHeaderContent
              description={description}
              advanced={isAdvanced(attribute.metadata.entitlements)}
              setHelpText={(data) => setHelpText(data)}
            />
          ),
          endArea: (
            <PolicyHeaderRightWrapper
              description={description}
              status={attribute.status}
            />
          )
        }
      })
    }
    return items
  }, [props.attributes])

  return (
    <SettingsProvider>
      <Accordion
        className={'rightPaddingAccordion'}
        items={accordionItems}
        behavior={'singleExpand'}
        id="compliance-accordion-settings"
      />
      <Help {...helpText} onClose={() => setHelpText(null)} />
    </SettingsProvider>
  )
}

export default DeviceCompliancePreview
