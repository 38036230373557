import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Accordion, AnchorMenu, Button, Card } from '@veneer/core'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import CreatePolicy from 'components/policies/table/CreatePolicy'
import { Error } from 'components/policies/modal/error'
import Help from 'components/policies/modal/help'
import PolicyAttribute from 'components/policies/settings/attributes'
import PolicyHeaderContent from 'components/policies/settings/attributes/header/content'
import NoItemsCard from 'common/controls/noItems'
import { getDisplayToast, isAdvanced } from 'common/utilities'
import PoliciesConfiguration, {
  PolicyAttributeDescriptionEx
} from 'context/policies/PoliciesConfiguration'
import SettingsProvider from 'context/settings/SettingsProvider'
import PolicyConstraints from 'context/constraints/Constraints'
import { FlexRow, SideMenu } from 'styles/styles'
import './index.scss'

const DevicePolicyPreview = (props) => {
  const { t } = useContext(configContext)
  const key = (key: string): string => {
    return t(`policy.details.device.${key}`)
  }

  const { getDevicePolicy, selectedPolicy } = useContext(policiesContext)
  const { deviceModelNumber, deviceId, entitlements, containerId, useToast } =
    props
  const [showModal, setShowModal] = useState(false)
  const [helpText, setHelpText] = useState(null)

  useEffect(() => {
    if (deviceId) {
      getDevicePolicy(deviceId)
    }
  }, [deviceId])

  const constraints = useMemo(() => {
    return deviceModelNumber
      ? new PolicyConstraints(deviceModelNumber)
      : undefined
  }, [deviceModelNumber])

  const accordionContent = useMemo(() => {
    if (!selectedPolicy?.attributes.length) {
      return (
        <NoItemsCard
          indicator={!selectedPolicy}
          title={key('notFound')}
          description={key('tryEditing')}
          noCard={true}
        />
      )
    }
    const items = []
    for (const attribute of PoliciesConfiguration.sortPolicyAttributes(
      selectedPolicy.attributes,
      t
    )) {
      // Show expected values (from policies)
      const description: PolicyAttributeDescriptionEx =
        PoliciesConfiguration.getPolicyAttributeDescription(
          attribute.name,
          constraints
        )
      items.push({
        id: attribute.name,
        expanded: false,
        content: (
          <PolicyAttribute
            item={attribute}
            description={description}
            preview={true}
          />
        ),
        header: {
          centralArea: (
            <PolicyHeaderContent
              description={description}
              advanced={isAdvanced(attribute.metadata.entitlements)}
              setHelpText={(data) => setHelpText(data)}
            />
          )
        }
      })
    }
    return (
      <Accordion
        className={'rightPaddingAccordion'}
        items={items}
        behavior={'singleExpand'}
        id={'device-policy-preview-settings-accordion'}
      />
    )
  }, [selectedPolicy, constraints])

  return (
    <SettingsProvider>
      <FlexRow>
        <Card
          id={'device-policy-preview-card'}
          content={
            <Accordion
              className={'rightPaddingAccordion noPaddingAccordion'}
              items={[
                {
                  id: 'device-policy-preview-accordion-item',
                  content: accordionContent,
                  expanded: true,
                  header: {
                    id: 'device-policy-preview-accordion-header',
                    centralArea: (
                      <p className={'centralAreaAccordion'}>{key('title')}</p>
                    ),
                    endArea: (
                      <Button
                        id={'device-policy-preview-accordion-button'}
                        appearance={'ghost'}
                        onClick={() => setShowModal(true)}
                        small={true}
                      >
                        {t('common.edit')}
                      </Button>
                    )
                  }
                }
              ]}
              behavior={'singleExpand'}
              id={'device-policy-preview-accordion'}
            />
          }
          border={'dropShadow'}
          customStyle={{ width: '80%' }}
        />
        <SideMenu>
          <AnchorMenu
            containerId={containerId}
            position={{
              position: 'relative',
              end: 0,
              start: 24
            }}
            items={[
              {
                anchor: 'device-policy-preview-card',
                label: key('title')
              }
            ]}
          />
        </SideMenu>
      </FlexRow>
      {showModal && (
        <CreatePolicy
          deviceId={deviceId}
          entitlements={entitlements}
          constraints={constraints}
          attributes={selectedPolicy?.attributes || []}
          displayToast={getDisplayToast(useToast)}
          onClose={() => setShowModal(false)}
        />
      )}
      <Help {...helpText} onClose={() => setHelpText(null)} />
      <Error />
    </SettingsProvider>
  )
}

export default DevicePolicyPreview
