import { Item } from '../PoliciesConfiguration'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const generateConnectionMethodObj = (connectionMethod: string) => {
  return {
    value: `${PolicyAttributeEnum.WiFi_Direct}.method.${connectionMethod}`,
    label: 'method-' + connectionMethod
  }
}

export default class WiFiDirectItems {
  static wifiDirectChannels: Item[] = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '10' },
    { value: '11' },
    { value: '36' },
    { value: '40' },
    { value: '44' },
    { value: '48' },
    { value: '149' },
    { value: '153' },
    { value: '157' },
    { value: '161' },
    { value: '165' }
  ]

  static wifiDirectConnectionMethods: Item[] = [
    generateConnectionMethodObj('auto'),
    generateConnectionMethodObj('manual'),
    generateConnectionMethodObj('advanced')
  ]
}
