import { Button, ButtonGroup, Modal } from '@veneer/core'
import React, { useContext, useMemo, memo } from 'react'
import configContext from 'context/config/configContext'

const re = new RegExp('{{([-._\\w])*}}')

const Help = (props) => {
  const { t } = useContext(configContext)
  const { name, description, footer, onClose, relatedItemId } = props
  const show = useMemo(() => name && description, [name, description])
  const text = useMemo(
    () =>
      description
        ? description.replace(re, (match) => t(match.slice(2, -2)))
        : '',
    [description]
  )

  return show ? (
    <Modal
      id={'regular-modal'}
      className={'large-policy-modal'}
      closeButton={!footer}
      onClose={onClose}
      closeOnBlur={!footer}
      show={true}
      title={name}
      footer={
        footer && (
          <ButtonGroup>
            <Button
              onClick={() => props.selectedState(relatedItemId)}
              id={'selectPolicyAttributeButton'}
            >
              {t('common.select')}
            </Button>
            <Button
              onClick={onClose}
              appearance={'secondary'}
              id={'closePolicyAttributeButton'}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        )
      }
    >
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </Modal>
  ) : null
}

export default memo(Help)
