import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Grid2 = styled.div`
  display: grid;
  grid-gap: 1px;
`

export const BadgeLabel = styled.div`
  color: ${primitives.color.gray7};
  font-size: ${primitives.typography.size0};
  line-height: ${primitives.typography.lineHeight1};
  border: 1px solid ${primitives.color.gray7};
  border-radius: 10px;
  padding: 1px 8px;
  box-sizing: border-box;
  height: ${primitives.layout.size5}px;
  white-space: nowrap;
`

export const TextBlack = styled.div<{ disabled?: boolean }>`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  color: ${({ disabled }) =>
    disabled ? primitives.color.gray5 : primitives.color.gray9};
  overflow-wrap: anywhere;
`

export const TextBlackH = styled.div`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  color: ${primitives.color.gray9};
`

export const FlexRow = styled.div`
  display: flex;
`

export const FlexRowWithSpace = styled(FlexRow)`
  justify-content: space-between;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const AccordionContent = styled(FlexColumn)`
  padding: ${primitives.layout.size3}px;
`

export const FlexColumnGrow = styled(FlexColumn)`
  flex-grow: 1;
`

export const FlexColumnTree = styled(FlexColumn)`
  width: 280px;
`

export const FlexColumnRight = styled(FlexColumn)`
  width: calc(100% - 280px);
`

export const SideMenu = styled.div`
  position: sticky;
  top: 10px;
  height: 100%;
`

export const PreviewLabel = styled.div`
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  color: ${primitives.color.gray9};
  overflow-wrap: anywhere;
`

export const PreviewValue = styled.div<{ disabled?: boolean }>`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  color: ${({ disabled }) =>
    disabled ? primitives.color.gray5 : primitives.color.gray12};
  overflow-wrap: anywhere;
`

// Scrolling

export const cssFullHeight = { height: '100%' }
export const cssModalTableHeight = { height: '488px' } // + header(60) + text(24) + footer(68) + 2 x padding(32) = 720px modal height
