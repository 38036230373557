import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react'
import {
  Button,
  ButtonGroup,
  IconDocumentPlus,
  Search,
  Table
} from '@veneer/core'
import {
  ColumnIndexTypes,
  TableSortBy,
  SortTypes
} from '@veneer/core/dist/scripts/table'
import TableFooter, { MenuItems } from 'components/policies/tableFooter'
import policiesContext from 'context/policies/policiesContext'
import configContext from 'context/config/configContext'
import { newPolicy } from 'common/setPolicy'
import PoliciesCategories from 'context/policies/PoliciesCategories'
import {
  DATE_FORMAT,
  TABLE_CONSTANTS,
  formatDate,
  getGroupName,
  getUserName,
  getRowSelectAllState,
  abcSort,
  jsonParse
} from 'common/utilities'
import 'styles/global.scss'
import './index.scss'
import { Link, useHistory } from 'react-router-dom'
import ConfirmRemove from 'components/policies/modal/confirmRemove'
import CreatePolicy from './CreatePolicy'
import PolicyStatus from 'components/policies/status'
import {
  RELOAD_EVENT,
  WRITE_SCOPES,
  DELETE_SCOPES,
  READ_SCOPES,
  CREATE_SCOPES,
  ToastIdEnum
} from '../constants'
import { FlexRowWithSpace } from 'styles/styles'
import { getDecoratedName } from 'common/decoratedName'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const columnId = 'id'
const columnName = 'name'
const columnActive = 'active'
const columnAssignedTo = 'assignedTo'
const columnCategory = 'category'
const columnModifiedBy = 'modifiedBy'
const columnModifiedAt = 'modifiedAt'
const defaultOrder = [
  columnName,
  columnActive,
  columnAssignedTo,
  columnCategory,
  columnModifiedBy,
  columnModifiedAt
]

const defSortOrder: SortTypes = 'descending'
const defSorting: TableSortBy = {
  id: columnActive,
  type: defSortOrder
}

const stateAll = 'all'
const storageColumns = 'policiesMfeColumns'

const PolicyTable = (props) => {
  const policyContext = useContext(policiesContext)
  const { getAllPolicies, policies, removePolicies, createPolicy, showError } =
    policyContext
  const fileInputRef = useRef(null)
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [numberOfSelectedItems, setNumberOfSelectedItems] = useState(0)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)

  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(storageColumns), defaultOrder)
  )
  const [sorting, setSorting] = useState(defSorting)
  const { baseurl, navigation, displayToast, accessControl, events } = props
  const isWex = props?.themeIdentifier === 'wex'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()

  const displayErrorToast = (error) => {
    displayToast(ToastIdEnum.GET_ALL, error, 'negative')
  }

  const cContext = useContext(configContext)
  const { t } = cContext

  const [allowWrite, setAllowWrite] = useState(isWex) // TO DO: isWex -> false
  const [allowDelete, setAllowDelete] = useState(isWex) // TO DO: isWex -> false
  const [allowCreate, setAllowCreate] = useState(isWex) // TO DO: isWex -> false
  const [allowRead, setAllowRead] = useState(isWex) // TO DO: isWex -> false

  const [searchValue, setSearchValue] = useState('')
  const [showModal, setShowModal] = useState(false)

  const checkScopes = async () => {
    setAllowWrite(await accessControl.checkScopes(WRITE_SCOPES))
    setAllowDelete(await accessControl.checkScopes(DELETE_SCOPES))
    setAllowCreate(await accessControl.checkScopes(CREATE_SCOPES))
    setAllowRead(await accessControl.checkScopes(READ_SCOPES))
  }

  useEffect(() => {
    // TO DO: remove !isWex
    if (accessControl && !isWex) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const reload = useCallback(() => {
    getAllPolicies(displayErrorToast)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => reload(), [reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(RELOAD_EVENT, reload)
      return () => events.removeEventListener(RELOAD_EVENT, reload)
    }
  }, [events, reload])

  useEffect(() => {
    const getAssignedTo = (assignedTo) => {
      return assignedTo.length > 1
        ? t('policy.table.more', {
            name: getGroupName(assignedTo[0], t),
            count: assignedTo.length - 1
          })
        : assignedTo.length
        ? getGroupName(assignedTo[0], t)
        : TABLE_CONSTANTS.NO_DATA
    }

    const getCategory = (attributes) => {
      const category = (subkey) => t(`policy.settings.categories.${subkey}`)
      const categories = PoliciesCategories.getPolicyCategories([], attributes)
      return categories.length > 1
        ? t('policy.table.more', {
            name: category(categories[0]),
            count: categories.length - 1
          })
        : categories.length
        ? category(categories[0])
        : ''
    }

    const getStatus = (active) =>
      t(`policy.status.${active ? 'active' : 'inactive'}`)

    const item = (policy) => {
      return {
        id: policy.id,
        name: policy.name,
        active: policy.active,
        status: getStatus(policy.active),
        assignedTo: getAssignedTo(policy.assignedTo),
        category: getCategory(policy.attributes),
        modifiedBy: getUserName(policy.lastModifiedBy),
        modifiedAt: formatDate(
          policy.lastModifiedAt,
          DATE_FORMAT.LAST_MODIFIED
        ),
        rowConfig: {
          lastModifiedAt: policy.lastModifiedAt,
          selected: !!tableData?.find(({ id }) => id === policy.id)?.rowConfig
            .selected
        }
      }
    }

    setTableData(
      policies?.items ? policies.items.map((policy) => item(policy)) : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies, t])

  const showFooter = useMemo(
    () => numberOfSelectedItems >= 1,
    [numberOfSelectedItems]
  )

  const filteredData = useMemo(() => {
    const selected = tableData
      ? tableData.filter(({ rowConfig }) => rowConfig.selected).length
      : 0
    setNumberOfSelectedItems(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData?.length))

    const searchLowerCase = searchValue.toLowerCase()
    return !tableData
      ? null
      : searchLowerCase.length
      ? tableData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchLowerCase) ||
            item.status.toLowerCase().includes(searchLowerCase) ||
            item.assignedTo.toLowerCase().includes(searchLowerCase) ||
            item.category.toLowerCase().includes(searchLowerCase) ||
            item.modifiedBy.toLowerCase().includes(searchLowerCase)
        )
      : tableData
  }, [tableData, searchValue])

  const getPolicy = useCallback(
    (pId) => policies.items?.find(({ id }) => id === pId),
    [policies.items]
  )

  const sortedData = useMemo(() => {
    if (!filteredData) {
      return null
    }

    // Sorting
    let picker = (x) => x.name //columnName
    switch (sorting.id) {
      case columnModifiedAt:
        picker = (x) => x.rowConfig.lastModifiedAt
        break
      case columnActive:
        picker = (x) => `${!x.active}`
        break
      case columnModifiedBy:
        picker = (x) => x.modifiedBy
        break
    }
    return abcSort([...filteredData], picker, sorting.type)
  }, [filteredData, sorting])

  const pagedData = useMemo(() => {
    const getName = (id, name, search) => {
      const decoratedName = getDecoratedName(name, search, 'searchTokenGray')
      return (
        <Link
          to={`/${id}`}
          onClick={(e) => {
            e.preventDefault()
            history.push(`${baseurl}/${id}`)
          }}
          id={id}
        >
          {decoratedName.length ? decoratedName : name}
        </Link>
      )
    }

    const getDataForTable = (item, search) => {
      const getDecoratedText = (text) => {
        const decoratedName = getDecoratedName(text, search, 'searchTokenBlue')
        return decoratedName.length ? decoratedName : text
      }
      return {
        id: item.id,
        name: getName(item.id, item.name, search),
        active: (
          <PolicyStatus
            active={item.active}
            status={getDecoratedText(item.status)}
          />
        ),
        assignedTo: getDecoratedText(item.assignedTo),
        category: getDecoratedText(item.category),
        modifiedBy: getDecoratedText(item.modifiedBy),
        modifiedAt: item.modifiedAt,
        rowConfig: item.rowConfig
      }
    }

    // Change current page if required
    const lastPage =
      sortedData && sortedData.length
        ? ~~((sortedData.length - 1) / pageSize)
        : 0
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    // Paged
    const searchLowerCase = searchValue.toLowerCase()
    return !sortedData
      ? null
      : [...sortedData]
          .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
          .map((item) => getDataForTable(item, searchLowerCase))
  }, [sortedData, pageSize, currentPage, searchValue, baseurl, history])

  const disableDelete = useMemo(
    () =>
      numberOfSelectedItems > 0 &&
      tableData?.some((x) => x.rowConfig.selected && getPolicy(x.id)?.active),
    [numberOfSelectedItems, tableData, getPolicy]
  )

  const handleRowSelect = (event, rowId) => {
    const newTableData = [...tableData]
    const found = newTableData.find((row) => row.id === rowId)
    if (found) {
      const { checked } = event.target
      found.rowConfig.selected = checked
      setTableData(newTableData)
    }
  }

  const handleSelectAllPageItems = (event) => {
    let { checked } = event.currentTarget
    if (!checked && rowSelectAllState === stateAll) {
      selectOrDeselectAll(false) // emulate Unselect All
      return
    }
    if (checked && !pagedData?.find(({ rowConfig }) => !rowConfig.selected)) {
      checked = false // there is nothing to select on this page, so unselect instead
    }
    const newTableData = tableData.map((row) => {
      if (pagedData?.find(({ id }) => id === row.id)) {
        const rowCopy = row
        rowCopy.rowConfig.selected = checked
        return rowCopy
      }
      return row
    })
    setTableData(newTableData)
  }

  const selectOrDeselectAll = (checked) => {
    const newTableData = tableData.map((row) => {
      const newRow = row
      newRow.rowConfig.selected = checked
      return newRow
    })
    setTableData(newTableData)
  }

  const handlePageChange = (page) => setCurrentPage(page - 1)

  const handlePageSizeChange = (event, option) => setPageSize(option.value)

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(storageColumns, JSON.stringify(newOrder))
  }

  const getRowsSelected = () => {
    const selectedRows = tableData?.filter(
      (row) => row.rowConfig.selected === true
    )
    return policies?.items?.filter((row) =>
      selectedRows.find(({ id }) => row.id === id)
    )
  }

  const getClonedPolicyName = (name, names) => {
    for (let n = 1; ; n += 1) {
      const newName = name + t('policy.clone.clone', { n })
      if (!names.includes(newName)) {
        return newName
      }
    }
  }

  const closeConfirmRemove = () => setShowConfirmRemove(false)

  const onContinue = (action) => {
    switch (action) {
      case MenuItems.REMOVE:
        setShowConfirmRemove(true)
        break
      case MenuItems.CLONE:
        doClonePolicy()
        break
      case MenuItems.EXPORT:
        doExportPolicy()
        break
      case MenuItems.EDIT:
        doEditPolicy()
        break
    }
  }

  const doRemovePolicy = () => {
    closeConfirmRemove()
    const selectedPolicy = getRowsSelected()
    if (selectedPolicy && selectedPolicy.length > 0) {
      removePolicies(
        selectedPolicy.map((x) => x.id),
        () => {
          displayToast(ToastIdEnum.REMOVE_POLICY, t('policy.remove.success'))
          getAllPolicies(displayErrorToast)
        }
      )
    }
  }

  const doClonePolicy = () => {
    const selectedPolicy = getRowsSelected()
    if (selectedPolicy && selectedPolicy.length > 0) {
      const clonedPolicy = newPolicy(
        getClonedPolicyName(
          selectedPolicy[0].name,
          policies.items.map((x) => x.name)
        ),
        selectedPolicy[0].description,
        selectedPolicy[0].attributes
      )
      createPolicy(clonedPolicy, () => {
        displayToast(ToastIdEnum.CLONE_POLICY, t('policy.clone.success'))
        getAllPolicies(displayErrorToast)
      })
    }
  }

  const doImportPolicy = () => fileInputRef.current.click()

  const handleFileChange = (event) => {
    for (const file of event.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const imported = jsonParse(e.target.result.toString(), {})
        if (imported.name && imported.attributes?.length) {
          createPolicy(
            newPolicy(
              imported.name,
              imported.description || '',
              imported.attributes
            ),
            () => {
              displayToast(
                ToastIdEnum.IMPORT_POLICY,
                t('policy.import.success')
              )
              getAllPolicies(displayErrorToast)
            }
          )
        } else {
          showError({ text: 'policy.import.error' })
        }
      }
      reader.readAsText(file)
    }
    fileInputRef.current.value = ''
  }

  const doExportPolicy = () => {
    getRowsSelected()
      ?.map(({ name, description, attributes }) => ({
        name,
        description,
        attributes: attributes.map((x) => {
          const metadata = { ...x.metadata }
          delete metadata['entitlements']
          return { ...x, metadata }
        })
      }))
      .forEach((policy) => {
        const blob = new Blob([JSON.stringify(policy)], {
          type: 'application/json'
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${policy.name}.json`
        a.click()
      })
    handleSelectAllPageItems({ currentTarget: { checked: false } })
  }

  const doEditPolicy = () => {
    const selectedPolicy = getRowsSelected()
    if (selectedPolicy && selectedPolicy.length > 0) {
      history.push(`${baseurl}/edit/${selectedPolicy[0].id}`)
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    const header = (subkey) => t(`policy.table.header.${subkey}`)
    return [
      {
        id: columnId,
        label: columnId,
        index
      },
      {
        id: columnName,
        label: header('policy'),
        sortable: true,
        required: true // non-documented
      },
      {
        id: columnActive,
        label: header('status'),
        sortable: true
      },
      {
        id: columnAssignedTo,
        label: header('assignedTo'),
        sortable: false
      },
      {
        id: columnCategory,
        label: header('category'),
        sortable: false
      },
      {
        id: columnModifiedBy,
        label: header('modifiedBy'),
        sortable: true
      },
      {
        id: columnModifiedAt,
        label: header('modifiedAt'),
        sortable: true
      }
    ]
  }, [t])

  return (
    <div id={'policies'}>
      <Table
        actionArea={
          <FlexRowWithSpace className={'fullWidth'}>
            <Search
              id={'policy-search'}
              className={'maxSearchWidth'}
              placeholder={t('common.search')}
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
            />
            <ButtonGroup>
              {allowCreate && (
                <Button
                  appearance={'secondary'}
                  onClick={doImportPolicy}
                  id={'importPolicy'}
                >
                  {t('common.import')}
                </Button>
              )}
              {allowCreate && allowRead && (
                <Button
                  appearance={'primary'}
                  leadingIcon={<IconDocumentPlus />}
                  onClick={() => setShowModal(true)}
                  id={'createPolicy'}
                >
                  {t('common.create')}
                </Button>
              )}
            </ButtonGroup>
          </FlexRowWithSpace>
        }
        columns={tableColumns}
        data={pagedData || []}
        onSort={(_, sortBy) => setSorting(sortBy)}
        loading={pagedData === null}
        loadingDataLength={pageSize}
        onSelect={handleRowSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        columnReorder
        onColumnReorder={handleOnColumnReorder}
        pagination={{
          currentPage: currentPage + 1,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          pageSize,
          pageSizeOptions: [
            { value: 5 },
            { value: 25 },
            { value: 50 },
            { value: 100 },
            { value: 500 }
          ],
          totalItems: filteredData ? filteredData.length : 0
        }}
        rowSelector={'multiSelection'}
        rowSelectAllState={rowSelectAllState}
        preferences={{
          defaultOrder,
          order,
          sortBy: sorting,
          width: [
            { columnId: columnName, width: 25 },
            { columnId: columnActive, width: 10 },
            { columnId: columnAssignedTo, width: 15 },
            { columnId: columnCategory, width: 15 },
            { columnId: columnModifiedBy, width: 15 },
            { columnId: columnModifiedAt, width: 20 }
          ]
        }}
        data-testid={'id-policies-table'}
        i18n={Retrievei18nItems()}
      />
      {showModal && (
        <CreatePolicy
          onClose={() => setShowModal(false)}
          displayToast={displayToast}
        />
      )}
      {showFooter && (
        <TableFooter
          numberOfSelectedItems={numberOfSelectedItems}
          onCancel={() => selectOrDeselectAll(false)}
          onContinue={onContinue}
          allowDelete={allowDelete}
          allowWrite={allowWrite}
          allowCreate={allowCreate}
          allowExport={true}
          disableDelete={disableDelete}
        />
      )}
      <ConfirmRemove
        show={showConfirmRemove}
        onCancel={closeConfirmRemove}
        onRemove={doRemovePolicy}
      />
      <input
        type={'file'}
        ref={fileInputRef}
        onChange={handleFileChange}
        className={'displayNone'}
        accept={'.json'}
        multiple
      />
    </div>
  )
}

export default PolicyTable
