import React, { useContext } from 'react'
import { Checkbox } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexColumn, PreviewValue, TextBlack } from 'styles/styles'
import { MOC } from 'common/mocParser/MocUtils'
import WarningMessage from '../WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const AppDeploymentCheck = (props) => {
  const { tt } = useContext(configContext)
  const {
    fId,
    sId,
    set,
    error,
    preview,
    disabled,
    helperText,
    componentValue,
    updateStateValue,
    localizationPath
  } = props

  const getLocalized = (key: string, params?): string => {
    return tt(localizationPath, key, params)
  }

  const retrieveCheckBoxLabel = (set) => {
    return [Object.values(set[MOC.OPTIONS]?.[0])?.[0], set[MOC.LABEL]]
      .filter(Boolean)
      .join('/')
  }

  return preview ? (
    <>
      <TextBlack className={`marginBottom4`}>
        {retrieveCheckBoxLabel(set)}
      </TextBlack>
      <PreviewValue>
        {getLocalized(componentValue(fId, sId) ? 'common.on' : 'common.off')}
      </PreviewValue>
    </>
  ) : (
    <FlexColumn>
      <Checkbox
        id={set[MOC.NAME]}
        label={retrieveCheckBoxLabel(set)}
        onChange={({ target }) => {
          const valueToSet = {
            [fId]: {
              [sId]: { [MOC.VALUES]: [{ [sId]: target.checked }] }
            }
          }
          updateStateValue(valueToSet)
        }}
        checked={componentValue(fId, sId)}
        disabled={disabled}
      />
      {error && (
        <WarningMessage type={MessageTypesEnum.ERROR} message={helperText} />
      )}
    </FlexColumn>
  )
}
export default AppDeploymentCheck
