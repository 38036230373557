import React from 'react'
import './index.scss'

const VerticalDivider = () => {
  return (
    <div className="dividerDiv">
      <hr className="divider" />
    </div>
  )
}
export default VerticalDivider
