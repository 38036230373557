import React from 'react'
import { Card, Checkbox, Scrollbar } from '@veneer/core'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import LabelSelect from 'common/controls/labelSelect'
import LabelTextBox from 'common/controls/labelTextBox'
import { QuickSetsMessageEnum } from './QuickSetsEnums'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import { getError, isTrueString } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { errorKeys } from './QuickSetsModal'
import { FlexColumn, cssFullHeight } from 'styles/styles'

const AddQuickSetStep2 = (props) => {
  const {
    getLocalized,
    value,
    constraints,
    checkConstraints,
    onChange,
    requiredErrors,
    setRequiredErrors
  } = props

  const getLocalizedEx = (key) =>
    getLocalized(
      `policy.settings.attributes.email-message.device_settings.${key}`
    )

  const getFromItems = () =>
    EmailAddressItems.fromItems.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

  const getToCcBccItems = () =>
    EmailAddressItems.toItemsQs.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

  const getRestrictionsItems = () =>
    EmailAddressItems.restrictionItems.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

  const inputFieldsData = {
    [QuickSetsMessageEnum.RESTRICTIONS]: {
      options: getRestrictionsItems(),
      value: value[QuickSetsMessageEnum.RESTRICTIONS]
    },
    [EmailMessageEnum.FROM]: {
      options: getFromItems(),
      value: value[EmailMessageEnum.FROM]
    },
    [EmailMessageEnum.DEF_FROM]: {
      placeholder: 'def-from_hint',
      errors: PoliciesErrors.errorsInvalidEmail
    },
    [EmailMessageEnum.DEF_NAME]: {
      placeholder: 'def-name_hint',
      errors: PoliciesErrors.errorsDefFromName(constraints)
    },
    [EmailMessageEnum.TO]: {
      options: getToCcBccItems(),
      value: value[EmailMessageEnum.TO],
      placeholder: 'email_hint'
    },
    [EmailMessageEnum.CC]: {
      options: getToCcBccItems(),
      value: value[EmailMessageEnum.CC],
      placeholder: 'email_hint'
    },
    [EmailMessageEnum.BCC]: {
      options: getToCcBccItems(),
      value: value[EmailMessageEnum.BCC],
      placeholder: 'email_hint'
    },
    [EmailMessageEnum.SUBJECT]: {
      placeholder: 'subject_hint',
      errors: PoliciesErrors.errorsSubject(constraints)
    },
    [EmailMessageEnum.MESSAGE]: {
      placeholder: 'message_hint',
      errors: PoliciesErrors.errorsMessage(constraints)
    }
  }

  const generateSelect = (id: string) => {
    const error = requiredErrors[id]
    const selectBoxData = inputFieldsData[id]
    return (
      <LabelSelect
        label={getLocalizedEx(id)}
        id={id}
        className={'maxTextWidth'}
        options={selectBoxData.options}
        placeholder={getLocalized('common.select-option')}
        value={[selectBoxData.value]}
        error={!!error}
        helperText={error?.message ? getLocalized(error?.message) : null}
        onChange={(option) => {
          setRequiredErrors((prevValue) => {
            const newValue = { ...prevValue }
            if (!option.value) {
              newValue[id] = { message: errorKeys[0] }
            } else {
              delete newValue[id]
            }
            return newValue
          })
          onChange({ [id]: option.value })
        }}
      />
    )
  }

  const generateCheckbox = (id: string, indent = true) => {
    const checkId = indent ? id + EmailMessageEnum.EDITABLE : id
    return (
      <div className={`paddingTop16${indent ? ' devSettingsIndent1' : ''}`}>
        <Checkbox
          id={checkId}
          label={getLocalizedEx(indent ? 'user-editable' : id)}
          key={checkId}
          checked={isTrueString(value[checkId])}
          onChange={(check) => {
            const { checked, id } = check.target
            onChange({ [id]: checked + '' })
          }}
        />
      </div>
    )
  }

  const generateTextbox = (id: string, textArea = false, disabled = false) => {
    const placeholder = inputFieldsData[id].placeholder
    const policyError = inputFieldsData[id].errors
    const error = requiredErrors[id]
    return (
      <div className={'paddingTop16'}>
        <LabelTextBox
          label={getLocalizedEx(id)}
          className={'maxTextWidth'}
          id={id}
          type={'text'}
          disabled={disabled}
          value={value[id] || ''}
          placeholder={getLocalizedEx(placeholder)}
          helperText={
            error?.message
              ? getLocalized(error.message, 0, error.params)
              : getLocalizedEx(placeholder)
          }
          onChange={(val) => {
            setRequiredErrors((prevValue) => {
              const newValue = { ...prevValue }
              const newError = getError(policyError, val)
              if (newError) {
                newValue[id] = newError
              } else {
                delete newValue[id]
              }
              return newValue
            })
            onChange({ [id]: val })
          }}
          error={!!error}
          textArea={textArea}
        />
      </div>
    )
  }

  const generateEmailAddressesTextbox = (id: string, disabled = false) => {
    const placeholder = inputFieldsData[id].placeholder
    const textBoxId = id + QuickSetsMessageEnum.ADDRESSES
    const error = requiredErrors[textBoxId]

    const handleEmailAddressesChange = (val) => {
      const newError = getError(PoliciesErrors.errorsInvalidEmailList, val)
      setRequiredErrors((prevValue) => {
        const newValue = { ...prevValue }
        if (newError) {
          newValue[textBoxId] = newError
        } else {
          delete newValue[textBoxId]
        }
        return newValue
      })
      onChange({ [textBoxId]: newError ? [val] : val.split(',') })
    }

    return (
      <div className={'paddingTop16 devSettingsIndent1'}>
        <LabelTextBox
          className={'maxTextWidth'}
          id={textBoxId}
          type={'text'}
          disabled={disabled}
          value={value[textBoxId]?.toString() || ''}
          placeholder={getLocalizedEx(placeholder)}
          helperText={
            error?.message
              ? getLocalized(error.message, 0, error.params)
              : getLocalizedEx(placeholder)
          }
          onChange={(val) => handleEmailAddressesChange(val)}
          error={!!error}
          textArea={true}
        />
      </div>
    )
  }

  const generateSelectCheckText = (
    id: string,
    textBoxDisabled: boolean,
    hasCheck = true
  ) => (
    <FlexColumn className={'paddingTop16'}>
      {generateSelect(id)}
      {hasCheck && generateCheckbox(id)}
      {generateEmailAddressesTextbox(id, textBoxDisabled)}
    </FlexColumn>
  )

  const generateSelectCheck = (id: string, hasCheck: boolean) => (
    <FlexColumn className={'paddingTop16'}>
      {generateSelect(id)}
      {hasCheck && generateCheckbox(id)}
    </FlexColumn>
  )

  const generateTextCheck = (
    id: string,
    texArea: boolean,
    hasCheck: boolean
  ) => (
    <FlexColumn>
      {generateTextbox(id, texArea)}
      {hasCheck && generateCheckbox(id)}
    </FlexColumn>
  )

  const generateCheckboxGroup = (id: string) =>
    checkConstraints(id, false) && (
      <FlexColumn>
        {generateCheckbox(id, false)}
        {checkConstraints(id + EmailMessageEnum.EDITABLE, false) &&
          generateCheckbox(id)}
      </FlexColumn>
    )

  const generateCardContent = () => {
    const disabled = (id) => value[id] !== EmailAddressItems.toItemsQs[2].value
    const defDisabled =
      value[EmailMessageEnum.FROM] !== EmailAddressItems.fromItems[0].value
    return (
      <Scrollbar customStyle={cssFullHeight}>
        <FlexColumn className={'padding1224'}>
          {generateSelect(QuickSetsMessageEnum.RESTRICTIONS)}
          {generateSelectCheck(
            EmailMessageEnum.FROM,
            checkConstraints(EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE)
          )}
          <FlexColumn className={'devSettingsIndent1'}>
            {generateTextbox(EmailMessageEnum.DEF_FROM, false, defDisabled)}
            {generateTextbox(EmailMessageEnum.DEF_NAME, false, defDisabled)}
          </FlexColumn>
          {generateSelectCheckText(
            EmailMessageEnum.TO,
            disabled(EmailMessageEnum.TO)
          )}
          {checkConstraints(EmailMessageEnum.CC) &&
            generateSelectCheckText(
              EmailMessageEnum.CC,
              disabled(EmailMessageEnum.CC),
              checkConstraints(EmailMessageEnum.CC + EmailMessageEnum.EDITABLE)
            )}
          {checkConstraints(EmailMessageEnum.BCC) &&
            generateSelectCheckText(
              EmailMessageEnum.BCC,
              disabled(EmailMessageEnum.BCC),
              checkConstraints(EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE)
            )}
          {generateTextCheck(
            EmailMessageEnum.SUBJECT,
            false,
            checkConstraints(
              EmailMessageEnum.SUBJECT + EmailMessageEnum.SUBJECT
            )
          )}
          {generateTextCheck(
            EmailMessageEnum.MESSAGE,
            true,
            checkConstraints(
              EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE
            )
          )}
          {checkConstraints(QuickSetsMessageEnum.ALLOW_INVALID_EMAIL, false) &&
            generateCheckbox(QuickSetsMessageEnum.ALLOW_INVALID_EMAIL, false)}
          {generateCheckboxGroup(QuickSetsMessageEnum.SIGN)}
          {generateCheckboxGroup(QuickSetsMessageEnum.ENCRYPT)}
        </FlexColumn>
      </Scrollbar>
    )
  }

  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header', 2)}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description', 2)}</p>
      <div className={'quick-set-card-height'}>
        <Card
          id={'add-quick-set-step-2'}
          content={generateCardContent()}
          border={'dropShadow'}
          customStyle={{ height: '100%', padding: '12px 0 12px 0' }}
        />
      </div>
    </>
  )
}

export default AddQuickSetStep2
