import React, { useContext, useMemo } from 'react'
import { IconXCircle } from '@veneer/core'
import configContext from 'context/config/configContext'
import { PreviewLabel, PreviewValue, FlexRow } from 'styles/styles'
import 'styles/global.scss'

// Token syntax: {{n}} or {{n-m}} or {{}}
const bra = '{{'
const ket = '}}'
const dash = '-'
const sep = ', '

const PreviewItem = (props) => {
  const { t } = useContext(configContext)
  const { label, value, keys, compliance, className } = props

  // key parameters to string
  const params = (s, k) => {
    let sKeys = k
    if (s) {
      const indexes = s.split(dash)
      sKeys = k.slice(+indexes[0], +indexes[indexes.length === 2 ? 1 : 0] + 1)
    }
    return sKeys.map((x) => (isNaN(x) ? t(x) : x)).join(sep)
  }

  const data = useMemo(() => {
    if (!value) {
      // backward compatibility, same as '{{}}'
      return keys ? params('', keys) : undefined
    }

    const parse = (v, k) => {
      let result = ''
      for (let i = 0; i < v.length; ) {
        const begin = v.indexOf(bra, i)
        if (begin >= 0) {
          const end = v.indexOf(ket, begin)
          if (end >= 0) {
            result += v.slice(i, begin)
            result += params(v.slice(begin + bra.length, end), k)
            i = end + ket.length
            continue
          }
        }
        result += v.slice(i)
        break
      }
      return result
    }
    if (!keys) {
      return value // plain data
    }
    if (!keys.length) {
      return undefined // nothing to substitute
    }
    if (!Array.isArray(keys[0])) {
      return parse(value, keys) // value with substituted tokens
    }
    const localizedValue = t(value) // value is template with tokens
    return keys.map((x) => parse(localizedValue, x)) //array of values with substituted tokens
  }, [value, keys])

  return label || data || compliance ? (
    <>
      <div className={className}>
        {label && <PreviewLabel>{t(label)}</PreviewLabel>}
        {data && (
          <PreviewValue>
            {Array.isArray(data)
              ? data.map((x, i) => <div key={i}>{x}</div>)
              : data}
          </PreviewValue>
        )}
        {compliance && (
          <FlexRow className={'alignCenter'}>
            <IconXCircle
              customStyle={{ flexShrink: 0 }}
              size={20}
              filled
              color={'red7'}
            />
            <PreviewLabel className={'marginLeft4'}>{compliance}</PreviewLabel>
          </FlexRow>
        )}
      </div>
    </>
  ) : null
}

export default PreviewItem
