import React, { memo } from 'react'
import { RadioButton, RadioGroup } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'
import WarningMessage from 'components/policies/settings/attributes/device/controls/WarningMessage'

const LabelRadioButtons = (props) => {
  const {
    className,
    label,
    required,
    helpButton,
    disabled,
    options,
    message,
    type,
    ...params
  } = props
  return (
    <div className={className}>
      {label && (
        <FlexRow className={'alignCenter marginBottom8'}>
          <TextBlack
            className={required ? 'requiredField' : undefined}
            disabled={disabled}
          >
            {label}
          </TextBlack>
          <FlexRow className={'paddingLeft4 lineBreak'}>{helpButton}</FlexRow>
        </FlexRow>
      )}
      <RadioGroup {...params} name={params.id}>
        {options.map((option) => {
          return (
            <RadioButton
              value={option.value}
              label={option.label}
              disabled={disabled || option.disabled}
              key={option.value}
            />
          )
        })}
      </RadioGroup>
      {message && (
        <WarningMessage
          id={params.id + '.warning'}
          message={message}
          type={type}
        />
      )}
    </div>
  )
}

export default memo(LabelRadioButtons)
