import Policy, { Attribute, DeviceSettings } from './model/api/Policy'

/**
 * Compare 2 policies and return false in case of any difference (even if items aren't presented in one of collections),
 * otherwise - true
 *
 * @param selectedPolicy specifies first policy for checking for difference
 * @param clonedPolicy specifies second policy for checking for difference
 */
export function equalPolicies(
  selectedPolicy: Policy,
  clonedPolicy: Policy
): boolean {
  if (selectedPolicy) {
    if (
      selectedPolicy.name !== clonedPolicy.name ||
      selectedPolicy.description !== clonedPolicy.description ||
      selectedPolicy.attributes.length !== clonedPolicy.attributes.length
    ) {
      return false
    }
    for (const policyAttribute of clonedPolicy.attributes) {
      const selectAttribute: Attribute = selectedPolicy.attributes.find(
        (attribute) => attribute.name === policyAttribute.name
      )
      if (!selectAttribute) {
        return false
      }

      // Check metadata settings
      const { metadata: policyMetadata } = policyAttribute
      const { metadata: selectMetadata } = selectAttribute
      if (
        policyMetadata.severity !== selectMetadata.severity ||
        policyMetadata.remediation !== selectMetadata.remediation ||
        policyMetadata.unsupported !== selectMetadata.unsupported ||
        policyMetadata.unentitled !== selectMetadata.unentitled
      ) {
        return false
      }

      // Check device settings
      if (
        policyAttribute.deviceSettings.length !==
        selectAttribute.deviceSettings.length
      ) {
        return false
      }
      for (const policyDeviceSetting of policyAttribute.deviceSettings) {
        const cloneDeviceSetting: DeviceSettings =
          selectAttribute.deviceSettings.find(
            (setting) => setting.name === policyDeviceSetting.name
          )
        if (!cloneDeviceSetting) {
          return false
        }

        if (policyDeviceSetting.value !== cloneDeviceSetting.value) {
          return false
        }
      }
    }
  }

  return true
}
