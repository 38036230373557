import { TableI18n } from '@veneer/core'
import { useContext, useMemo } from 'react'
import configContext from 'context/config/configContext'

const localizationPath = 'i18n.table.'

const Retrievei18nItems = (): TableI18n => {
  const { t } = useContext(configContext)

  return useMemo(() => {
    return {
      actionButton: t(localizationPath + 'action-button'),
      cancelButton: t(localizationPath + 'cancel-button'),
      clearAllFilters: t(localizationPath + 'clear-filters'),
      columnOptions: t(localizationPath + 'column-options'),
      columnResizeTooltip: t(localizationPath + 'resize-tool-tip'),
      deselectAllItems: t(localizationPath + 'deselect-all'),
      downButton: t(localizationPath + 'down-button'),
      itemSelected: t(localizationPath + 'item-selected'),
      itemsSelected: t(localizationPath + 'items-selected'),
      noItems: t(localizationPath + 'no-items'),
      resetToDefault: t(localizationPath + 'reset-default'),
      saveButton: t(localizationPath + 'save-button'),
      selectAllItems: t(localizationPath + 'select-items'),
      selectAllPageItems: t(localizationPath + 'select-page-items'),
      sortByAscending: t(localizationPath + 'sort-by-ascending'),
      sortByAscendingActive: t(localizationPath + 'sort-by-ascending-active'),
      sortedAscending: t(localizationPath + 'sorted-ascending'),
      sortByDescending: t(localizationPath + 'sort-by-descending'),
      sortByDescendingActive: t(localizationPath + 'sort-by-descending-active'),
      sortedDescending: t(localizationPath + 'sorted-descending'),
      upButton: t(localizationPath + 'up-button')
    }
  }, [])
}

export default Retrievei18nItems
