import { Item } from 'context/policies/PoliciesConfiguration'

const sioProvider = (provider) => {
  return {
    value: 'app-deployment.sio-config.provider.' + provider,
    label: 'provider-' + provider
  }
}

export default class sioConfigItems {
  static sioProviders: Item[] = [
    sioProvider('hp'),
    sioProvider('google'),
    sioProvider('azure')
  ]
}
