import React from 'react'
import { Button, ButtonGroup, IconInfo, Modal } from '@veneer/core'
import { FlexRow } from 'styles/styles'

const HomeScreenAppsFolderDeleteModal = (props) => {
  const { getLocalized, onClose, onOk, folderName } = props

  return (
    <Modal
      className={'small-policy-modal'}
      data-testid={'id-folder-remove-modal'}
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      footer={
        <ButtonGroup>
          <Button
            id={'id-folder-remove-modal-remove'}
            onClick={() => {
              onOk()
              onClose()
            }}
          >
            {getLocalized('common.remove')}
          </Button>
          <Button
            id={'id-folder-remove-modal-cancel'}
            appearance={'secondary'}
            onClick={onClose}
          >
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'marginBottom20 alignCenter'}>
        <IconInfo size={36} filled color={'gray6'} />
        <h4 className={'marginLeft8'}>{getLocalized('menu-remove')}</h4>
      </FlexRow>
      <p>
        {getLocalized('remove-folder-1', { folderName })}
        <br />
        {getLocalized('remove-folder-2')}
      </p>
    </Modal>
  )
}

export default HomeScreenAppsFolderDeleteModal
