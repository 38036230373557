import Policy, {
  Attribute,
  SetPolicy,
  SetDevicePolicy,
  CreatePolicy
} from 'common/model/api/Policy'

export const setPolicy = (selectedPolicy: Policy) => {
  const policy: SetPolicy = {
    id: selectedPolicy.id,
    name: selectedPolicy.name,
    description: selectedPolicy.description,
    attributes: selectedPolicy.attributes
  }
  return policy
}

export const newDevicePolicy = (deviceId: string, attributes: Attribute[]) => {
  const policy: SetDevicePolicy = { deviceId, attributes }
  return policy
}

export const newPolicy = (
  name: string,
  description: string,
  attributes: Attribute[]
) => {
  const policy: CreatePolicy = { name, description, attributes }
  return policy
}
