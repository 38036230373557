import React from 'react'
import { Card } from '@veneer/core'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import QuickSetsItems from './QuickSetsItems'
import { QuickSetsEnum } from './QuickSetsEnums'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'

const AddQuickSetStep1 = (props) => {
  const { value, onChange, requiredErrors, setRequiredErrors } = props

  const getLocalized = (key, step = 1) => props.getLocalized(key, step)

  const generateCardContent = () => {
    const error = requiredErrors[QuickSetsEnum.TITLE]
    return (
      <>
        <div className={'padding24'}>
          <LabelTextBox
            required={true}
            label={getLocalized('title', 0)}
            className={'marginBottom16 maxTextWidth'}
            id={'quickSetTitle'}
            type={'text'}
            value={value[QuickSetsEnum.TITLE] || ''}
            onChange={(value) => {
              setRequiredErrors((prevValue) => {
                const newValue = { ...prevValue }
                const retrieveError = getError(
                  PoliciesErrors.errorsNotSelected,
                  value
                )
                if (retrieveError) {
                  newValue[QuickSetsEnum.TITLE] = {
                    message: retrieveError.message
                  }
                } else {
                  delete newValue[QuickSetsEnum.TITLE]
                }
                return newValue
              })
              onChange({ [QuickSetsEnum.TITLE]: value })
            }}
            error={!!error}
            placeholder={getLocalized('title-placeholder')}
            helperText={getLocalized(
              error?.message || 'title-placeholder',
              error?.params
            )}
          />
          <LabelTextBox
            label={getLocalized('description', 0)}
            id={'quickSetDescription'}
            textArea={true}
            value={value[QuickSetsEnum.DESCRIPTION] || ''}
            onChange={(value) =>
              onChange({ [QuickSetsEnum.DESCRIPTION]: value })
            }
            className={'marginBottom16 maxTextWidth'}
            placeholder={getLocalized('description-placeholder')}
            helperText={getLocalized('description-placeholder')}
          />
          <LabelRadioButtons
            id={'quickSetStartOption'}
            label={getLocalized(QuickSetsEnum.START)}
            options={QuickSetsItems.startOptions.map((item) => {
              return {
                value: item.value,
                label: getLocalized(item.label)
              }
            })}
            value={value[QuickSetsEnum.START]}
            onChange={(_, value) => onChange({ [QuickSetsEnum.START]: value })}
          />
        </div>
      </>
    )
  }
  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header')}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description')}</p>
      <Card
        id={'add-quick-set-step-1'}
        content={generateCardContent()}
        border={'dropShadow'}
      />
    </>
  )
}

export default AddQuickSetStep1
