import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import { TextBox } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'
import { PasswordDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import settingsContext from 'context/settings/settingsContext'
import HelpButton from 'components/policies/settings/attributes/HelpButton'

const PasswordControl = (props) => {
  const description: PasswordDescription = props.description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const enabled = isEnabled(description.attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, props.data.deviceSettings)
  )
  const [valueConfirm, setValueConfirm] = useState(value)
  const [error, setError] = useState(
    PoliciesHelper.getError(description, value)
  )
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const err = enabled ? PoliciesHelper.getError(description, value) : null
    setError(showError ? err : null)
    err
      ? addError(props.id, description.attribute, showError)
      : removeError(props.id, description.attribute)
  }, [enabled, value, showError])

  useEffect(() => {
    const val = PoliciesHelper.getData(description, props.data.deviceSettings)
    setValue(val)
    setValueConfirm(val)
  }, [props.data.deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, props.data.deviceSettings)
    if (props.attributes && val === value) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        props.attributes
      )
    }
  }, [value, valueConfirm, props.attributes])

  const onChange = (val) => {
    setValue(val)
    setDisplayError(true)
    const deviceSettings = [...props.data.deviceSettings]
    PoliciesHelper.setData(
      description,
      deviceSettings,
      val === '' && !enabled ? null : val // set null for disabled
    )
    props.onAttributeChange({ ...props.data, deviceSettings })
  }

  const confirmError = valueConfirm !== value
  const disabledHelp = !enabled && !!description.disabledHelp
  return (
    <>
      <FlexRow className={'alignCenter marginBottom4'}>
        <TextBlack>
          {getLocalized(
            description.label ? description.label : description.hint
          )}
        </TextBlack>
        <FlexRow className={'paddingLeft4'}>
          {(description.help || disabledHelp) && (
            <HelpButton
              enabled={enabled || disabledHelp}
              description={getLocalized(
                disabledHelp ? description.disabledHelp : description.help
              )}
            />
          )}
        </FlexRow>
      </FlexRow>

      <FlexRow>
        <TextBox
          id={description.attribute}
          type={enabled ? 'password' : 'text'}
          placeholder={getLocalized(description.hint)}
          helperText={getLocalized(
            error?.message || description.hint,
            error?.params
          )}
          value={enabled || value ? value : PoliciesHelper.getPassword()}
          error={!!error}
          disabled={!enabled}
          onChange={onChange}
          className={'maxTextWidth'}
        />
      </FlexRow>
      {enabled && (
        <>
          <FlexRow className={'alignCenter paddingTop16'}>
            <TextBox
              id={'${description.attribute}.confirm'}
              type={enabled ? 'password' : 'text'}
              label={getLocalized(description.confirmationHint)}
              helperText={getLocalized(
                confirmError
                  ? description.errorMessage
                  : description.confirmationHint
              )}
              value={valueConfirm}
              error={confirmError}
              disabled={!enabled}
              onChange={setValueConfirm}
              className={'maxTextWidth'}
            />
          </FlexRow>
        </>
      )}
    </>
  )
}

export default memo(PasswordControl)
