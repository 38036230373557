import React, { useContext, useEffect, useState, useMemo, memo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import {
  ControlWidthEnum,
  DropBoxDescription
} from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import LabelSelect from 'common/controls/labelSelect'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const DropBoxControl = (props) => {
  const description: DropBoxDescription = props.description
  const {
    id,
    attributes,
    data: { deviceSettings }
  } = props
  const { attribute, help, disabledHelp, label, radio } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addWarning,
    removeWarning,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string): string => tt(props.localizationPath, key)

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState([
    PoliciesHelper.getData(description, deviceSettings)
  ])

  const valueString = useMemo(() => value.toString(), [value])
  const [warning, setWarning] = useState(
    PoliciesHelper.getWarning(description, value.toString())
  )
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const warn = PoliciesHelper.getWarning(description, valueString)
    setWarning(warn)
    warn?.type == MessageTypesEnum.WARNING
      ? addWarning(id, attribute)
      : removeWarning(id, attribute)

    const err = enabled && !valueString // "not selected" error
    setError(showError ? err : null)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  useEffect(
    () => setValue([PoliciesHelper.getData(description, deviceSettings)]),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val === valueString) {
      PoliciesHelper.update(
        description,
        val,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [valueString, attributes])

  const onChange = (v) => {
    const val = v.toString()
    setValue([val])
    setDisplayError(true)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, val)
    props.onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const options = description.items.map((v) => {
    return {
      value: v.value,
      label: v.label ? getLocalized(v.label) : v.value,
      disabled: !isEnabled(attribute + '_' + v.value)
    }
  })

  const helpButton = useMemo(() => {
    const disHelp = !enabled && !!disabledHelp
    return (
      (help || disHelp) && (
        <HelpButton
          enabled={enabled || disHelp}
          description={getLocalized(disHelp ? disabledHelp : help)}
        />
      )
    )
  }, [help, enabled, disabledHelp])

  return radio ? (
    <LabelRadioButtons
      disabled={!enabled}
      label={label ? getLocalized(label) : undefined}
      helpButton={helpButton}
      id={attribute}
      value={valueString}
      onChange={(e, v) => onChange(v)}
      options={options.map((option) => {
        return { ...option, disabled: !enabled || option.disabled }
      })}
      type={error ? MessageTypesEnum.ERROR : warning?.type}
      message={
        warning || error
          ? getLocalized(error ? 'common.errors.not-selected' : warning.message)
          : undefined
      }
    />
  ) : (
    <LabelSelect
      disabled={!enabled}
      label={label ? getLocalized(label) : undefined}
      helpButton={helpButton}
      id={attribute}
      placeholder={getLocalized('common.select-option')}
      helperText={
        error ? getLocalized('common.errors.not-selected') : undefined
      }
      options={options}
      value={value}
      error={error}
      onChange={({ value: v }) => onChange(v)}
      className={ControlWidthEnum.NORMAL}
      type={warning?.type}
      message={warning ? getLocalized(warning.message) : undefined}
    />
  )
}

export default memo(DropBoxControl)
