import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const faxMethod = (method, option?) => {
  return {
    value: 'fax-method.' + method,
    label: 'policy.settings.fax-methods.' + method,
    option
  }
}

export default class FaxMethodItems {
  static allMethods: Item[] = [
    faxMethod('modem', 'internalModem'),
    faxMethod('internet', 'internetFaxService'),
    faxMethod('ip-fax', 'ipFax')
  ]
  static faxSendMethods = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? FaxMethodItems.allMethods
      : constraints.filterSettingOptions(
          FaxMethodItems.allMethods,
          ConstraintsResourceEnum.faxSendCfg,
          ConstraintsSettingEnum.faxSendMethod
        )

  static faxReceiveMethods = (constraints?: PolicyConstraints): Item[] => {
    const receiveMethods = [
      FaxMethodItems.allMethods[0],
      FaxMethodItems.allMethods[2]
    ]
    return !constraints
      ? receiveMethods
      : constraints.filterSettingOptions(
          receiveMethods,
          ConstraintsResourceEnum.faxSendCfg,
          ConstraintsSettingEnum.faxSendMethod // change when faxReceiveMethod is available
        )
  }
}
