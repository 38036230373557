import React, { useState, useMemo } from 'react'
import moment from 'moment'
import { Button, ButtonGroup, Modal, Select, DatePicker } from '@veneer/core'
import WarningMessage from '../WarningMessage'
import { hours24to12, isPm, hours12to24 } from 'common/utilities'
import LabelTextBox from 'common/controls/labelTextBox'
import TimeItems from 'context/policies/dropboxItems/TimeItems'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

export enum HolidayScheduleEnum {
  NAME = 'name',
  START = 'start',
  END = 'end'
}

const HolidayScheduleModal = (props) => {
  const { value, values, edit, onClose, onChange, getLocalized } = props
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState({})

  const setData = (x) => setInputValue((prevValue) => ({ ...prevValue, ...x }))

  const checkErrorName = (x?) => {
    const val = { ...inputValue, ...x }
    const err = !val[HolidayScheduleEnum.NAME].length
    setError((prevValue) => ({
      ...prevValue,
      [HolidayScheduleEnum.NAME]: err ? 'error-event-name-required' : null
    }))
    return !err
  }

  const checkErrorRange = (x?) => {
    const val = { ...inputValue, ...x }
    const err = {
      [HolidayScheduleEnum.START]: null,
      [HolidayScheduleEnum.END]: null
    }
    const start = new Date(val[HolidayScheduleEnum.START])
    const end = new Date(val[HolidayScheduleEnum.END])
    if (new Date() >= start) {
      err[HolidayScheduleEnum.START] = 'error-past-date-time'
    }
    if (end <= start) {
      err[HolidayScheduleEnum.END] = 'error-start-end-date-time'
    } else {
      if (
        values.some(
          (x) =>
            new Date(x[HolidayScheduleEnum.START]).getTime() ===
              start.getTime() &&
            new Date(x[HolidayScheduleEnum.END]).getTime() === end.getTime()
        )
      ) {
        err[HolidayScheduleEnum.END] = 'error-same-holiday'
      }
    }
    setError((prevValue) => ({ ...prevValue, ...err }))
    return !(err[HolidayScheduleEnum.START] || err[HolidayScheduleEnum.END])
  }

  const onSave = () => {
    if (checkErrorName() && checkErrorRange()) {
      onChange(inputValue)
      onClose()
    }
  }

  const saveDisabled = useMemo(
    () => JSON.stringify(inputValue) === JSON.stringify(value),
    [inputValue, value]
  )

  const amPmOptions = TimeItems.amPms.map((x) => {
    return { ...x, label: getLocalized(x.label) }
  })

  const dateTimeControls = (label, key) => {
    const setDateTime = (updater) => {
      const date = new Date(inputValue[key])
      updater(date)
      const newValue = { [key]: date.toISOString() }
      checkErrorRange(newValue)
      setData(newValue)
    }
    return (
      <FlexColumn className={'paddingTop16'}>
        <TextBlack className={'marginBottom4'}>{getLocalized(label)}</TextBlack>
        <FlexRow className={'alignCenter'}>
          <DatePicker
            className={'paddingRight16'}
            calendarOrientation={['down']} // + 'right'
            onChange={(date: moment.Moment) =>
              setDateTime((x) =>
                x.setFullYear(date.year(), date.month(), date.date())
              )
            }
            orientation={'horizontal'}
            placeholder={getLocalized('date-time_hint')}
            value={moment(inputValue[key])}
            clearIcon={false}
          />
          <Select
            options={TimeItems.hours}
            placeholder={getLocalized('common.select-option')}
            className={'formatSelect'}
            id={key}
            clearIcon={false}
            value={[
              hours24to12(new Date(inputValue[key]).getHours()).toString()
            ]}
            onChange={({ value }) =>
              setDateTime((x) =>
                x.setHours(
                  hours12to24(parseInt(value), isPm(x.getHours())) as number
                )
              )
            }
          />
          <TextBlack className={'paddingLeft4'}>
            {getLocalized('policy.settings.time.separator')}
          </TextBlack>
          <Select
            options={TimeItems.minutesBy10}
            placeholder={getLocalized('common.select-option')}
            id={key}
            clearIcon={false}
            className={'formatSelect paddingLeft4'}
            value={[new Date(inputValue[key]).getMinutes().toString()]}
            onChange={({ value }) =>
              setDateTime((x) => x.setMinutes(parseInt(value), 0, 0))
            }
          />
          <Select
            options={amPmOptions}
            placeholder={getLocalized('common.select-option')}
            clearIcon={false}
            className={'formatSelect paddingLeft4'}
            value={[
              amPmOptions[isPm(new Date(inputValue[key]).getHours()) ? 1 : 0]
                .value
            ]}
            onChange={({ value }) =>
              setDateTime((x) =>
                x.setHours(
                  hours12to24(
                    hours24to12(x.getHours()),
                    amPmOptions[1].value === value
                  ) as number
                )
              )
            }
          />
        </FlexRow>
        {error[key] && (
          <WarningMessage
            type={MessageTypesEnum.ERROR}
            message={getLocalized(error[key])}
          />
        )}
      </FlexColumn>
    )
  }

  return (
    <Modal
      show={true}
      className={'small-policy-modal'}
      data-testid={'id-holiday-schedule-modal'}
      onClose={onClose}
      closeOnBlur={false}
      title={getLocalized(
        edit ? 'holiday-schedule-edit' : 'holiday-schedule-add'
      )}
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveDisabled}>
            {getLocalized(edit ? 'common.save' : 'common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p>
        {getLocalized(
          edit
            ? 'holiday-schedule-edit-description'
            : 'holiday-schedule-add-description'
        )}
      </p>

      <div className={'paddingTop16'}>
        <LabelTextBox
          label={getLocalized('event-name')}
          value={inputValue[HolidayScheduleEnum.NAME] || ''}
          placeholder={getLocalized('event-name_hint')}
          onChange={(val) => {
            const newValue = { [HolidayScheduleEnum.NAME]: val }
            checkErrorName(newValue)
            setData(newValue)
          }}
          error={!!error[HolidayScheduleEnum.NAME]}
          helperText={getLocalized(
            error[HolidayScheduleEnum.NAME]
              ? 'error-event-name-required'
              : 'event-name_hint'
          )}
          className={'maxTextWidth'}
        />
      </div>

      {dateTimeControls('start-date-time', HolidayScheduleEnum.START)}
      {dateTimeControls('end-date-time', HolidayScheduleEnum.END)}
    </Modal>
  )
}
export default HolidayScheduleModal
