import { SettingsActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case SettingsActions.CLEAN_UP: {
      const warnings = {}
      const errors = {}
      for (const key in state.warnings) {
        if (action.payload.includes(key)) {
          warnings[key] = state.warnings[key]
        }
      }
      for (const key in state.errors) {
        if (action.payload.includes(key)) {
          errors[key] = state.errors[key]
        }
      }
      const disabled = state.disabled.filter((x) =>
        action.payload.find((y) => x.startsWith(y))
      )
      return { ...state, warnings, errors, disabled }
    }

    case SettingsActions.ADD_WARNING: {
      const { id, attribute } = action.payload
      let items = [attribute]
      const found = state.warnings[id]
      if (found) {
        if (found.includes(attribute)) {
          return state
        } else {
          items = [...items, ...found]
        }
      }
      const warnings = { ...state.warnings }
      warnings[id] = items
      return { ...state, warnings } // return state
    }

    case SettingsActions.REMOVE_WARNING: {
      const { id, attribute } = action.payload
      const found = state.warnings[id]
      if (found && found.includes(attribute)) {
        const items = found.filter((x) => x !== attribute)
        const warnings = { ...state.warnings }
        warnings[id] = items
        return { ...state, warnings } // return state
      }
      return state
    }

    case SettingsActions.ADD_ERROR: {
      const { id, attribute, display } = action.payload
      let items = [{ attribute, display }]
      const found = state.errors[id]
      if (found) {
        const item = found.find((x) => x.attribute === attribute)
        if (item) {
          if (item.display === display) {
            return state
          }
          item.display = display
          items = [...found]
        } else {
          items = [...items, ...found]
        }
      }
      const errors = { ...state.errors }
      errors[id] = items
      return { ...state, errors } // return state
    }

    case SettingsActions.REMOVE_ERROR: {
      const { id, attribute } = action.payload
      const found = state.errors[id]
      if (found && found.find((x) => x.attribute === attribute)) {
        const items = found.filter((x) => x.attribute !== attribute)
        const errors = { ...state.errors }
        errors[id] = items
        return { ...state, errors } // return state
      }
      return state
    }

    case SettingsActions.DISPLAY_ALL_ERRORS: {
      const { display } = action.payload
      return { ...state, displayAllErrors: display }
    }

    case SettingsActions.ADD_DISABLED: {
      const additions = []
      action.payload.forEach((id) => {
        if (!state.disabled.includes(id)) {
          additions.push(id)
        }
      })
      return additions.length
        ? { ...state, disabled: [...state.disabled, ...additions] }
        : state
    }

    case SettingsActions.REMOVE_DISABLED: {
      const removals = []
      action.payload.forEach((id) => {
        if (state.disabled.includes(id)) {
          removals.push(id)
        }
      })
      return removals.length
        ? {
            ...state,
            disabled: state.disabled.filter((x) => !removals.includes(x))
          }
        : state
    }

    default:
      return state
  }
}
