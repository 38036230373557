import React, { useContext } from 'react'
import { Button, ButtonGroup, IconXCircle, Modal } from '@veneer/core'
import policiesContext from 'context/policies/policiesContext'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'

export const Error = () => {
  const policiesCtxt = useContext(policiesContext)
  const { error, errorFlag, hideError } = policiesCtxt
  const cContext = useContext(configContext)
  const { t } = cContext

  return (
    errorFlag && (
      <Modal
        onClose={hideError}
        closeOnBlur={false}
        show={errorFlag}
        className={'small-policy-modal'}
        data-testid={'id-policies-error-modal'}
        footer={
          <ButtonGroup>
            <Button onClick={hideError} id={'idCloseError'}>
              {t('common.ok')}
            </Button>
          </ButtonGroup>
        }
      >
        <FlexRow className={'marginBottom20 alignCenter'}>
          <IconXCircle size={36} filled color={'red7'} />
          <h4
            className={'marginLeft8'}
            data-testid={'id-policies-error-modal-title'}
          >
            {t('error.title')}
          </h4>
        </FlexRow>
        <p data-testid={'id-policies-error-modal-text'}>
          {error ? error.message : ''}
        </p>
      </Modal>
    )
  )
}

export default Error
