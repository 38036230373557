import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const countryCode = (x) => {
  return {
    value: 'country-code.' + x,
    label: 'policy.settings.countries.' + x,
    option: x.toUpperCase()
  }
}

export default class CountryItems {
  static items: Item[] = [
    countryCode('ao'),
    countryCode('ar'),
    countryCode('au'),
    countryCode('at'),
    countryCode('bd'),
    countryCode('be'),
    countryCode('by'),
    countryCode('br'),
    countryCode('bn'),
    countryCode('bg'),
    countryCode('ca'),
    countryCode('kh'),
    countryCode('cl'),
    countryCode('cn'),
    countryCode('co'),
    countryCode('cr'),
    countryCode('hr'),
    countryCode('cy'),
    countryCode('cz'),
    countryCode('dk'),
    countryCode('ec'),
    countryCode('eg'),
    countryCode('ee'),
    countryCode('fi'),
    countryCode('fr'),
    countryCode('de'),
    countryCode('gr'),
    countryCode('hk'),
    countryCode('hu'),
    countryCode('is'),
    countryCode('in'),
    countryCode('id'),
    countryCode('ie'),
    countryCode('il'),
    countryCode('it'),
    countryCode('jp'),
    countryCode('jo'),
    countryCode('kz'),
    countryCode('kp'),
    countryCode('kw'),
    countryCode('la'),
    countryCode('lv'),
    countryCode('lb'),
    countryCode('li'),
    countryCode('lt'),
    countryCode('lu'),
    countryCode('my'),
    countryCode('mv'),
    countryCode('mt'),
    countryCode('mx'),
    countryCode('mn'),
    countryCode('ma'),
    countryCode('mz'),
    countryCode('mm'),
    countryCode('nl'),
    countryCode('nz'),
    countryCode('no'),
    countryCode('pk'),
    countryCode('pa'),
    countryCode('py'),
    countryCode('pe'),
    countryCode('ph'),
    countryCode('pl'),
    countryCode('pt'),
    countryCode('qa'),
    countryCode('ro'),
    countryCode('ru'),
    countryCode('sa'),
    countryCode('sg'),
    countryCode('sk'),
    countryCode('si'),
    countryCode('za'),
    countryCode('es'),
    countryCode('se'),
    countryCode('lk'),
    countryCode('ch'),
    countryCode('tw'),
    countryCode('th'),
    countryCode('tl'),
    countryCode('tn'),
    countryCode('tr'),
    countryCode('ua'),
    countryCode('uy'),
    countryCode('uk'),
    countryCode('us'),
    countryCode('ae'),
    countryCode('ve'),
    countryCode('vn'),
    countryCode('ye')
  ]

  static countryCodes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? CountryItems.items
      : constraints.filterSettingOptions(
          CountryItems.items,
          ConstraintsResourceEnum.faxModemCfg,
          ConstraintsSettingEnum.analogFaxSetupCountry
        )
}
