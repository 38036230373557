import { Item } from '../PoliciesConfiguration'

const fileNameToken = (value, label) => {
  return { value, label: 'policy.settings.file-name-tokens.' + label }
}

const fileColor = (x) => {
  return {
    value: 'file-color-preference.' + x,
    label: 'policy.settings.file-color-preferences.' + x
  }
}

const fileQuality = (x) => {
  return {
    value: 'file-quality.' + x,
    label: 'policy.settings.file-qualities.' + x
  }
}

const fileType = (x, e?) => {
  return {
    value: 'file-type.' + x,
    label: 'policy.settings.file-types.' + x,
    extension: '.' + (e || x)
  }
}

const fileResolution = (x) => {
  return {
    value: x,
    label: `policy.settings.resolutions.${x}-dpi`
  }
}

const fileCompression = (x, suffix = '') => {
  return {
    value: `file-compression${suffix}.${x}`,
    label: `policy.settings.file-compressions${suffix}.${x}`
  }
}

const blackSuffix = '-black'
const colorSuffix = '-color'

export default class FileSettingItems {
  static fileNameTokenItems: Item[] = [
    fileNameToken('', 'none'),
    fileNameToken('%DEVICE_DATE_DDMMYYYY%', 'date-ddmmyyyy'),
    fileNameToken('%DEVICE_DATE_MMDDYYYY%', 'date-mmddyyyy'),
    fileNameToken('%DEVICE_DATE_YYMMDD%', 'date-yymmdd'),
    fileNameToken('%DEVICE_DATE_YYYYMMDD%', 'date-yyyymmdd'),
    fileNameToken('%DEVICE_TIME_HHMMSS%', 'time-hhmmss'),
    fileNameToken('%SECURITY_USERNAME%', 'user-name'),
    fileNameToken('%DEVICE_HOSTNAME%', 'host-name'),
    fileNameToken('%DEVICE_HOSTNAME_PQDN%', 'host-name-pqdn')
  ]

  static fileColorPrefItems: Item[] = [
    fileColor('color-black-auto'),
    fileColor('color-gray-auto'),
    fileColor('black'),
    fileColor('black-gray'),
    fileColor('color')
  ]

  static fileQualityItems: Item[] = [
    fileQuality('high'),
    fileQuality('low'),
    fileQuality('medium')
  ]

  static fileTypeItems: Item[] = [
    fileType('csv'),
    fileType('html', 'zip'),
    fileType('jpeg', 'jpg'),
    fileType('m-tiff', 'tif'),
    fileType('pdf'),
    fileType('pdfa', 'pdf'),
    fileType('rtf'),
    fileType('s-pdf', 'pdf'),
    fileType('s-pdfa', 'pdf'),
    fileType('text', 'txt'),
    fileType('tiff', 'tif'),
    fileType('u-text', 'txt'),
    fileType('xps')
  ]
  static isFileTypeTiff = (type) =>
    ['file-type.tiff', 'file-type.m-tiff'].includes(type)
  static isFileTypePdf = (type) =>
    ['file-type.pdf', 'file-type.s-pdf'].includes(type)
  static isFileTypeCompressed = (type) =>
    FileSettingItems.isFileTypePdf(type) ||
    ['file-type.pdfa', 'file-type.s-pdfa', 'file-type.xps'].includes(type)

  static fileResolutionItems: Item[] = [
    fileResolution('75'),
    fileResolution('100'),
    fileResolution('150'),
    fileResolution('200'),
    fileResolution('240'),
    fileResolution('300'),
    fileResolution('400'),
    fileResolution('500'),
    fileResolution('600')
  ]

  static fileCompressionItems: Item[] = [
    fileCompression('high'),
    fileCompression('normal')
  ]

  static fileCompressionBlackItems: Item[] = [
    fileCompression('auto', blackSuffix),
    fileCompression('g3', blackSuffix),
    fileCompression('g4', blackSuffix),
    fileCompression('lzw', blackSuffix)
  ]

  static fileCompressionColorItems: Item[] = [
    fileCompression('lzw', colorSuffix),
    fileCompression('tiff', colorSuffix),
    fileCompression('tiff-6', colorSuffix)
  ]
}
