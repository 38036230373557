import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { TextBlack, FlexRow } from 'styles/styles'
import configContext from 'context/config/configContext'
import { FileUploadDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import policiesContext from 'context/policies/policiesContext'
import settingsContext from 'context/settings/settingsContext'
import { Button } from '@veneer/core'
import PreviewItem from '../previewItem'
import { generateBase64Value } from 'common/utilities'

export enum FileUploadEnum {
  ID = 'id',
  NAME = 'name'
}
const FileUploadControl = (props) => {
  const description: FileUploadDescription = props.description
  const {
    attributes,
    compliance,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute, fileUploadExt, fileUploadType } = description
  const { showError, fleetMgtSvcClient } = useContext(policiesContext)
  const { tt } = useContext(configContext)
  const { addDisabled, removeDisabled, isEnabled } = useContext(settingsContext)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const fileInputRef = useRef(null)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(() => {
    const fileUpload = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(fileUpload) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const handleButtonClick = () => fileInputRef.current.click()

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (!selectedFile || selectedFile.length === 0) return
    const reader = new FileReader()
    reader.onload = async (file) => {
      try {
        const res = await fleetMgtSvcClient.uploadFile(
          selectedFile.name,
          fileUploadType,
          generateBase64Value(file)
        )
        if (res.status && res.status === 200) {
          const newValue = {
            [FileUploadEnum.NAME]: res.data.filename,
            [FileUploadEnum.ID]: res.data.id
          }
          setValue(newValue)
          const settings = [...deviceSettings]
          PoliciesHelper.setData(description, settings, newValue)
          onAttributeChange({ ...props.data, deviceSettings: settings })
        }
      } catch (error) {
        showError(error)
      }
    }
    reader.readAsDataURL(selectedFile)
    fileInputRef.current.value = ''
  }

  const enabled = isEnabled(attribute)
  return onAttributeChange ? (
    <>
      <TextBlack disabled={!enabled} className={'marginBottom4'}>
        {getLocalized('label')}
      </TextBlack>
      <FlexRow className={'alignCenter'}>
        <input
          type={'file'}
          ref={fileInputRef}
          className={'displayNone'}
          onChange={handleFileChange}
          accept={fileUploadExt}
        />
        <Button
          onClick={handleButtonClick}
          disabled={!enabled}
          appearance={'secondary'}
        >
          {getLocalized(
            value[FileUploadEnum.NAME] ? 'change-file' : 'select-file'
          )}
        </Button>
        <TextBlack className={'marginLeft12'} disabled={!enabled}>
          {value[FileUploadEnum.NAME]}
        </TextBlack>
      </FlexRow>
    </>
  ) : (
    <PreviewItem
      label={`${props.localizationPath}.label`}
      value={value[FileUploadEnum.NAME]}
      compliance={compliance}
    />
  )
}

export default memo(FileUploadControl)
