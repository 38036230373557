import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { TextBlack } from 'styles/styles'
import FaxReceiveItems from 'context/policies/dropboxItems/FaxReceiveItems'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { getError } from 'common/utilities'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'

export enum faxReceiveModemEnum {
  RINGER_VOLUME = 'modem-ringer-volume',
  RINGS_TO_ANSWER = 'modem-rings-to-answer'
}

const FaxReceiveInternalModemModal = (props) => {
  const [value, setValue] = useState(props.value)
  const [invalidIntegerValueError, setInvalidIntegerValueError] =
    useState<{ message: string; params }>(null)
  const [disableSave, setDisableSave] = useState(true)

  const [ringerVolumeError, setRingerVolumeError] =
    useState<{ message: string }>(null)

  const getRingerVolumeSelectOptions = FaxReceiveItems.faxModemRingerVolume.map(
    ({ value, label }) => ({
      value,
      label: props.getLocalized(label)
    })
  )

  useEffect(() => {
    setDisableSave(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const getInvalidIntegerError = (integerValue) =>
    getError(
      [
        PoliciesErrors.errorWrongCharNumber,
        PoliciesErrors.errorIntegerRange(1, 6)
      ],
      integerValue
    )

  const onSave = () => {
    const invalidIntegerValueError = getInvalidIntegerError(
      value[faxReceiveModemEnum.RINGS_TO_ANSWER]
    )
    setInvalidIntegerValueError(invalidIntegerValueError)
    const errorRingerVolume = !value[faxReceiveModemEnum.RINGER_VOLUME]
      ? { message: 'common.errors.not-selected' }
      : null

    setRingerVolumeError(errorRingerVolume)

    if (invalidIntegerValueError?.message || errorRingerVolume?.message) {
      return
    }
    props.onChange(value)
    props.onClose()
  }

  return (
    <Modal
      footer={
        <ButtonGroup>
          <Button disabled={disableSave} onClick={onSave}>
            {props.getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={props.onClose}>
            {props.getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      onClose={props.onClose}
      closeOnBlur={false}
      show={true}
      title={props.getLocalized('modem-settings')}
      className={'small-policy-modal'}
      data-testid={'id-fax-internal-modem-modal'}
    >
      <TextBlack className="paddingBottom16">
        {props.getLocalized('modem-description-message')}
      </TextBlack>

      <TextBlack className="paddingBottom16 bold">
        {props.getLocalized('modem-fax-receive-settings')}
      </TextBlack>

      <LabelSelect
        label={props.getLocalized(faxReceiveModemEnum.RINGER_VOLUME)}
        className={'paddingBottom16 maxTextWidth'}
        id={faxReceiveModemEnum.RINGER_VOLUME}
        options={getRingerVolumeSelectOptions}
        error={!!ringerVolumeError}
        helperText={
          ringerVolumeError?.message
            ? props.getLocalized(ringerVolumeError?.message)
            : null
        }
        placeholder={props.getLocalized('common.select-option')}
        value={[value[faxReceiveModemEnum.RINGER_VOLUME]]}
        onChange={(option) => {
          const error = !option.value
            ? { message: 'common.errors.not-selected' }
            : null
          setRingerVolumeError(error)
          setValue({
            ...value,
            [faxReceiveModemEnum.RINGER_VOLUME]: option.value
          })
        }}
      />

      <LabelTextBox
        label={props.getLocalized(faxReceiveModemEnum.RINGS_TO_ANSWER)}
        id={faxReceiveModemEnum.RINGS_TO_ANSWER}
        value={value[faxReceiveModemEnum.RINGS_TO_ANSWER] || ''}
        onChange={(e) => {
          const invalidIntegerValueError = getInvalidIntegerError(e)
          setInvalidIntegerValueError(invalidIntegerValueError)
          setValue({ ...value, [faxReceiveModemEnum.RINGS_TO_ANSWER]: e })
        }}
        error={!!invalidIntegerValueError?.message}
        helperText={props.getLocalized(
          invalidIntegerValueError?.message ||
            faxReceiveModemEnum.RINGS_TO_ANSWER,
          invalidIntegerValueError?.params
        )}
        className={'maxTextWidth'}
      />
    </Modal>
  )
}
export default FaxReceiveInternalModemModal
