import React, { useContext } from 'react'
import { Button, ButtonGroup } from '@veneer/core'
import './index.scss'
import configContext from 'context/config/configContext'

const Footer = (props) => {
  const { t } = useContext(configContext)
  const { onSave, onCancel, error } = props

  return (
    <div>
      <div id={'policy-footer'} className={'policy-fixed'}>
        <div className={'policy-buttons-fixed'}>
          <ButtonGroup>
            <Button
              id={'cancelButton'}
              appearance={'secondary'}
              onClick={onCancel}
            >
              {t('common.cancel')}
            </Button>
            <Button id={'saveButton'} onClick={onSave} disabled={error}>
              {t('common.save')}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}

export default Footer
