import React, { Fragment, memo } from 'react'
import { Grid2 } from 'styles/styles'
import 'styles/global.scss'
import './index.scss'

const DeviceAttributes = ({ deviceSettingForms }) => {
  return (
    <>
      {deviceSettingForms.map((setting) => {
        return (
          <Fragment key={setting.key}>
            <Grid2 className={setting.className}>
              <div>{setting.form}</div>
            </Grid2>
          </Fragment>
        )
      })}
    </>
  )
}

export default memo(DeviceAttributes)
