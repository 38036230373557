import React, { useContext } from 'react'
import settingsContext from 'context/settings/settingsContext'
import PolicyHeaderRight from './index'
import 'styles/global.scss'

const PolicyHeaderRightWrapper = (props) => {
  const { description, status, onRemove, nonEntitled } = props
  if (status) {
    return <PolicyHeaderRight description={description} status={status} />
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { addWarning, getWarnings, getErrors } = useContext(settingsContext)

    // Could not find better place to set 'non-entitled' warning :(
    if (nonEntitled) {
      addWarning(description.id, 'non-entitled')
    }

    return (
      <PolicyHeaderRight
        description={description}
        errors={getErrors(description)}
        warnings={getWarnings(description)}
        onRemove={onRemove}
      />
    )
  }
}

export default PolicyHeaderRightWrapper
