import React, { useContext, memo } from 'react'
import uuid from 'react-uuid'
import { Button, IconHelp } from '@veneer/core'
import { BadgeLabel, FlexColumn, FlexRow } from 'styles/styles'
import configContext from 'context/config/configContext'
import PoliciesConfiguration from 'context/policies/PoliciesConfiguration'
import { getDecoratedName } from 'common/decoratedName'
import './index.scss'
import 'styles/global.scss'

const PolicyHeaderContent = (props) => {
  const { t } = useContext(configContext)
  const { description, search, advanced, setHelpText } = props

  const localizationPath = PoliciesConfiguration.getPolicyAttrLocPath(
    description.id
  )
  const name = t(localizationPath + '.name')

  const helpButton = () => {
    return (
      <Button
        id={'policySettingDescription'}
        key={uuid()}
        className={'iconButton alignSelfCenter'}
        appearance={'ghost'}
        small={true}
        onClick={() =>
          setHelpText({
            name,
            description: t(localizationPath + '.description')
          })
        }
      >
        <IconHelp color={'hpBlue7'} size={20} key={uuid()} />
      </Button>
    )
  }

  const decoratedName = getDecoratedName(name, search, 'searchTokenBlue')

  return (
    <FlexColumn key={uuid()} className={'contentColumn'}>
      <FlexRow className={'alignCenter'} id={'policySettingHeader'}>
        <FlexRow className={'attributeName'}>
          {decoratedName.length ? decoratedName : name}
        </FlexRow>
        <FlexRow className={'paddingLeft6'}>{helpButton()}</FlexRow>
        {advanced && (
          <BadgeLabel className={'marginLeft8'}>
            {t('policy.settings.entitlements.advanced')}
          </BadgeLabel>
        )}
      </FlexRow>
    </FlexColumn>
  )
}

export default memo(PolicyHeaderContent)
