import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from 'context/policies/PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export default class OutgoingServersItems {
  static allCredentialModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Outgoing_Servers}.servers.credential.device-user`,
      label: 'device-user',
      option: 'signInUser'
    },
    {
      value: `${PolicyAttributeEnum.Outgoing_Servers}.servers.credential.public`,
      label: 'public',
      option: 'alwaysUseCredential'
    }
  ]

  static outgoingServerCredentialModes = (
    constraints?: PolicyConstraints
  ): Item[] =>
    !constraints
      ? OutgoingServersItems.allCredentialModes
      : constraints.filterSettingOptions(
          OutgoingServersItems.allCredentialModes,
          ConstraintsResourceEnum.smtpServers,
          ConstraintsSettingEnum.credentialType
        )
}
