import React, { useContext, useEffect, useMemo, useState, memo } from 'react'
import FileName, {
  getFileNamePreview
} from 'components/policies/modal/FileName'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import {
  ObjectAttributeDescription,
  DropBoxDescription
} from 'context/policies/PoliciesConfiguration'
import settingsContext from 'context/settings/settingsContext'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

const FileNameControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description
  const {
    isEnabled,
    addError,
    removeError,
    displayAllErrors,
    addDisabled,
    removeDisabled
  } = useContext(settingsContext)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)

  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )

  const fileType = useMemo(
    () =>
      PoliciesHelper.getData(
        {
          attribute: description.attribute.replace('.name', '.type')
        } as DropBoxDescription,
        deviceSettings
      ),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const onChange = (value) => {
    setDisplayError(true)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, value)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  useEffect(() => {
    const fileName = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(fileName) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const emptyPreview = useMemo(() => !getFileNamePreview(value).length, [value])

  const enabled = isEnabled(attribute)
  const showError = displayError || displayAllErrors
  useEffect(() => {
    // Improvement point: show the error in case of prefix/suffix syntax errors
    const err = enabled && emptyPreview
    setError(showError && err)
    err
      ? addError(props.id, attribute, showError)
      : removeError(props.id, attribute)
  }, [enabled, emptyPreview, showError])

  return (
    <FileName
      simple={attribute.startsWith(PolicyAttributeEnum.Email_File)}
      value={value}
      fileType={fileType}
      error={error}
      compliance={compliance}
      attribute={attribute}
      disabled={!enabled}
      onChange={onAttributeChange ? onChange : undefined}
    />
  )
}

export default memo(FileNameControl)
