import React, { useContext, useEffect } from 'react'
import settingsContext from 'context/settings/settingsContext'
import PolicySettingsCard from '.'
import 'styles/global.scss'

const PolicySettingsCardWrapper = (props) => {
  const { getErrors, hasErrors, getWarnings, cleanUp, showErrors } =
    useContext(settingsContext)
  const warningCount = getWarnings()
  const errorStatus = hasErrors()

  useEffect(() => {
    cleanUp(props.attributes.map((x) => x.name))
  }, [props.attributes])

  useEffect(() => {
    showErrors(props.showErrors)
  }, [props.showErrors])

  useEffect(() => {
    if (props.onError) {
      props.onError(errorStatus)
    }
  }, [errorStatus])

  useEffect(() => {
    if (props.onWarning) {
      props.onWarning(warningCount > 0)
    }
  }, [warningCount])

  return <PolicySettingsCard errors={getErrors()} warnings={warningCount} />
}

export default PolicySettingsCardWrapper
