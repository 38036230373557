import { Item } from '../PoliciesConfiguration'

const optimizeImageItem = (x) => {
  return {
    value: 'optimize-text-picture.' + x,
    label: 'policy.settings.optimize-text-picture.' + x
  }
}

export default class PrintOptimizeImageItems {
  static optimizeImageTypes: Item[] = [
    optimizeImageItem('photo'),
    optimizeImageItem('photograph'),
    optimizeImageItem('text'),
    optimizeImageItem('mixed')
  ]
}
