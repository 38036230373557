import React, { useContext, useState, useEffect } from 'react'
import { TreeView, Scrollbar } from '@veneer/core'
import configContext from 'context/config/configContext'
import PoliciesCategories from 'context/policies/PoliciesCategories'
import { FlexColumn } from 'styles/styles'
import 'styles/global.scss'
import './index.scss'

const idAll = 'all'
const categorySeparator = '.'

const PolicyCategories = (props) => {
  const { t } = useContext(configContext)

  const key = (subkey: string): string => {
    return t(`policy.settings.categories.${subkey}`)
  }

  const { attributes, selectedAttributes, onChange } = props
  const [selectedNode, setSelectedNode] = useState(idAll)
  const [categoryNodes, setCategoryNodes] = useState([])

  useEffect(() => {
    const populate = (start, nodes) => {
      return PoliciesCategories.getPolicyCategories(start, attributes).map(
        (category) => {
          const startWith = [...start, category]
          const id = [idAll, ...startWith].join(categorySeparator)
          const count =
            PoliciesCategories.filterPolicyAttributes(
              startWith,
              selectedAttributes ? selectedAttributes : attributes
            ).length || null
          const found = nodes.find((node) => node.id === id)
          if (found) {
            found.nodes = populate(startWith, found.nodes)
            found.totalChildren = count
            return found
          }
          return {
            id,
            label: key(category),
            nodes: populate(startWith, found ? found.nodes : []),
            totalChildren: count
          }
        }
      )
    }
    const newNodes = populate([], categoryNodes)
    setCategoryNodes(newNodes)
    checkSelectedNode(newNodes)
  }, [attributes, selectedAttributes])

  const checkSelectedNode = (nodes) => {
    // change selection if necessary
    if (selectedNode !== idAll) {
      const searchNode = (items) => {
        let bestId = null
        items.forEach((x) => {
          if (selectedNode.startsWith(x.id)) {
            const id = searchNode(x.nodes) || x.id
            if (!bestId || id.length > bestId.length) {
              bestId = id
            }
          }
        })
        return bestId
      }
      const selectedId = searchNode(nodes)
      if (selectedId !== selectedNode) {
        handleChange(selectedId || idAll)
      }
    }
  }

  const handleChange = (id) => {
    setSelectedNode(id)
    onChange(id.split(categorySeparator).slice(1)) // remove 'All'
  }

  return (
    <FlexColumn className={'fullHeight paddingRight24'}>
      <p className={'categoryHeading'}>{key('title')}</p>
      <div className={'categoryTreeColumn'}>
        <Scrollbar>
          <TreeView
            defaultExpandedNodes={[idAll]}
            defaultSelectedNodes={[idAll]}
            selectedNodes={[selectedNode]}
            onChange={(e, id) => handleChange(id)}
            nodes={[
              {
                id: idAll,
                label: key('all'),
                totalChildren:
                  (selectedAttributes ? selectedAttributes : attributes)
                    .length || null,
                nodes: categoryNodes
              }
            ]}
          />
        </Scrollbar>
      </div>
    </FlexColumn>
  )
}
export default PolicyCategories
