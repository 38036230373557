import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import {
  ControlWidthEnum,
  TextBoxDescription
} from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import settingsContext from 'context/settings/settingsContext'
import LabelTextBox from 'common/controls/labelTextBox'
import HelpButton from 'components/policies/settings/attributes/HelpButton'

const TextBoxControl = (props) => {
  const description: TextBoxDescription = props.description
  const {
    id,
    attributes,
    data: { deviceSettings }
  } = props
  const { attribute, hint, label, help, disabledHelp, widthStyle } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [error, setError] = useState(
    PoliciesHelper.getError(description, value)
  )
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const err = enabled ? PoliciesHelper.getError(description, value) : null
    setError(showError ? err : null)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings) || '')
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val === value) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const onChange = (val) => {
    setValue(val)
    setDisplayError(true)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, val)
    props.onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const disHelp = !enabled && !!disabledHelp
  return (
    <LabelTextBox
      label={label ? getLocalized(label) : undefined}
      disabled={!enabled}
      helpButton={
        (description.help || disHelp) && (
          <HelpButton
            enabled={enabled || disHelp}
            description={getLocalized(disHelp ? disabledHelp : help)}
          />
        )
      }
      id={attribute}
      placeholder={getLocalized(hint)}
      helperText={getLocalized(
        error?.message || description.helper || hint,
        error?.params
      )}
      value={value}
      error={!!error}
      onChange={onChange}
      className={widthStyle || ControlWidthEnum.NORMAL}
      textArea={description.textArea}
    />
  )
}

export default memo(TextBoxControl)
