import { Item } from '../PoliciesConfiguration'

export default class TimeItems {
  static hours: Item[] = Array.from(Array(12)).map((_, i) => {
    return { value: `${i + 1}` }
  })

  static minutesBy10: Item[] = [
    { value: '0', label: '00' },
    { value: '10' },
    { value: '20' },
    { value: '30' },
    { value: '40' },
    { value: '50' }
  ]

  static amPms: Item[] = [
    { value: 'am', label: 'policy.settings.time.am' },
    { value: 'pm', label: 'policy.settings.time.pm' }
  ]
}
