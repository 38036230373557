import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import LabelTextBox from 'common/controls/labelTextBox'
const HomeScreenAppsFolderNameModal = (props) => {
  const { value, getLocalized, onClose, onChange } = props
  const [name, setName] = useState(value)

  useEffect(() => {
    setName(value)
  }, [value])

  return (
    <Modal
      className={'small-policy-modal'}
      title={getLocalized(value ? 'menu-rename' : 'menu-to-new-folder')}
      data-testid={'id-folder-name-modal'}
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      footer={
        <ButtonGroup>
          <Button
            id={'id-folder-name-modal-ok'}
            disabled={!name || name === value}
            onClick={() => {
              onChange(name)
              onClose()
            }}
          >
            {getLocalized(value ? 'common.rename' : 'common.add')}
          </Button>
          <Button
            id={'id-folder-name-modal-cancel'}
            appearance={'secondary'}
            onClick={onClose}
          >
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <LabelTextBox
        label={getLocalized('folder-name')}
        value={name || ''}
        placeholder={getLocalized('folder-name_hint')}
        id={'id-folder-name-modal-edit'}
        helperText={getLocalized('folder-name_hint')}
        className={'maxTextWidth'}
        onChange={(val) => setName(val)}
      />
    </Modal>
  )
}

export default HomeScreenAppsFolderNameModal
