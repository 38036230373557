import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PolicyTable from 'components/policies/table'
import PolicyPreview from 'components/policies/preview'
import PolicyDetails from 'components/policies/details'
import Error from 'components/policies/modal/error'
import { getDisplayToast } from 'common/utilities'

const RoutePoliciesTab = (props) => {
  const { navigation, useToast } = props
  const baseurl =
    props.customRelativePath !== undefined
      ? props.customRelativePath
      : '/policies'
  const baseName = navigation
    ? navigation.createHref({ pathname: baseurl })
    : baseurl
  const displayToast = getDisplayToast(useToast)
  const policyProps = { ...props, baseurl, displayToast }

  return (
    <Router basename={baseName}>
      <Switch>
        <Route exact path={'/'}>
          <PolicyTable {...policyProps} />
        </Route>
        <Route path={'/edit/:policyId'}>
          <PolicyDetails {...policyProps} />
        </Route>
        <Route path={'/:policyId'}>
          <PolicyPreview {...policyProps} />
        </Route>
      </Switch>
      <Error />
    </Router>
  )
}

export default RoutePoliciesTab
