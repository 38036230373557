import React, { useContext, useEffect, useState } from 'react'
import PoliciesConfiguration from 'context/policies/PoliciesConfiguration'
import { Card, IconWarningAlt } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import uuid from 'react-uuid'

const Step2RelatedItemsCard = (props) => {
  const { attributes, selectedAttributes, setHelpText } = props
  const { t } = useContext(configContext)
  const key = (subkey: string): string => {
    return t(`policy.create.step2.${subkey}`)
  }
  const [relatedItem, setRelatedItem] = useState([])

  useEffect(() => {
    let itemDesc = []
    selectedAttributes.forEach((item) => {
      const temp = PoliciesConfiguration.getPolicyAttributeDescription(
        item.name
      )
      if (temp.relatedItems) {
        itemDesc = Array.from(
          new Set([
            ...itemDesc,
            ...temp.relatedItems.filter((x) =>
              attributes.find((y) => y.name === x)
            )
          ])
        )
      }
    })

    setRelatedItem(itemDesc)
  }, [attributes, selectedAttributes])

  const checkRelatedItems = (item) => {
    let isPresent = true
    selectedAttributes.forEach((element) => {
      if (element.name === item) {
        isPresent = false
      }
    })
    return isPresent
  }

  const warningContainer = () => (
    <div className="textAlign marginBottom16">
      <div className="circleDiv">
        <IconWarningAlt className="circle" size={36} />
      </div>
      <p className="relatedItem">{key('noItemFound')}</p>
    </div>
  )

  const cardContent = (relatedItems) => {
    const visableItems = []
    return (
      <div className="cardTitle">
        <FlexRow className={'alignCenter marginBottom16'}>
          <p className="relatedItem">{key('relatedItem')}</p>
          <FlexRow className={'paddingLeft4'}>
            <HelpButton
              enabled={true}
              description={key('toolTip')}
              placement="top-end"
              portal={true}
            />
          </FlexRow>
        </FlexRow>
        {relatedItem.length > 0 &&
          relatedItems?.map((item) => {
            if (checkRelatedItems(item)) {
              visableItems.push(item)
              const path = PoliciesConfiguration.getPolicyAttrLocPath(item)
              const attributeName = t(`${path}.name`)
              const relatedItemId = item
              return (
                <div key={uuid()}>
                  <a
                    onClick={() =>
                      setHelpText({
                        name: attributeName,
                        description: t(`${path}.description`),
                        footer: true,
                        relatedItemId: relatedItemId
                      })
                    }
                  >
                    <p className="relatedItem marginBottom8">{attributeName}</p>
                  </a>
                </div>
              )
            }
          })}
        {(relatedItem.length === 0 || visableItems.length === 0) &&
          warningContainer()}
      </div>
    )
  }
  return (
    <Card
      content={cardContent(relatedItem)}
      customStyle={{ border: '1px solid rgba(33, 33, 33, 0.05)' }}
    />
  )
}
export default Step2RelatedItemsCard
