import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'

export default class FaxReceiveItems {
  static faxReceiveOwners: Item[] = [
    {
      value: `${PolicyAttributeEnum.Fax_Receive}.owner.guest`,
      label: 'owner-guest'
    },
    {
      value: `${PolicyAttributeEnum.Fax_Receive}.owner.admin`,
      label: 'owner-admin'
    }
  ]

  static faxModemRingerVolume: Item[] = [
    {
      value: `${PolicyAttributeEnum.Fax_Receive}.settings.modem-ringer-volume.off`,
      label: 'modem-ringer-volume-off'
    },
    {
      value: `${PolicyAttributeEnum.Fax_Receive}.settings.modem-ringer-volume.high`,
      label: 'modem-ringer-volume-high'
    },
    {
      value: `${PolicyAttributeEnum.Fax_Receive}.settings.modem-ringer-volume.low`,
      label: 'modem-ringer-volume-low'
    }
  ]
}
