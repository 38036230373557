import React from 'react'
import {
  IconArrowUp,
  IconArrowUpLine,
  IconArrowDown,
  IconArrowDownLine,
  IconExportFromFolder,
  IconImportToFolder,
  IconFolderAdd,
  IconPencil,
  IconTrash,
  IconBookPagesDouble
} from '@veneer/core'

export enum HomeScreenAppsEnum {
  ID = 'id',
  NAME = 'name',
  TYPE = 'type',
  VISIBLE = 'visible',
  APPS = 'apps'
}

export enum LauncherTypeEnum {
  APP = 'app',
  FOLDER = 'folder'
}

export enum MenuActionEnum {
  TOP,
  UP,
  DOWN,
  BOTTOM,
  TO_FOLDER,
  TO_NEW_FOLDER,
  FROM_FOLDER,
  CHANGE_PAGE,
  RENAME_FOLDER,
  REMOVE_FOLDER
}

export default class HomeScreenAppsMenu {
  static menuOptions = (
    getLocalized,
    pos,
    isApp,
    isSystem,
    inFolder,
    simple
  ) => {
    const items = []
    items.push({
      value: MenuActionEnum.TOP,
      label: getLocalized('menu-top'),
      disabled: !pos.index,
      icon: <IconArrowUpLine />
    })
    items.push({
      value: MenuActionEnum.UP,
      label: getLocalized('menu-up'),
      disabled: !pos.index,
      icon: <IconArrowUp />
    })
    items.push({
      value: MenuActionEnum.DOWN,
      label: getLocalized('menu-down'),
      disabled: pos.index + 1 === pos.length,
      icon: <IconArrowDown />
    })
    items.push({
      value: MenuActionEnum.BOTTOM,
      label: getLocalized('menu-bottom'),
      disabled: pos.index + 1 === pos.length,
      icon: <IconArrowDownLine />
    })

    if (isApp) {
      items.push({
        value: MenuActionEnum.FROM_FOLDER,
        label: getLocalized('menu-from-folder'),
        disabled: !inFolder,
        icon: <IconExportFromFolder />
      })
      items.push({
        value: MenuActionEnum.TO_FOLDER,
        label: getLocalized('menu-to-folder'),
        icon: <IconImportToFolder />
      })
      if (!simple) {
        items.push({
          value: MenuActionEnum.TO_NEW_FOLDER,
          label: getLocalized('menu-to-new-folder'),
          disabled: inFolder,
          icon: <IconFolderAdd />
        })
      }
    }
    if (!simple) {
      items.push({
        value: MenuActionEnum.CHANGE_PAGE,
        label: getLocalized('menu-change-page'),
        icon: <IconBookPagesDouble />
      })
      if (!(isApp || isSystem)) {
        items.push({
          value: MenuActionEnum.RENAME_FOLDER,
          label: getLocalized('menu-rename'),
          icon: <IconPencil />
        })
        items.push({
          value: MenuActionEnum.REMOVE_FOLDER,
          label: getLocalized('menu-remove'),
          icon: <IconTrash />
        })
      }
    }
    return items
  }

  static onMenu = (
    item,
    action,
    opts: { findFolder?; folderName?; folder?; pageNumber? }
  ): boolean => {
    if (!item) {
      return false
    }
    const removeThisItem = () => {
      item.array.splice(item.index, 1)
      if (!item.array.length) {
        // remove empty page
        item.pages.splice(item.pageIndex, 1)
      }
    }
    switch (action) {
      case MenuActionEnum.TOP:
        // move app to the top of the page
        if (item.index) {
          const tmp = item.array[item.index]
          for (let i = item.index; i > 0; i--) {
            item.array[i] = item.array[i - 1]
          }
          item.array[0] = tmp
          return true
        }
        break

      case MenuActionEnum.UP:
        // swap with previous item in the same page
        if (item.index) {
          const tmp = item.array[item.index]
          item.array[item.index] = item.array[item.index - 1]
          item.array[item.index - 1] = tmp
          return true
        }
        break

      case MenuActionEnum.DOWN:
        // swap with next item in the same page
        if (item.index < item.array.length - 1) {
          const tmp = item.array[item.index]
          item.array[item.index] = item.array[item.index + 1]
          item.array[item.index + 1] = tmp
          return true
        }
        break

      case MenuActionEnum.BOTTOM:
        // move app to the bottom of the page
        if (item.index < item.array.length - 1) {
          const tmp = item.array[item.index]
          for (let i = item.index; i < item.array.length - 1; i++) {
            item.array[i] = item.array[i + 1]
          }
          item.array[item.array.length - 1] = tmp
          return true
        }
        break

      case MenuActionEnum.FROM_FOLDER:
        // move app from folder to the page
        if (
          opts.findFolder(item.pages, (page, index) =>
            page.splice(index, 0, item.array[item.index])
          )
        ) {
          removeThisItem()
          return true
        }
        break

      case MenuActionEnum.TO_FOLDER: {
        // move app to folder
        const app = item.array[item.index]
        const apps = opts.folder[HomeScreenAppsEnum.APPS]
        // move app to last page and last position in folder
        apps.length ? apps[apps.length - 1].push(app) : apps.push([app])
        removeThisItem()
        return true
      }

      case MenuActionEnum.TO_NEW_FOLDER: {
        // move app to new folder
        const app = { ...item.array[item.index] }
        item.array[item.index][HomeScreenAppsEnum.ID] = null
        item.array[item.index][HomeScreenAppsEnum.NAME] = opts.folderName
        item.array[item.index][HomeScreenAppsEnum.TYPE] =
          LauncherTypeEnum.FOLDER
        item.array[item.index][HomeScreenAppsEnum.APPS] = [[app]]
        return true
      }

      case MenuActionEnum.RENAME_FOLDER:
        // rename folder
        item.array[item.index][HomeScreenAppsEnum.NAME] = opts.folderName
        return true

      case MenuActionEnum.REMOVE_FOLDER: {
        // delete folder and move apps to the page
        const apps = item.array[item.index][HomeScreenAppsEnum.APPS].reduce(
          (acc, x) => [...acc, ...x],
          []
        )
        item.array.splice(item.index, 1, ...apps)
        return true
      }

      case MenuActionEnum.CHANGE_PAGE:
        // move app to another page
        if (opts.pageNumber > item.pages.length) {
          item.pages.push([item.array[item.index]])
        } else if (opts.pageNumber > 0) {
          item.pages[opts.pageNumber - 1].push(item.array[item.index])
        } else {
          item.pages.splice(0, 0, [item.array[item.index]])
          item.pageIndex++ // new page was inserted before current page
        }
        removeThisItem()
        return true
    }
    return false
  }
}
