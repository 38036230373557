import React from 'react'
import PoliciesConfiguration, {
  PolicyAttributeDescription,
  PolicyItemPrimitives
} from './PoliciesConfiguration'
import { Attribute } from 'common/model/api/Policy'
import PasswordControl from 'components/policies/settings/attributes/device/controls/PasswordControl'
import PasswordViewControl from 'components/policies/settings/attributes/device/controls/PasswordViewControl'
import TextBoxControl from 'components/policies/settings/attributes/device/controls/TextBoxControl'
import ToggleControl from 'components/policies/settings/attributes/device/controls/ToggleControl'
import ToggleViewControl from 'components/policies/settings/attributes/device/controls/ToggleViewControl'
import DropBoxControl from 'components/policies/settings/attributes/device/controls/DropBoxControl'
import PriorityListControl from 'components/policies/settings/attributes/device/controls/PriorityListControl'
import MultiSelectCheckbox from 'components/policies/settings/attributes/device/controls/MultiSelectCheckbox/MultiSelectCheckboxControl'
import DelayVeryLowMessageControl from 'components/policies/settings/attributes/device/controls/DelayVeryLowMessage/DelayVeryLowMessageControl'
import FwUpdateVersionControl from 'components/policies/settings/attributes/device/controls/FwUpdateVersion/FwUpdateVersionControl'
import FwUpdateScheduleControl from 'components/policies/settings/attributes/device/controls/FwUpdateScheduleControl'
import TrayAdministrationControl from 'components/policies/settings/attributes/device/controls/TrayAdministration/TrayAdministrationControl'
import LanguageAndKeyboardLayoutControl from 'components/policies/settings/attributes/device/controls/HomeScreenLanguage/LanguageAndKeyboardLayoutControl'
import OutgoingServersControl from 'components/policies/settings/attributes/device/controls/OutgoingServers/OutgoingServersControl'
import CopyStampsControl from 'components/policies/settings/attributes/device/controls/CopyStamps/CopyStampsControl'
import EmailMessageControl from 'components/policies/settings/attributes/device/controls/EmailMessage/EmailMessageControl'
import FaxReceiveControl from 'components/policies/settings/attributes/device/controls/FaxReceive/FaxReceiveControl'
import FaxSendControl from 'components/policies/settings/attributes/device/controls/FaxSend/FaxSendControl'
import SimpleTableControl from 'components/policies/settings/attributes/device/controls/SimpleTable/SimpleTableControl'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import HomeScreenLanguageItems from './dropboxItems/HomeScreenLanguageItems'
import RestrictColorControl from 'components/policies/settings/attributes/device/controls/RestrictColor/RestrictColorControl'
import FileNameControl from 'components/policies/settings/attributes/device/controls/FileName/FileNameControl'
import RadioTextBoxControl from 'components/policies/settings/attributes/device/controls/RadioTextBoxControl'
import ObjectDropboxControl from 'components/policies/settings/attributes/device/controls/ObjectDropboxControl'
import AppDeploymentControl from 'components/policies/settings/attributes/device/controls/AppDeployment/AppDeploymentControl'
import SioConfigControl from 'components/policies/settings/attributes/device/controls/SioConfig/SioConfigControl'
import FileUploadControl from 'components/policies/settings/attributes/device/controls/FileUploadControl'
import WallpaperUploadControl from 'components/policies/settings/attributes/device/controls/WallpaperUploadControl'
import HomeScreenAppsControl from 'components/policies/settings/attributes/device/controls/HomeScreenApps/HomeScreenAppsControl'
import WeeklyScheduleControl from 'components/policies/settings/attributes/device/controls/WeeklySchedule/WeeklyScheduleControl'
import HolidayScheduleControl from 'components/policies/settings/attributes/device/controls/HolidaySchedule/HolidayScheduleControl'
import QuickSetsControl from 'components/policies/settings/attributes/device/controls/QuickSets/QuickSetsControl'
import ErrorControl from 'components/policies/settings/attributes/device/controls/ErrorControl'

interface DeviceSettingForm {
  form: JSX.Element
  key: number
  className?: string
}

export default class PoliciesGenerator {
  static generateDeviceSettings(
    data: Attribute,
    attributeDescription: PolicyAttributeDescription,
    attributes: Attribute[],
    onAttributeChange,
    onSettingsChanges
  ): DeviceSettingForm[] {
    const { id, deviceSettings } = attributeDescription
    if (!deviceSettings || !id) {
      return []
    }

    const deviceSettingsForm: DeviceSettingForm[] = []
    const localizationPath =
      PoliciesConfiguration.getPolicyAttrLocPath(id) + '.device_settings'

    const className = (indent, nonFirst) => {
      const classes = []
      if (nonFirst) {
        classes.push('paddingTop16')
      }
      if (indent) {
        classes.push(`devSettingsIndent${indent}`)
      }
      return classes.join(' ')
    }

    for (const [key, description] of deviceSettings.entries()) {
      let form: JSX.Element
      switch (description.type) {
        case PolicyItemPrimitives.Password:
          form = (
            <PasswordControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.PasswordView:
          form = (
            <PasswordViewControl
              localizationPath={localizationPath}
              description={description}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.Textbox:
          form = (
            <TextBoxControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.Toggle:
          form = (
            <ToggleControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.ToggleView:
          form = (
            <ToggleViewControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
            />
          )
          break
        case PolicyItemPrimitives.Dropbox:
          form = (
            <DropBoxControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.MultiSelectCheckbox:
          form = (
            <MultiSelectCheckbox
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.DelayLowMsgThreshold:
          form = (
            <DelayVeryLowMessageControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break
        case PolicyItemPrimitives.FwUpdateVersion:
          form = (
            <FwUpdateVersionControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.FwUpdateSchedule:
          form = (
            <FwUpdateScheduleControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.TrayAdministration:
          form = (
            <TrayAdministrationControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.HomeScreenLanguageAndKeyboard:
          form = (
            <LanguageAndKeyboardLayoutControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
              parentToggleValue={false}
            />
          )
          break

        case PolicyItemPrimitives.PriorityList:
          form = (
            <PriorityListControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.OutgoingServers:
          form = (
            <OutgoingServersControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.RestrictColor:
          form = (
            <RestrictColorControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.CopyStamps:
          form = (
            <CopyStampsControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.EmailMessage:
          form = (
            <EmailMessageControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.FaxReceive:
          form = (
            <FaxReceiveControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.FaxSend:
          form = (
            <FaxSendControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.SimpleTable:
          form = (
            <SimpleTableControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.FileName:
          form = (
            <FileNameControl
              id={id}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.RadioTextBox:
          form = (
            <RadioTextBoxControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.ObjectDropBox:
          form = (
            <ObjectDropboxControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.AppDeployment:
          form = (
            <AppDeploymentControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.SioConfig:
          form = (
            <SioConfigControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.FileUpload:
          form = (
            <FileUploadControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.WallpaperUpload:
          form = (
            <WallpaperUploadControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.HomeScreenApplications:
          form = (
            <HomeScreenAppsControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.WeeklySchedule:
          form = (
            <WeeklyScheduleControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.HolidaySchedule:
          form = (
            <HolidayScheduleControl
              id={id}
              description={description}
              localizationPath={localizationPath}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.QuickSets:
          form = (
            <QuickSetsControl
              id={id}
              localizationPath={localizationPath}
              description={description}
              data={data}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.Error:
          form = (
            <ErrorControl
              id={id}
              localizationPath={localizationPath}
              description={description}
            />
          )
          break
      }
      deviceSettingsForm.push({
        form,
        key,
        className: className(description.indent, key > 0)
      })
    }
    return deviceSettingsForm
  }

  static generateDeviceSettingsPreview(
    data: Attribute,
    attributeDescription: PolicyAttributeDescription
  ): DeviceSettingForm[] {
    const { deviceSettings, id } = attributeDescription
    if (!deviceSettings || !id) {
      return []
    }

    const deviceSettingsForm: DeviceSettingForm[] = []
    const localizationPath =
      PoliciesConfiguration.getPolicyAttrLocPath(id) + '.device_settings'
    const getKey = (key) => {
      return !key
        ? undefined
        : key.includes('.')
        ? key
        : `${localizationPath}.${key}`
    }

    for (const [index, description] of deviceSettings.entries()) {
      const key = index * 10
      let value = undefined
      let keys = undefined

      const className = !key ? '' : 'paddingTop16'

      // Optional compliance
      const setting = data.deviceSettings.find(
        (x) => x.name === description.attribute
      )
      let compliance = setting ? setting.compliance : undefined

      switch (description.type) {
        case PolicyItemPrimitives.Password:
        case PolicyItemPrimitives.PasswordView:
          value = PoliciesHelper.getPassword()
          break

        case PolicyItemPrimitives.Textbox:
          value = PoliciesHelper.getData(description, data.deviceSettings)
          break
        case PolicyItemPrimitives.Toggle:
        case PolicyItemPrimitives.ToggleView:
          keys = [
            PoliciesHelper.getData(description, data.deviceSettings)
              ? 'common.on'
              : 'common.off'
          ]
          break

        case PolicyItemPrimitives.Dropbox: {
          value = PoliciesHelper.getData(description, data.deviceSettings)
          const item = description.items.find((item) => item.value === value)
          if (item?.label) {
            value = undefined
            keys = [getKey(item.label)]
          }
          break
        }
        case PolicyItemPrimitives.ObjectDropBox: {
          value = PoliciesHelper.getData(description, data.deviceSettings).value
          compliance = PoliciesHelper.getCompliance(
            description,
            data.deviceSettings
          ).value
          const item = description.items.find((item) => item.value === value)
          if (item) {
            value = undefined
            keys = [getKey(item.label)]
          }
          break
        }

        case PolicyItemPrimitives.DelayLowMsgThreshold: {
          deviceSettingsForm.push({
            form: (
              <DelayVeryLowMessageControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue
        }

        case PolicyItemPrimitives.TrayAdministration:
          deviceSettingsForm.push({
            form: (
              <TrayAdministrationControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HomeScreenLanguageAndKeyboard: {
          const valueArr = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          value = getKey('kb-layout-preview-entry')
          keys = []
          valueArr.forEach((item: { language; layout }) => {
            const language = description.items.find(
              (x) => x.value === item.language
            )
            const layout = HomeScreenLanguageItems.keyboardLayouts.find(
              (x) => x.value === item.layout
            )
            if (language && layout) {
              keys.push([language.label, layout.label].map((x) => getKey(x)))
            }
          })
          break
        }

        case PolicyItemPrimitives.PriorityList: {
          const valueArr: string[] = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          value = getKey('preview-entry')
          keys = []
          valueArr.forEach((item) => {
            const found = description.items.find((x) => x.value === item)
            if (found) {
              keys.push([getKey(found.label)])
            }
          })
          break
        }

        case PolicyItemPrimitives.MultiSelectCheckbox: {
          const valueArr: string[] = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          keys = description.items
            .filter(({ value }) => valueArr.includes(value))
            .map(({ label }) => getKey(label))
          break
        }

        case PolicyItemPrimitives.OutgoingServers:
          deviceSettingsForm.push({
            form: (
              <OutgoingServersControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.RestrictColor:
          deviceSettingsForm.push({
            form: (
              <RestrictColorControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.CopyStamps:
          deviceSettingsForm.push({
            form: (
              <CopyStampsControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.EmailMessage:
          deviceSettingsForm.push({
            form: (
              <EmailMessageControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FaxReceive:
          deviceSettingsForm.push({
            form: (
              <FaxReceiveControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FaxSend:
          deviceSettingsForm.push({
            form: (
              <FaxSendControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.SimpleTable: {
          deviceSettingsForm.push({
            form: (
              <SimpleTableControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue
        }

        case PolicyItemPrimitives.FwUpdateVersion:
          deviceSettingsForm.push({
            form: (
              <FwUpdateVersionControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FwUpdateSchedule:
          deviceSettingsForm.push({
            form: (
              <FwUpdateScheduleControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FileName:
          deviceSettingsForm.push({
            form: (
              <FileNameControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.RadioTextBox:
          deviceSettingsForm.push({
            form: (
              <RadioTextBoxControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.AppDeployment:
          deviceSettingsForm.push({
            form: (
              <AppDeploymentControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.SioConfig:
          deviceSettingsForm.push({
            form: (
              <SioConfigControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FileUpload:
          deviceSettingsForm.push({
            form: (
              <FileUploadControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.WallpaperUpload:
          deviceSettingsForm.push({
            form: (
              <WallpaperUploadControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HomeScreenApplications:
          deviceSettingsForm.push({
            form: (
              <HomeScreenAppsControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.WeeklySchedule:
          deviceSettingsForm.push({
            form: (
              <WeeklyScheduleControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HolidaySchedule:
          deviceSettingsForm.push({
            form: (
              <HolidayScheduleControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.QuickSets:
          deviceSettingsForm.push({
            form: (
              <QuickSetsControl
                id={id}
                localizationPath={localizationPath}
                description={description}
                data={data}
                compliance={compliance}
              />
            ),
            key,
            className
          })
          continue
      }

      deviceSettingsForm.push({
        form: (
          <PreviewItem
            label={getKey(description['label'])}
            value={value}
            keys={keys}
            compliance={compliance}
            className={className}
          />
        ),
        key
      })
    }
    return deviceSettingsForm
  }
}
