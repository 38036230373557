import React from 'react'
import AppDeploymentModal from '../settings/attributes/device/controls/AppDeployment/AppDeploymentModal'

const AppConfigModal = (props) => {
  const { appId, mocId, header, ...modalProps } = props

  return (
    <AppDeploymentModal
      localizationPath={
        'policy.settings.attributes.app-deployment.device_settings'
      }
      item={{ appId, mocId }}
      tableHeader={header}
      {...modalProps}
    />
  )
}

export default AppConfigModal
