const launcherName = (x) => 'policy.settings.system-launchers.' + x

export default class SystemLaunchers {
  static basic = {
    '2b328d57-7fff-401c-9665-315ada3010f0': launcherName('contacts'),
    'cedab422-33b3-4638-b6a1-604e54525215': launcherName('copy'),
    'c74293eb-04c1-4dff-b469-1c0e99fdbe8b': launcherName('id-copy'),
    '697749ba-b6ea-11eb-80d0-9ffe5c5f1620': launcherName('info'),
    '8be4736f-26ab-459f-a415-b85dc8b157d3': launcherName('jobs'),
    '1f91f218-ca35-4554-a2f3-16b0b28fea31': launcherName('help'),
    '9da37e46-9b8a-4dc2-a24c-017fee6b088f': launcherName('maintenance'),
    'e1ab8582-666d-442f-b620-0b5f58a7abee': launcherName('personal-shortcuts'),
    'e2e770be-1f2b-4fa9-a072-c3565da21ed9': launcherName('quick-set'),
    '34876b06-05be-4044-b61c-40cca9dfe4cb': launcherName('reports'),
    '44aa632b-cfa3-4c10-8cab-697a9bef610b': launcherName('send-fax'),
    '0e49b040-ed7c-4b11-8dd2-f8acc500760a': launcherName('service'),
    '3dfe6950-5cf9-41c2-a3b2-6154868ab45d': launcherName('settings'),
    '3e7cc42a-d7ff-11eb-9ddc-d3f9425e6ff9': launcherName('substrate-library'),
    '32fd7d01-7447-4da8-9de5-0bc6eb97f22e': launcherName('supplies'),
    '12fca0ec-1128-4434-aa4a-68e1ca8c4f42': launcherName(
      'tray-paper-substrate'
    ),
    'f6d66534-9b96-4f12-9f51-cea0ab19dc79': launcherName('troubleshooting')
  }
  static print = {
    '7b7efcc4-34ad-11ed-a261-0242ac120002': launcherName(
      'print-from-nw-folder'
    ),
    'dec7d1f3-81ea-460b-8a00-d2729786ec2f': launcherName('print-from-storage'),
    'c93bc831-99a8-454c-b508-236fc3a2a08f': launcherName('print-from-usb')
  }
  static scan = {
    '10c9c25c-7b7b-4f7d-b4ad-dd9975be35c7': launcherName('scan-to-computer'),
    '2cd169f1-ad17-4cc1-864e-8f4d5f9ffd10': launcherName('scan-to-cloud'),
    '83969b5e-f2ac-4f8c-a201-a18f02141136': launcherName('scan-to-email-cloud'),
    'b8460c9e-43c8-4290-a0f8-8ce450867f09': launcherName(
      'scan-to-email-native'
    ),
    '65acca51-619d-4e29-b1d0-6414e52f908b': launcherName('scan-to-nw-folder'),
    'a3d696df-b7ff-4d3d-9969-5cd7f18c0c92': launcherName('scan-to-sharepoint'),
    'df4a8a01-7659-486f-95d5-e125ccd1529a': launcherName('scan-to-usb')
  }

  static launcherKeys = {
    ...SystemLaunchers.basic,
    ...SystemLaunchers.print,
    ...SystemLaunchers.scan
  }
}
