import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Checkbox, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { TextBlack } from 'styles/styles'
import sioConfigItems from './SioConfigItems'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import { getError, isTrueString } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'

export enum SioConfigEnum {
  PROVIDER = 'provider',
  OPTIONS = 'options',
  TENANT = 'tenant-id',
  DOMAIN = 'domain'
}

const ChangeSIOConfigModal = (props) => {
  const { tt } = useContext(configContext)
  const [disableSave, setDisableSave] = useState(true)
  const [value, setValue] = useState(props.value)
  const [domainError, setDomainError] = useState(null)
  const [tenantIdError, setTenantIdError] = useState(null)
  const saveData = () => {
    const domainError = getEmptyFieldError(value[SioConfigEnum.DOMAIN])
    const tenantIdError = getEmptyFieldError(value[SioConfigEnum.TENANT])
    if (
      (value[SioConfigEnum.PROVIDER] === sioConfigItems.sioProviders[0].value &&
        isTrueString(value[SioConfigEnum.OPTIONS])) ||
      value[SioConfigEnum.PROVIDER] === sioConfigItems.sioProviders[1].value
    ) {
      if (domainError?.message) {
        setDomainError(domainError)
        return
      }
    } else if (
      value[SioConfigEnum.PROVIDER] === sioConfigItems.sioProviders[2].value
    )
      if (tenantIdError?.message) {
        setTenantIdError(tenantIdError)
        return
      }
    props.onChange(value)
    props.onClose()
  }

  const sioProviderOptions = () => {
    return sioConfigItems.sioProviders.map((v) => {
      return {
        value: v.value,
        label: getLocalized(`${v.label}`)
      }
    })
  }

  useEffect(() => {
    setDisableSave(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const getEmptyFieldError = (key) =>
    getError(PoliciesErrors.errorsNotSelected, key)

  const TextBoxWithLabel = (id, error, setError, disabled = false) => {
    disabled =
      value[SioConfigEnum.PROVIDER] === sioConfigItems.sioProviders[0].value
        ? disabled
        : false
    return (
      <LabelTextBox
        label={getLocalized(id)}
        id={id}
        value={value[id]}
        onChange={(v) => {
          setValue({ ...value, [id]: v })
          setError(getEmptyFieldError(v))
        }}
        className={'maxTextWidth'}
        error={!!error?.message}
        helperText={
          error?.message
            ? getLocalized(error?.message || `${id}_hint`, error?.params)
            : null
        }
        placeholder={getLocalized(id + '-placeholder')}
        disabled={disabled}
      />
    )
  }

  const showFields = useMemo(() => {
    switch (value[SioConfigEnum.PROVIDER]) {
      case sioConfigItems.sioProviders[0].value: //Hp
        return (
          <>
            <div className={'paddingBottom16'}>
              <Checkbox
                label={getLocalized('specify-options')}
                checked={isTrueString(value[SioConfigEnum.OPTIONS])}
                onChange={({ target }) => {
                  setValue({
                    ...value,
                    [SioConfigEnum.OPTIONS]: target.checked + ''
                  })
                }}
              />
            </div>
            <div className={'devSettingsIndent1'}>
              {TextBoxWithLabel(
                SioConfigEnum.DOMAIN,
                domainError,
                setDomainError,
                !isTrueString(value[SioConfigEnum.OPTIONS])
              )}
            </div>
          </>
        )
      case sioConfigItems.sioProviders[1].value: //Google
        return TextBoxWithLabel(
          SioConfigEnum.DOMAIN,
          domainError,
          setDomainError
        )
      case sioConfigItems.sioProviders[2].value: //Azure
        return TextBoxWithLabel(
          SioConfigEnum.TENANT,
          tenantIdError,
          setTenantIdError
        )
    }
  }, [value, tenantIdError, domainError])

  return (
    <Modal
      onClose={props.onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('change-sio-config')}
      className={'small-policy-modal'}
      data-testid={'id-sio-config-modal'}
      footer={
        <ButtonGroup>
          <Button disabled={disableSave} onClick={saveData}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={props.onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack className={'paddingBottom16'}>
        {getLocalized('change-sio-config-message')}
      </TextBlack>
      <LabelSelect
        label={getLocalized('identity-provider')}
        id={SioConfigEnum.PROVIDER}
        className={'paddingBottom16 maxTextWidth'}
        options={sioProviderOptions()}
        placeholder={getLocalized('common.select-option')}
        onChange={(option) => {
          setValue({ ...value, [SioConfigEnum.PROVIDER]: option.value })
        }}
        value={[value[SioConfigEnum.PROVIDER]]}
      />
      <div className={'devSettingsIndent1'}>{showFields}</div>
    </Modal>
  )
}

export default ChangeSIOConfigModal
