import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button } from '@veneer/core'
import ChangeSIOConfigModal, { SioConfigEnum } from './ChangeSIOConfigModal'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import sioConfigItems from './SioConfigItems'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { FlexRow, TextBlack } from 'styles/styles'
import 'styles/global.scss'

const SioConfigControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [showModal, setShowModal] = useState(false)

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const sio = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(sio) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const onChange = (newValue) => {
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const msProvider = () =>
    value[SioConfigEnum.PROVIDER] ===
    sioConfigItems.sioProviders[sioConfigItems.sioProviders.length - 1].value

  const summaryText = useMemo(() => {
    const provider = sioConfigItems.sioProviders.find(
      (x) => x.value === value[SioConfigEnum.PROVIDER]
    )?.label
    const param =
      value[msProvider() ? SioConfigEnum.TENANT : SioConfigEnum.DOMAIN]
    return provider
      ? getLocalized('sio-summary', { provider: getLocalized(provider), param })
      : ''
  }, [value])

  const getCompliance = () =>
    compliance[SioConfigEnum.PROVIDER] ||
    (msProvider()
      ? compliance[SioConfigEnum.TENANT]
      : compliance[SioConfigEnum.DOMAIN] || compliance[SioConfigEnum.OPTIONS])

  const enabled = isEnabled(attribute)
  return (
    <>
      {!onAttributeChange ? (
        <PreviewItem value={summaryText} compliance={getCompliance()} />
      ) : (
        <FlexRow className={'alignCenter'}>
          <Button
            appearance={'secondary'}
            disabled={!enabled}
            onClick={() => setShowModal(true)}
            id={'editSioConfig'}
          >
            {getLocalized('common.edit')}
          </Button>
          <TextBlack className={'marginLeft12'} disabled={!enabled}>
            {summaryText}
          </TextBlack>
        </FlexRow>
      )}
      {showModal && (
        <ChangeSIOConfigModal
          value={value}
          localizationPath={props.localizationPath}
          onChange={onChange}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default memo(SioConfigControl)
