import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import {
  Table,
  Button,
  Checkbox,
  ContextualMenu,
  IconApplication,
  IconFolder
} from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { HomeScreenAppsDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import HomeScreenAppsMenu, {
  LauncherTypeEnum,
  MenuActionEnum,
  HomeScreenAppsEnum
} from './HomeScreenAppsMenu'
import SystemLaunchers from './SystemLaunchers'
import ReferenceDeviceModal from 'components/policies/modal/ReferenceDevice'
import HomeScreenAppsFolderNameModal from './HomeScreenAppsFolderNameModal'
import HomeScreenAppsFolderSelectModal from './HomeScreenAppsFolderSelectModal'
import HomeScreenAppsFolderDeleteModal from './HomeScreenAppsFolderDeleteModal'
import HomeScreenAppsChangePageModal from './HomeScreenAppsChangePageModal'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import {
  FlexRow,
  FlexColumn,
  TextBlack,
  PreviewLabel,
  FlexRowWithSpace
} from 'styles/styles'
import 'styles/global.scss'

const pageColumn = 'page'

const HomeScreenAppsControl = (props) => {
  const description: HomeScreenAppsDescription = props.description
  const { attribute, simple } = description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange,
    localizationPath
  } = props
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)
  const { tt } = useContext(configContext)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [menuModal, setMenuModal] = useState(null)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const apps = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(apps) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const setData = (newValue) => {
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const getItemName = (item) => {
    const key = SystemLaunchers.launcherKeys[item[HomeScreenAppsEnum.ID]]
    return key ? getLocalized(key) : item[HomeScreenAppsEnum.NAME]
  }

  const findData = (index) =>
    value.reduce(
      (found, page: [], pageIndex) =>
        page.reduce((result, cur, curIndex, curPage) => {
          if (result) {
            return result
          }
          if (!index--) {
            return { index: curIndex, array: curPage, pages: value, pageIndex }
          }
          if (cur[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER) {
            const pages: [] = cur[HomeScreenAppsEnum.APPS]
            return pages.reduce(
              (foundApp, appPage: [], pageIndex) =>
                appPage.reduce((result, app, appIndex, appPage) => {
                  if (result) {
                    return result
                  }
                  if (!index--) {
                    return { index: appIndex, array: appPage, pages, pageIndex }
                  }
                  return undefined
                }, foundApp),
              undefined
            )
          }
          return undefined
        }, found),
      undefined
    )

  const findFolder = (pages, doIt: (page, index) => void) =>
    value.some((page: Record<string, unknown>[]) => {
      const index = page.findIndex(
        (x) =>
          x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER &&
          x[HomeScreenAppsEnum.APPS] == pages
      )
      if (index >= 0) {
        doIt(page, index)
        return true
      }
      return false
    })

  const onCheckAll = (checked) => {
    value.forEach((page: Record<string, unknown>[]) =>
      page.forEach((item) => {
        item[HomeScreenAppsEnum.VISIBLE] = checked
        if (item[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER) {
          const apps = item[HomeScreenAppsEnum.APPS] as []
          apps.forEach((appPage: Record<string, unknown>[]) =>
            appPage.forEach((x) => {
              x[HomeScreenAppsEnum.VISIBLE] = checked
            })
          )
        }
      })
    )
    setData([...value])
  }

  const onCheck = (index, checked) => {
    const found = findData(index)
    if (found) {
      // update app/folder visibility
      const item = found.array[found.index]
      item[HomeScreenAppsEnum.VISIBLE] = checked
      if (item[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER) {
        // if folder is unchecked, all apps inside should be unchecked as well
        if (!checked) {
          item[HomeScreenAppsEnum.APPS].forEach((appPage) =>
            appPage.forEach((x) => {
              x[HomeScreenAppsEnum.VISIBLE] = false
            })
          )
        }
      } else if (value != found.pages && checked) {
        // if app is checked, folder should be checked as well
        findFolder(found.pages, (page, index) => {
          page[index][HomeScreenAppsEnum.VISIBLE] = true
        })
      }

      setData([...value])
    }
  }

  const onMenu = (item, action, opts = {}) => {
    switch (action) {
      case MenuActionEnum.FROM_FOLDER:
        opts = { findFolder }
        break
      case MenuActionEnum.TO_FOLDER:
      case MenuActionEnum.TO_NEW_FOLDER:
      case MenuActionEnum.RENAME_FOLDER:
      case MenuActionEnum.REMOVE_FOLDER:
      case MenuActionEnum.CHANGE_PAGE:
        if (!menuModal) {
          // show modal
          setMenuModal({ item, action })
          return
        }
        break
    }
    if (HomeScreenAppsMenu.onMenu(item, action, opts)) {
      setData([...value])
    }
  }

  const renderMenuModal = () => {
    const { item, action } = menuModal
    switch (action) {
      case MenuActionEnum.TO_FOLDER: {
        const allItems = value.reduce((items, page) => [...items, ...page], [])
        const options = allItems
          .map((x, i) => ({
            label: getItemName(x),
            value:
              x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER &&
              x[HomeScreenAppsEnum.APPS] != item.pages // exclude this folder
                ? i + 1
                : 0
          }))
          .filter((x) => x.value)
        return (
          <HomeScreenAppsFolderSelectModal
            getLocalized={getLocalized}
            options={options}
            onClose={() => setMenuModal(null)}
            onChange={(folderIndex) =>
              onMenu(item, action, { folder: allItems[folderIndex - 1] })
            }
            appName={getItemName(item.array[item.index])}
          />
        )
      }

      case MenuActionEnum.TO_NEW_FOLDER:
      case MenuActionEnum.RENAME_FOLDER:
        return (
          <HomeScreenAppsFolderNameModal
            getLocalized={getLocalized}
            value={
              action === MenuActionEnum.RENAME_FOLDER
                ? item.array[item.index][HomeScreenAppsEnum.NAME]
                : null
            }
            onClose={() => setMenuModal(null)}
            onChange={(folderName) => onMenu(item, action, { folderName })}
          />
        )

      case MenuActionEnum.REMOVE_FOLDER:
        return (
          <HomeScreenAppsFolderDeleteModal
            getLocalized={getLocalized}
            onClose={() => setMenuModal(null)}
            onOk={() => onMenu(item, action)}
            folderName={item.array[item.index][HomeScreenAppsEnum.NAME]}
          />
        )

      case MenuActionEnum.CHANGE_PAGE:
        return (
          <HomeScreenAppsChangePageModal
            getLocalized={getLocalized}
            count={item.pages.length}
            value={item.pageIndex + 1}
            onClose={() => setMenuModal(null)}
            onChange={(pageNumber) => onMenu(item, action, { pageNumber })}
            appName={getItemName(item.array[item.index])}
          />
        )
    }
  }

  const tableItem = (
    index,
    item,
    pos: { index; length },
    page: { index; length },
    count?: number,
    indent?: boolean
  ) => {
    const isApp = item[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.APP
    const isSystem = !item[HomeScreenAppsEnum.NAME]
    const name = getItemName(item)
    const visible = item[HomeScreenAppsEnum.VISIBLE]
    return {
      id: index,
      [HomeScreenAppsEnum.NAME]: (
        <FlexRow
          className={`alignCenter${indent ? ' devSettingsIndent1' : ''}`}
        >
          {isApp ? <IconApplication /> : <IconFolder />}
          <span className={'marginLeft4'}>
            {count ? getLocalized('name-n', { name, count }) : name}
          </span>
        </FlexRow>
      ),
      [HomeScreenAppsEnum.TYPE]: getLocalized(
        isApp ? 'type-app' : isSystem ? 'type-system-folder' : 'type-folder'
      ),
      [pageColumn]: page.index + 1,
      [HomeScreenAppsEnum.VISIBLE]: onAttributeChange ? (
        <Checkbox
          id={index + '-check'}
          label={getLocalized('visible-show')}
          checked={visible}
          onChange={({ target }) => onCheck(index, target.checked)}
        />
      ) : (
        getLocalized(visible ? 'visible-show' : 'visible-hide')
      ),
      rowConfig: {
        [HomeScreenAppsEnum.VISIBLE]: visible,
        action: onAttributeChange && (
          <ContextualMenu
            id={index + '-menu'}
            placement={'top-end'}
            options={HomeScreenAppsMenu.menuOptions(
              getLocalized,
              pos,
              isApp,
              isSystem,
              indent,
              simple
            )}
            onClick={(_, option) => onMenu(findData(index), option.value)}
          />
        )
      }
    }
  }

  useEffect(() => {
    const items = value
      ? value.reduce(
          (items: Record<string, unknown>[], page: [], pageNumber) =>
            page.reduce((acc, cur, curIndex, curPage) => {
              const appPages =
                cur[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER
                  ? cur[HomeScreenAppsEnum.APPS]
                  : null
              acc.push(
                tableItem(
                  acc.length,
                  cur,
                  { index: curIndex, length: curPage.length },
                  { index: pageNumber, length: value.length },
                  appPages?.reduce((a, x) => a + x.length, 0)
                )
              )
              if (appPages) {
                appPages.forEach((appPage, i) =>
                  appPage.forEach((app, j) =>
                    acc.push(
                      tableItem(
                        acc.length,
                        app,
                        { index: j, length: appPage.length },
                        { index: i, length: appPages.length },
                        null,
                        true
                      )
                    )
                  )
                )
              }
              return acc
            }, items),
          []
        )
      : []
    setTableData(items)
    const selected = items.filter(
      (x) => x.rowConfig[HomeScreenAppsEnum.VISIBLE]
    ).length
    setRowSelectAllState(
      !selected ? false : selected === items.length ? true : undefined
    )
  }, [value])

  const tableInfo = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    const columns = [
      { id, label: id, index },
      { id: HomeScreenAppsEnum.NAME, label: getLocalized('name') },
      { id: HomeScreenAppsEnum.TYPE, label: getLocalized('type') },
      { id: pageColumn, label: getLocalized('page') },
      { id: HomeScreenAppsEnum.VISIBLE, label: getLocalized('visible') }
    ]
    const width = [
      { columnId: HomeScreenAppsEnum.NAME, width: 50 },
      { columnId: HomeScreenAppsEnum.TYPE, width: 25 },
      { columnId: pageColumn, width: 10 },
      { columnId: HomeScreenAppsEnum.VISIBLE, width: 15 }
    ]
    return simple
      ? {
          columns: columns.slice(0, 3),
          preferences: { width: [width[0], { ...width[1], width: 50 }] }
        }
      : { columns, preferences: { width } }
  }, [simple])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{getLocalized(text)}</PreviewLabel>
    ) : (
      <FlexRow className={'alignCenter marginBottom16'}>
        <TextBlack disabled={!enabled}>{getLocalized(text)}</TextBlack>
        <FlexRow className={'paddingLeft4'}>
          <HelpButton enabled description={getLocalized(text + '_help')} />
        </FlexRow>
      </FlexRow>
    )

  const i18nItems = Retrievei18nItems()
  const enabled = isEnabled(attribute)

  const onDeviceChange = (selectedDevice) => {
    // To do
    console.log(selectedDevice)
  }
  return (
    <>
      <FlexColumn>
        {tableHeader('home-screen')}
        {onAttributeChange && (
          <FlexRowWithSpace className={'marginBottom12 alignCenter'}>
            <Button
              appearance={'secondary'}
              id={'id-home-screen-apps-device'}
              onClick={() => setShowModal(true)}
            >
              {getLocalized('select-device')}
            </Button>
            {!simple && (
              <Checkbox
                label={getLocalized('visible-all')}
                checked={
                  rowSelectAllState === undefined
                    ? 'indeterminated'
                    : rowSelectAllState
                }
                onChange={({ target }) => onCheckAll(target.checked)}
              />
            )}
          </FlexRowWithSpace>
        )}
        {
          <Table
            columns={tableInfo.columns}
            data={tableData}
            className={'widthColAuto paddingFourthCol4'}
            data-testid={'id-home-screen-apps-table'}
            preferences={tableInfo.preferences}
            i18n={i18nItems}
          />
        }
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal && (
        <ReferenceDeviceModal
          onChange={(selectedDevice) => onDeviceChange(selectedDevice)}
          onClose={() => setShowModal(false)}
        />
      )}
      {menuModal && renderMenuModal()}
    </>
  )
}

export default memo(HomeScreenAppsControl)
