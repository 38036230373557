import React, { useMemo } from 'react'
import moment from 'moment'
import ConfigContext from './configContext'
import AssetsProviderFactory from 'assets/AssetsProviderFactory'

const ConfigProvider = (props) => {
  const { language, country } = props

  const defAssetProvider = useMemo(
    () => AssetsProviderFactory.create('en', 'US'),
    []
  )

  const assetsProvider = useMemo(() => {
    moment.locale((language + '-' + country).toLowerCase())
    return AssetsProviderFactory.create(language, country)
  }, [language, country])

  const t = (subkey: string, args?: Record<string, string>): string => {
    const key = `ecp-policies.${subkey}`
    return (
      assetsProvider.getText(key, args) || defAssetProvider.getText(key, args)
    )
  }

  const tt = (
    path: string,
    key: string,
    args?: Record<string, string>
  ): string => t(key.includes('.') ? key : `${path}.${key}`, args)

  return (
    <ConfigContext.Provider value={{ t, tt, language, country }}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
