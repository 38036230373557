import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import WeeklyScheduleModal, { WeeklyScheduleEnum } from './WeeklyScheduleModal'
import {
  getRowSelectAllState,
  DATE_FORMAT,
  TABLE_CONSTANTS,
  NO_SELECTION,
  WEEK_DAYS,
  formatDate
} from 'common/utilities'
import 'styles/global.scss'

const tablePreferences = {
  width: [
    { columnId: WeeklyScheduleEnum.TYPE, width: 20 },
    { columnId: WeeklyScheduleEnum.DAYS, width: 40 },
    { columnId: WeeklyScheduleEnum.TYPE_SLEEP, width: 20 },
    { columnId: WeeklyScheduleEnum.TYPE_WAKE, width: 20 }
  ]
}

const defaultSchedule = {
  [WeeklyScheduleEnum.TYPE]: WeeklyScheduleEnum.TYPE_SLEEP,
  [WeeklyScheduleEnum.HOUR]: '0',
  [WeeklyScheduleEnum.MINUTE]: '0',
  [WeeklyScheduleEnum.DAYS]: []
}

const WeeklyScheduleControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const [showModal, setShowModal] = useState(false)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [tableData, setTableData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(NO_SELECTION)
  const [selectedCount, setSelectedCount] = useState(0)

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const schedules = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(schedules) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const shiftDays = (days, delta) => {
    if (delta > 1) {
      delta = 6 - 1
    } else if (delta < -1) {
      delta = 6 + 1
    } else {
      delta += 6
    }
    const newDays = days.map((x) => (((+x + delta) % 7) + 1).toString())
    newDays.sort()
    return newDays
  }

  const utcToLocal = (item) => {
    const hh = +item[WeeklyScheduleEnum.HOUR]
    const mm = +item[WeeklyScheduleEnum.MINUTE]
    const date = new Date()
    date.setUTCHours(hh, mm)
    const delta = date.getDate() - date.getUTCDate() || 0
    return {
      [WeeklyScheduleEnum.TYPE]: item[WeeklyScheduleEnum.TYPE],
      [WeeklyScheduleEnum.HOUR]: date.getHours().toString(),
      [WeeklyScheduleEnum.MINUTE]: date.getMinutes().toString(),
      [WeeklyScheduleEnum.DAYS]: shiftDays(item[WeeklyScheduleEnum.DAYS], delta)
    }
  }

  const localToUtc = (item) => {
    const hh = +item[WeeklyScheduleEnum.HOUR]
    const mm = +item[WeeklyScheduleEnum.MINUTE]
    const date = new Date()
    date.setHours(hh, mm)
    const delta = date.getUTCDate() - date.getDate() || 0
    return {
      [WeeklyScheduleEnum.TYPE]: item[WeeklyScheduleEnum.TYPE],
      [WeeklyScheduleEnum.HOUR]: date.getUTCHours().toString(),
      [WeeklyScheduleEnum.MINUTE]: date.getUTCMinutes().toString(),
      [WeeklyScheduleEnum.DAYS]: shiftDays(item[WeeklyScheduleEnum.DAYS], delta)
    }
  }

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onRemove = () => setData(tableData.filter((x) => !x.rowConfig.selected))

  const onAdd = (edit = false) => {
    setSelectedIndex(
      edit ? tableData.findIndex((x) => x.rowConfig.selected) : NO_SELECTION
    )
    setShowModal(true)
  }

  const onChange = (v) => {
    setData(
      selectedIndex === NO_SELECTION
        ? [...tableData, tableItem(v, tableData.length)]
        : [
            ...tableData.slice(0, selectedIndex),
            tableItem(v, selectedIndex),
            ...tableData.slice(selectedIndex + 1)
          ]
    )
  }

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    const local = utcToLocal(item)
    const date = new Date()
    date.setHours(
      +local[WeeklyScheduleEnum.HOUR],
      +local[WeeklyScheduleEnum.MINUTE]
    )
    const type = item[WeeklyScheduleEnum.TYPE]
    const getType = () => {
      switch (type) {
        case WeeklyScheduleEnum.TYPE_SLEEP:
          return getLocalized('event-type-sleep')
        case WeeklyScheduleEnum.TYPE_WAKE:
          return getLocalized('event-type-wake')
        default:
          return TABLE_CONSTANTS.NO_DATA
      }
    }
    const getDays = (days) =>
      days
        .map((x) =>
          getLocalized('policy.settings.week-days-short.' + WEEK_DAYS[+x - 1])
        )
        .join(TABLE_CONSTANTS.JOINER)
    const getTime = (show, date) =>
      show
        ? formatDate(date, DATE_FORMAT.START_END_TIME)
        : TABLE_CONSTANTS.NO_DATA
    return {
      [TABLE_CONSTANTS.ID]: i,
      [WeeklyScheduleEnum.TYPE]: getType(),
      [WeeklyScheduleEnum.DAYS]: getDays(local[WeeklyScheduleEnum.DAYS]),
      [WeeklyScheduleEnum.TYPE_SLEEP]: getTime(
        type === WeeklyScheduleEnum.TYPE_SLEEP,
        date
      ),
      [WeeklyScheduleEnum.TYPE_WAKE]: getTime(
        type === WeeklyScheduleEnum.TYPE_WAKE,
        date
      ),
      rowConfig: { selected, item }
    }
  }

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom16'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      { id: WeeklyScheduleEnum.TYPE, label: getLocalized('event-type') },
      { id: WeeklyScheduleEnum.DAYS, label: getLocalized('day') },
      { id: WeeklyScheduleEnum.TYPE_SLEEP, label: getLocalized('sleep-time') },
      { id: WeeklyScheduleEnum.TYPE_WAKE, label: getLocalized('wake-time') }
    ]
  }, [])

  const enabled = isEnabled(attribute)
  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('weekly-schedule'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => onAdd()}
                id={'addWeeklySchedule'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || selectedCount !== 1}
                onClick={() => onAdd(true)}
                id={'editWeeklySchedule'}
              >
                {getLocalized('common.edit')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !selectedCount}
                onClick={onRemove}
                id={'removeWeeklySchedule'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('n-schedules-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          rowSelectAllState={rowSelectAllState}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          className={'widthColAuto'}
          data-testid={'id-weekly-schedule-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal && (
        <WeeklyScheduleModal
          value={
            selectedIndex !== NO_SELECTION
              ? utcToLocal(tableData[selectedIndex].rowConfig.item)
              : defaultSchedule
          }
          values={tableData
            .filter((_, index) => index !== selectedIndex)
            .map((x) => utcToLocal(x.rowConfig.item))}
          edit={selectedIndex !== NO_SELECTION}
          onChange={(v) => onChange(localToUtc(v))}
          onClose={() => setShowModal(false)}
          getLocalized={getLocalized}
        />
      )}
    </>
  )
}

export default memo(WeeklyScheduleControl)
