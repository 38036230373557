import React, { useContext } from 'react'
import configContext from 'context/config/configContext'
import './index.scss'

const PolicyStatus = (props) => {
  const { t } = useContext(configContext)
  return (
    <>
      <p className={props.active ? 'StatusIcon' : 'StatusIcon disabled'} />
      {props.status ||
        t(`policy.status.${props.active ? 'active' : 'inactive'}`)}
    </>
  )
}

export default PolicyStatus
