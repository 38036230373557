import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const createCipherObj = (x: string, o?: string) => {
  return {
    value: `${PolicyAttributeEnum.Web_Encryption}.ciphers.${x}`,
    label: 'cipher-' + x,
    option: o ?? x.toUpperCase()
  }
}
export default class WebEncryptionItems {
  static webEncryptionStrengths: Item[] = [
    {
      value: `${PolicyAttributeEnum.Web_Encryption}.strength.high`,
      label: 'strength-high'
    },
    {
      value: `${PolicyAttributeEnum.Web_Encryption}.strength.medium`,
      label: 'strength-medium'
    },
    {
      value: `${PolicyAttributeEnum.Web_Encryption}.strength.low`,
      label: 'strength-low'
    }
  ]

  static ciphers: Item[] = [
    createCipherObj('aes128-sha'),
    createCipherObj('aes128-sha256'),
    createCipherObj('aes128-gcm-sha256'),
    createCipherObj('aes256-sha'),
    createCipherObj('aes256-sha256'),
    createCipherObj('aes256-gcm-sha384'),
    createCipherObj('des-cbc3-sha'),
    createCipherObj('rc4-sha'),
    createCipherObj('rc4-md5'),
    createCipherObj('dhe-rsa-aes128-sha'),
    createCipherObj('dhe-rsa-aes128-sha256'),
    createCipherObj('dhe-rsa-aes128-gcm-sha256'),
    createCipherObj('dhe-rsa-aes256-sha'),
    createCipherObj('dhe-rsa-aes256-sha256'),
    createCipherObj('dhe-rsa-aes256-gcm-sha384'),
    createCipherObj('ecdhe-rsa-aes128-sha'),
    createCipherObj('ecdhe-rsa-aes128-sha256'),
    createCipherObj('ecdhe-rsa-aes128-gcm-sha256'),
    createCipherObj('ecdhe-rsa-aes256-sha'),
    createCipherObj('ecdhe-rsa-aes256-sha384'),
    createCipherObj('ecdhe-rsa-aes256-gcm-sha384'),
    createCipherObj('ecdhe-rsa-des-cbc3-sha'),
    createCipherObj('ecdhe-rsa-rc4-sha'),
    createCipherObj('ecdhe-ecdsa-aes128-gcm-sha256'),
    createCipherObj('ecdhe-ecdsa-aes256-gcm-sha384'),
    createCipherObj('ecdhe-ecdsa-aes128-sha'),
    createCipherObj('ecdhe-ecdsa-aes256-sha'),
    createCipherObj('ecdhe-ecdsa-aes256-sha384'),
    createCipherObj('ecdhe-ecdsa-aes128-sha256'),
    createCipherObj('tls-aes-128-ccm-sha256', 'TLS_AES_128_CCM_SHA256'),
    createCipherObj('tls-aes-128-gcm-sha256', 'TLS_AES_128_GCM_SHA256'),
    createCipherObj('tls-aes-256-gcm-sha384', 'TLS_AES_256_GCM_SHA384')
  ]

  static tlsVersions: Item[] = Array.from(Array(4)).map((_, i) => ({
    value: `${PolicyAttributeEnum.Web_Encryption}.tls-version.1-${i}`,
    label: 'tls-version_1-' + i,
    option: 'tls1_' + i
  }))

  static webEncryptionCiphers = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.ciphers
      : constraints.filterSettingOptions(
          WebEncryptionItems.ciphers,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.ciphers
        )

  static tlsMinVersions = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.tlsVersions
      : constraints.filterSettingOptions(
          WebEncryptionItems.tlsVersions,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.minProtocolVersion
        )

  static tlsMaxVersions = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.tlsVersions
      : constraints.filterSettingOptions(
          WebEncryptionItems.tlsVersions,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.maxProtocolVersion
        )

  // Helpers

  static updateVersionDropbox = (
    value,
    enableCallback,
    optionPrefix,
    comparator
  ) => {
    const version = value.split('-')
    const index = +version[version.length - 1]
    const toDisable = []
    const toEnable = []
    for (let i = 0; i <= WebEncryptionItems.tlsVersions.length; i++) {
      comparator(i, index)
        ? toDisable.push(optionPrefix + i)
        : toEnable.push(optionPrefix + i)
    }
    enableCallback(toDisable, false)
    enableCallback(toEnable, true)
  }
}
