import { Item } from '../PoliciesConfiguration'

const generateColorObj = (color: string) => {
  return { value: 'color.' + color, label: 'policy.settings.colors.' + color }
}

export default class ColorItems {
  static allColors: Item[] = [
    generateColorObj('black'),
    generateColorObj('yellow'),
    generateColorObj('green'),
    generateColorObj('red'),
    generateColorObj('blue'),
    generateColorObj('light-blue'),
    generateColorObj('purple'),
    generateColorObj('white')
  ]

  static stampColors: Item[] = ColorItems.allColors.slice(0, -1) // except white

  static homeScreenAppFontColor: Item[] = [
    ColorItems.allColors[0],
    ColorItems.allColors[ColorItems.allColors.length - 1] // black and white
  ]
}
