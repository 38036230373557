import { PolicyActions } from 'context/types'
import cloneDeep from 'lodash/cloneDeep'

export default (state, action) => {
  switch (action.type) {
    case PolicyActions.GET_ALL_POLICIES:
      return {
        ...state,
        policies: action.payload,
        selectedPolicy: null,
        clonedPolicy: null,
        policyWarning: false,
        policyError: false
      }

    case PolicyActions.SET_SELECTED_POLICY:
      return {
        ...state,
        selectedPolicy: cloneDeep(action.payload),
        clonedPolicy: cloneDeep(action.payload),
        policyWarning: false,
        policyError: false
      }

    case PolicyActions.REMOVE_SELECTED_POLICY_ATTRIBUTE:
      if (state.selectedPolicy) {
        state.selectedPolicy.attributes =
          state.selectedPolicy.attributes.filter(
            (x) => x.name !== action.payload
          )
        return { ...state }
      }
      return state

    case PolicyActions.CHANGE_SELECTED_POLICY_ATTRIBUTE: {
      if (state.selectedPolicy) {
        const index = state.selectedPolicy.attributes.findIndex(
          (x) => x.name === action.payload.name
        )
        if (
          index >= 0 &&
          state.selectedPolicy.attributes[index] != action.payload
        ) {
          Object.assign(state.selectedPolicy.attributes[index], action.payload)
          return { ...state }
        }
      }
      return state
    }

    case PolicyActions.UNSELECT_POLICY:
      return {
        ...state,
        selectedPolicy: null,
        clonedPolicy: null,
        policyWarning: false,
        policyError: false
      }

    case PolicyActions.CREATE_HTTP_ERROR_HIDE:
      return { ...state, errorFlag: false }

    case PolicyActions.CREATE_HTTP_ERROR:
      return {
        ...state,
        ...action.payload,
        errorFlag: true
      }

    default:
      return state
  }
}
