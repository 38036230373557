import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

const mediaSize = (x, opt?) => {
  return {
    value: 'media-size.' + x,
    label: 'policy.settings.media-sizes.' + x,
    option: opt || x
  }
}

const mediaType = (x, hp: boolean | string = false) => {
  return {
    value: 'media-type.' + x,
    label: 'policy.settings.media-types.' + x,
    option: typeof hp === 'string' ? hp : hp ? 'com.hp.' + x : x
  }
}

export default class PrintMediaItems {
  static sizes: Item[] = [
    mediaSize('om-small-photo', 'om_small-photo_100x150mm'),
    mediaSize('ledger', 'na_ledger_11x17in'),
    mediaSize('om-16k-184x260', 'om_16k_184x260mm'),
    mediaSize('om-16k-195x270', 'om_16k_195x270mm'),
    mediaSize('roc-16k-197x273', 'roc_16k_7.75x10.75in'),
    mediaSize('a3', 'iso_a3_297x420mm'),
    mediaSize('a4', 'iso_a4_210x297mm'),
    mediaSize('a5', 'iso_a5_148x210mm'),
    mediaSize('a6', 'iso_a6_105x148mm'),
    mediaSize('any'),
    mediaSize('jis-b4', 'jis_b4_257x364mm'),
    mediaSize('jis-b5', 'jis_b5_182x257mm'),
    mediaSize('jis-b6', 'jis_b6_128x182mm'),
    mediaSize('number-10', 'na_number-10_4.125x9.5in'),
    mediaSize('number-9', 'na_number-9_3.875x8.875in'),
    mediaSize('b5', 'iso_b5_176x250mm'),
    mediaSize('c5', 'iso_c5_162x229mm'),
    mediaSize('c6', 'iso_c6_114x162mm'),
    mediaSize('dl', 'iso_dl_110x220mm'),
    mediaSize('monarch', 'na_monarch_3.875x7.5in'),
    mediaSize('executive', 'na_executive_7.25x10.5in'),
    mediaSize('index-3x5', 'na_index-3x5_3x5in'),
    mediaSize('index-4x6', 'na_index-4x6_4x6in'),
    mediaSize('5x7', 'na_5x7_5x7in'),
    mediaSize('index-5x8', 'na_index-5x8_5x8in'),
    mediaSize('jpn-chou3', 'jpn_chou3_120x235mm'),
    mediaSize('jpn-chou4', 'jpn_chou4_90x205mm'),
    mediaSize('jpn-oufuku', 'jpn_oufuku_148x200mm'),
    mediaSize('jpn-hagaki', 'jpn_hagaki_100x148mm'),
    mediaSize('legal', 'na_legal_8.5x14in'),
    mediaSize('letter', 'na_letter_8.5x11in'),
    mediaSize('oficio', 'na_oficio_8.5x13.4in'),
    mediaSize('foolscap', 'na_foolscap_8.5x13in'),
    mediaSize('personal', 'na_personal_3.625x6.5in'),
    mediaSize('ra4', 'iso_ra4_215x305mm'),
    mediaSize('sra4', 'iso_sra4_225x320mm'),
    mediaSize('invoice', 'na_invoice_5.5x8.5in')
  ]

  static types: Item[] = [
    mediaType('any'),
    mediaType('stationery-bond'),
    mediaType('cardstock-glossy', true),
    mediaType('cardstock'),
    mediaType('stationery-colored'),
    mediaType('envelope'),
    mediaType('extra-heavy', true),
    mediaType('extra-heavy-gloss', true),
    mediaType('stationery-heavyweight'),
    mediaType('envelope-heavyweight'),
    mediaType('heavy-glossy', true),
    mediaType('heavy-rough', true),
    mediaType('eco-smart-lite', 'com.hp.EcoSMARTLite'),
    mediaType('glossy-160gsm', true),
    mediaType('glossy-220gsm', true),
    mediaType('matte-90gsm', true),
    mediaType('matte-105gsm', true),
    mediaType('matte-120gsm', true),
    mediaType('matte-160gsm', true),
    mediaType('matte-200gsm', true),
    mediaType('intermediate', true),
    mediaType('labels'),
    mediaType('stationery-letterhead'),
    mediaType('stationery-lightweight'),
    mediaType('midweight', true),
    mediaType('film-opaque', true),
    mediaType('stationery'),
    mediaType('stationery-preprinted'),
    mediaType('stationery-prepunched'),
    mediaType('recycled', true),
    mediaType('rough', true)
  ]

  static mediaSizes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? PrintMediaItems.sizes
      : constraints.filterSettingOptions(
          PrintMediaItems.sizes,
          ConstraintsResourceEnum.jobTicketCfgDefPrint,
          ConstraintsSettingEnum.destPrintMediaSize
        )

  static mediaTypes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? PrintMediaItems.types
      : constraints.filterSettingOptions(
          PrintMediaItems.types,
          ConstraintsResourceEnum.jobTicketCfgDefPrint,
          ConstraintsSettingEnum.destPrintMediaType
        )
}
