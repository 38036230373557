import React, { useContext, memo, useEffect } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import { ErrorDescription } from 'context/policies/PoliciesConfiguration'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'
import WarningMessage from 'components/policies/settings/attributes/device/controls/WarningMessage'

const ErrorControl = (props) => {
  const description: ErrorDescription = props.description
  const { id, localizationPath } = props
  const { attribute, error, params } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addError, removeError, displayAllErrors } =
    useContext(settingsContext)

  const enabled = isEnabled(attribute)
  useEffect(() => {
    enabled
      ? addError(id, attribute, displayAllErrors)
      : removeError(id, attribute)
  }, [enabled, displayAllErrors])

  return (
    displayAllErrors &&
    enabled && (
      <WarningMessage
        id={id + '.error'}
        message={tt(localizationPath, error, params)}
        type={MessageTypesEnum.ERROR}
      />
    )
  )
}

export default memo(ErrorControl)
