export enum QuickSetModalMode {
  NONE,
  ADD = 'add',
  EDIT = 'edit',
  COPY = 'copy'
}

export enum QuickSetsEnum {
  TITLE = 'title',
  TYPE = 'type',
  TYPE_EMAIL = 'scan-to-email',
  DESCRIPTION = 'description',
  START = 'start',
  MESSAGE = 'message',
  FILE = 'file'
}

export enum QuickSetsMessageEnum {
  RESTRICTIONS = 'restrictions',
  ALLOW_INVALID_EMAIL = 'allow-invalid-email',
  SIGN = 'sign',
  ENCRYPT = 'encrypt',
  ADDRESSES = '-addresses'
}

export enum QuickSetsFileEnum {
  FILE_NAME = 'name', // for tracking errors only
  QUALITY = 'quality',
  TYPE = 'type',
  RESOLUTION = 'resolution',
  COMPRESSION = 'compression',
  COMPRESSION_BLACK = 'compression-black',
  COMPRESSION_COLOR = 'compression-color',
  MEDIA_SIZE = 'media-size',
  PDF_ENCRYPTION = 'pdf-encryption',
  BLANK_PAGE_SUPPRESSION = 'blank-page-suppression',
  COLOR_PREFERENCE = 'color-preference'
}
