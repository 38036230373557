import React from 'react'
import uuid from 'react-uuid'

export function getDecoratedName(name, searchValue, searchToken) {
  const decoratedName = []
  if (searchValue?.length) {
    const len = searchValue.length
    const nameLowerCase = name.toLowerCase()
    const token = (text) => (
      <span key={uuid()} className={searchToken}>
        {text}
      </span>
    )
    const process = (text) => text.replace(/ /g, '\u00a0')

    let i = 0
    for (let j; (j = nameLowerCase.indexOf(searchValue, i)) >= 0; ) {
      decoratedName.push(process(name.substr(i, j - i)))
      decoratedName.push(token(process(name.substr(j, len))))
      i = j + len
    }
    decoratedName.push(process(name.substr(i)))
  }
  return decoratedName
}
