import { Item } from '../PoliciesConfiguration'

const emailNotificationCondition = (x) => {
  return {
    value: 'notification-condition.' + x,
    label: 'policy.settings.notification-condition.' + x
  }
}

const emailNotificationMethod = (x) => {
  return {
    value: 'notification-method.' + x,
    label: 'policy.settings.notification-method.' + x
  }
}

export default class PrintEmailNotificationItems {
  static emailNotificationConditions: Item[] = [
    emailNotificationCondition('none'),
    emailNotificationCondition('fail'),
    emailNotificationCondition('done')
  ]
  static emailNotificationMethods: Item[] = [
    emailNotificationMethod('print'),
    emailNotificationMethod('email')
  ]
}
