import React, { memo } from 'react'
import { InlineNotification } from '@veneer/core'
import PoliciesGenerator from 'context/policies/PoliciesGenerator'
import SecurityAttributes from './security'
import DeviceAttributes from './device'
import VerticalDivider from 'common/controls/divider'
import { PolicyCategoryEnum } from 'context/policies/PoliciesCategories'
import { AccordionContent, FlexRow } from 'styles/styles'
import HelpButton from './HelpButton'
import 'styles/global.scss'

const PolicyAttribute = (props) => {
  const generateDescription = (help, description) => {
    return help ? (
      <>
        {description}
        <HelpButton
          enabled
          placement={'top'}
          portal
          description={<div className={'lineBreak'}>{help}</div>}
          className={'marginLeft4'}
        />
      </>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: description }} />
    )
  }

  return (
    <AccordionContent>
      {props.notifications &&
        props.notifications.map((x, i) => (
          <div
            className={'lineBreak marginBottom16'}
            key={`${props.item.name}-inline-${i}`}
          >
            <InlineNotification
              closeButton={false}
              hideIcon={false}
              title={x.title}
              type={x.type}
              description={generateDescription(x.help, x.description)}
            />
          </div>
        ))}
      <FlexRow>
        <div className="securityAttributes">
          <SecurityAttributes
            attribute={props.item}
            severity={
              // show Severity only for Security attributes
              props.description.categories[0] === PolicyCategoryEnum.Security
            }
            onChange={props.preview ? null : props.onAttributeChange}
          />
        </div>
        <VerticalDivider />
        <div className="deviceAttributes">
          <DeviceAttributes
            deviceSettingForms={
              props.preview
                ? PoliciesGenerator.generateDeviceSettingsPreview(
                    props.item,
                    props.description
                  )
                : PoliciesGenerator.generateDeviceSettings(
                    props.item,
                    props.description,
                    props.attributes,
                    props.onAttributeChange,
                    props.onSettingsChanges
                  )
            }
          />
        </div>
      </FlexRow>
    </AccordionContent>
  )
}

export default memo(PolicyAttribute)
