import { SelectI18n } from '@veneer/core'
import { useContext, useMemo } from 'react'
import configContext from 'context/config/configContext'

const localizationPath = 'i18n.select.'

const Retrievei18nSelect = (): SelectI18n => {
  const { t } = useContext(configContext)

  return useMemo(() => {
    return {
      clear: t(localizationPath + 'clear'),
      noResults: t(localizationPath + 'no-results'),
      open: t(localizationPath + 'open'),
      searchPlaceholder: t(localizationPath + 'search-placeholder'),
      selected: t(localizationPath + 'selected'),
      showingResult: t(localizationPath + 'showing-result'),
      showingResults: t(localizationPath + 'showing-results'),
      unselected: t(localizationPath + 'unselected')
    }
  }, [])
}

export default Retrievei18nSelect
