import React from 'react'
import { Card, ProgressIndicator, IconWarningAlt } from '@veneer/core'
import { FlexColumn, TextBlackH } from 'styles/styles'
import './index.scss'

const NoItemsCard = (props) => {
  const { title, description, indicator, noCard } = props

  const noItems = (
    <FlexColumn className={'policySettingsNoItem'}>
      {indicator ? (
        <ProgressIndicator />
      ) : (
        <>
          <FlexColumn className={'policyNoItemsIcon'}>
            <IconWarningAlt size={24} color={'gray10'} />
          </FlexColumn>
          <TextBlackH>{title}</TextBlackH>
          <TextBlackH>{description}</TextBlackH>
        </>
      )}
    </FlexColumn>
  )

  return noCard ? (
    noItems
  ) : (
    <Card id={'policy-no-items-card'} content={noItems} border={'dropShadow'} />
  )
}

export default NoItemsCard
