import React, { useContext } from 'react'
import configContext from 'context/config/configContext'
import { Select } from '@veneer/core'
import { TextBlack, PreviewValue } from 'styles/styles'
import { MOC } from 'common/mocParser/MocUtils'

const AppDeploymentList = (props) => {
  const { tt } = useContext(configContext)
  const {
    fId,
    sId,
    set,
    error,
    preview,
    disabled,
    helperText,
    configState,
    textBlackStyle,
    localizationPath,
    updateStateValue
  } = props

  const getLocalized = (key: string, params?): string => {
    return tt(localizationPath, key, params)
  }

  const createPreviewListItem = (fId, sId, options) => {
    const selectValue = Object.keys(
      configState?.[fId]?.[sId]?.[MOC.VALUES]?.[0] || {}
    )[0]
    const previewValue = options.find((item) => {
      return Object.keys(item)[0] === selectValue
    })
    return Object.values(previewValue)[0]
  }

  return (
    <>
      <TextBlack disabled={disabled} className={textBlackStyle}>
        {set[MOC.LABEL]}
      </TextBlack>
      {preview ? (
        <PreviewValue>
          {createPreviewListItem(fId, sId, set[MOC.OPTIONS])}
        </PreviewValue>
      ) : (
        <Select
          id={set[MOC.NAME]}
          options={set[MOC.OPTIONS].map((item) => {
            return {
              value: Object.keys(item)[0],
              label: Object.values(item)[0]
            }
          })}
          onChange={({ value }) => {
            const valueToSet = {
              [fId]: { [sId]: { [MOC.VALUES]: [{ [value]: true }] } }
            }
            updateStateValue(valueToSet)
          }}
          value={[
            Object.keys(configState?.[fId]?.[sId]?.[MOC.VALUES]?.[0] || {})[0]
          ]}
          clearIcon={false}
          className={'maxTextWidth'}
          placeholder={getLocalized('common.select-option')}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    </>
  )
}
export default AppDeploymentList
