import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  Modal,
  Search,
  Table,
  ButtonGroup,
  Button,
  Scrollbar,
  ColumnIndexTypes,
  TableSortBy,
  SortTypes
} from '@veneer/core'
import CollectionsContext from 'context/devices/collectionsContext'
import policiesContext from 'context/policies/policiesContext'
import configContext from 'context/config/configContext'
import { abcSort, findPredefinedGroup } from 'common/utilities'
import { getDecoratedName } from 'common/decoratedName'
import { TABLE_CONSTANTS } from 'common/utilities'
import { cssModalTableHeight } from 'styles/styles'

const ReferenceDeviceModal = (props) => {
  const uid = 'uid'
  const modelName = 'modelName'
  const serialNumber = 'serialNumber'
  const ipAddress = 'ipAddress'
  const deviceName = 'deviceName'
  const group = 'group'
  const defSortOrder: SortTypes = 'ascending'
  const defSorting: TableSortBy = {
    id: modelName,
    type: defSortOrder
  }

  const { onChange, onClose } = props
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [sorting, setSorting] = useState(defSorting)
  const [searchValue, setSearchValue] = useState('')
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const [allCollectionId, setAllCollectionId] = useState(null)
  const [pageSize, setPageSize] = useState(25)
  const [totalDeviceItems, setTotalDeviceItems] = useState(0)

  const { tt } = useContext(configContext)

  const {
    getAllCollection,
    collections,
    paginatedDevices,
    getPaginatedDevicesByCollection
  } = useContext(CollectionsContext)

  const { showError } = useContext(policiesContext)

  const getLocalized = (key: string, params?) =>
    tt(`policy.settings.reference-device`, key, params)

  const displayError = (error) => showError(error)

  useEffect(() => {
    getAllCollection(displayError)
  }, [])

  useEffect(() => {
    if (collections && !allCollectionId) {
      const allCollection = findPredefinedGroup(collections, 'All')
      if (allCollection) {
        setAllCollectionId(allCollection.id)
        setTotalDeviceItems(allCollection.devices)
      }
    }
  }, [collections])

  useEffect(() => {
    if (allCollectionId) {
      getPaginatedDevicesByCollection(
        allCollectionId,
        currentPage,
        pageSize,
        undefined,
        undefined,
        displayError
      )
    }
  }, [allCollectionId, currentPage, pageSize])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: uid, label: 'ID', index },
      {
        id: modelName,
        label: getLocalized('model-name'),
        sortable: true
      },
      {
        id: serialNumber,
        label: getLocalized('serial-number'),
        sortable: true
      },
      {
        id: ipAddress,
        label: getLocalized('ip-address'),
        sortable: true
      },
      {
        id: deviceName,
        label: getLocalized('device-name'),
        sortable: true
      },
      {
        id: group,
        label: getLocalized('group'),
        sortable: true
      }
    ]
  }, [])

  const handlePageChange = (page) => {
    setCurrentPage(page - 1)
  }

  const handlePageSizeChange = (_, option) => setPageSize(option.value)

  const buildRowDataForTable = (item) => {
    return {
      ...item,
      deviceId: item.deviceId,
      modelName: item.modelName,
      serialNumber: item.serialNumber,
      ipAddress: item.ipAddress,
      deviceName: item.deviceName,
      group: item.group
    }
  }

  useEffect(() => {
    let tableDevices = null
    if (paginatedDevices) {
      tableDevices = paginatedDevices.map((device, index) => {
        const adapterIpv4Enabled = device.network?.adapters.find(
          (adapter) => adapter.ipv4 && adapter.ipv4.enabled
        )
        return {
          uid: index,
          deviceId: device.deviceId,
          modelName: device.identity.makeAndModel.name
            ? device.identity.makeAndModel.name
            : TABLE_CONSTANTS.NO_DATA,
          serialNumber: device.identity.serialNumber
            ? device.identity.serialNumber
            : TABLE_CONSTANTS.NO_DATA,
          ipAddress: adapterIpv4Enabled
            ? adapterIpv4Enabled.ipv4.address.ip
            : TABLE_CONSTANTS.NO_DATA,
          deviceName: device.identity.friendlyName
            ? device.identity.friendlyName
            : TABLE_CONSTANTS.NO_DATA,
          group: getGroupsText(device.groups),
          rowConfig: { selected: false }
        }
      })
    }
    setTableData(tableDevices)
  }, [paginatedDevices])

  const getGroupsText = (groups) => {
    if (!groups || groups.length === 0) {
      return TABLE_CONSTANTS.NO_DATA
    }
    return groups?.map((group) => group.label).join(', ')
  }

  useEffect(() => {
    const selected = tableData
      ? tableData.filter((row) => row.rowConfig.selected)
      : []
    setSaveButtonDisabled(selected.length !== 1)
  }, [tableData])

  const handleSaveButtonAction = () => {
    const selected = tableData.find(({ rowConfig }) => rowConfig.selected)
    onChange({ deviceId: selected.deviceId })
    onClose()
  }

  const filteredData = useMemo(() => {
    const searchLowerCase =
      searchValue === undefined ? '' : searchValue.toLowerCase()
    let filteredData = null
    if (tableData) {
      if (searchLowerCase.length) {
        filteredData = tableData.filter(
          (item) =>
            item.modelName.toLowerCase().includes(searchLowerCase) ||
            item.serialNumber.toLowerCase().includes(searchLowerCase) ||
            item.ipAddress.toLowerCase().includes(searchLowerCase) ||
            item.deviceName.toLowerCase().includes(searchLowerCase) ||
            item.group.toLowerCase().includes(searchLowerCase)
        )
      } else {
        filteredData = tableData
      }
    }
    return filteredData
  }, [tableData, searchValue])

  const getDataForTable = (item, search) => {
    const getDecoratedText = (text) => {
      const decoratedName = getDecoratedName(text, search, 'searchTokenBlue')
      return decoratedName.length ? decoratedName : text
    }
    return {
      ...item,
      modelName: getDecoratedText(item.modelName),
      serialNumber: getDecoratedText(item.serialNumber),
      ipAddress: getDecoratedText(item.ipAddress),
      deviceName: getDecoratedText(item.deviceName),
      group: getDecoratedText(item.group)
    }
  }

  const pagedTableData = useMemo(() => {
    // Sorting
    const sortedData = (data) =>
      abcSort(
        [...data],
        (x) => {
          switch (sorting.id) {
            case modelName:
              return x.modelName
            case serialNumber:
              return x.serialNumber
            case ipAddress:
              return x.ipAddress
            case deviceName:
              return x.deviceName
            case group:
              return x.group
            default:
              return x.deviceName
          }
        },
        sorting.type
      ).map((item) => buildRowDataForTable(item))

    // Paged
    const searchLowerCase = searchValue.toLowerCase()
    return !filteredData
      ? null
      : [...sortedData(filteredData)].map((item) =>
          getDataForTable(item, searchLowerCase)
        )
  }, [filteredData, sorting, searchValue])

  const onTableClick = (me) => {
    if (pagedTableData) {
      document.querySelectorAll(`.hideFirstCol tbody tr`).forEach((tr, i) => {
        const rect = tr.getBoundingClientRect()
        if (me.clientY >= rect.y && me.clientY < rect.bottom) {
          const rowId = pagedTableData[i].uid
          tableData.forEach(
            (row) => (row.rowConfig.selected = row.uid === rowId)
          )
          setTableData([...tableData])
        }
      })
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('select-device')}
      className={'extra-large-policy-modal'}
      data-testid={'id-reference-device-modal'}
      footer={
        <ButtonGroup>
          <Button
            id={'selectBtn'}
            onClick={handleSaveButtonAction}
            disabled={saveButtonDisabled}
          >
            {getLocalized('common.select')}
          </Button>
          <Button id={'cancelBtn'} appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'paddingBottom16'}>{getLocalized('modal-description')}</p>
      <Search
        id={'device-search'}
        placeholder={'Search'}
        className={'maxSearchWidth paddingBottom16'}
        value={searchValue}
        onChange={(value) => setSearchValue(value)}
      />
      <Scrollbar customStyle={cssModalTableHeight}>
        <div
          className={'hideFirstCol'}
          onClick={onTableClick}
          data-testid={'id-reference-device-table-container'}
        >
          <Table
            columns={tableColumns}
            data={pagedTableData || []}
            onSort={(_, sortBy) => setSorting(sortBy)}
            loading={pagedTableData === null}
            loadingDataLength={pageSize}
            rowSelector={'multiSelection'}
            pagination={{
              currentPage: currentPage + 1,
              pageSize,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
              totalItems: totalDeviceItems,
              pageSizeOptions: [
                { value: 5 },
                { value: 25 },
                { value: 50 },
                { value: 100 },
                { value: 500 }
              ]
            }}
            preferences={{
              sortBy: sorting,
              width: [
                { columnId: modelName, width: 28 },
                { columnId: serialNumber, width: 12 },
                { columnId: ipAddress, width: 12 },
                { columnId: deviceName, width: 28 },
                { columnId: group, width: 20 }
              ]
            }}
            className={'widthColAuto'}
            data-testid={'id-reference-device-table'}
          />
        </div>
      </Scrollbar>
    </Modal>
  )
}

export default ReferenceDeviceModal
