export enum SettingsActions {
  ADD_WARNING,
  REMOVE_WARNING,
  ADD_ERROR,
  DISPLAY_ALL_ERRORS,
  REMOVE_ERROR,
  ADD_DISABLED,
  REMOVE_DISABLED,
  CLEAN_UP
}

export enum PolicyActions {
  GET_ALL_POLICIES,
  SET_SELECTED_POLICY,
  REMOVE_SELECTED_POLICY_ATTRIBUTE,
  CHANGE_SELECTED_POLICY_ATTRIBUTE,
  UNSELECT_POLICY,
  CREATE_HTTP_ERROR,
  CREATE_HTTP_ERROR_HIDE
}

export enum SolutionActions {
  SET_SOLUTIONS,
  SET_ICONS,
  SET_MOC
}

export enum CollectionsActions {
  GET_ALL_COLLECTION,
  GET_COLLECTION_PAGINATED_DEVICES
}
