import React, { useContext, useEffect, useState } from 'react'
import { Card } from '@veneer/core'
import LabelTextBox from 'common/controls/labelTextBox'
import configContext from 'context/config/configContext'
import './index.scss'

const PolicyDetailsEditForm = ({
  selectedPolicy,
  onNameChange,
  onDescriptionChange
}) => {
  const cContext = useContext(configContext)
  const { t } = cContext

  const [policyName, setPolicyName] = useState('')
  const [policyDescription, setPolicyDescription] = useState('')

  useEffect(() => {
    setPolicyName(selectedPolicy?.name || '')
    setPolicyDescription(selectedPolicy?.description || '')
  }, [selectedPolicy])

  const inputForm = () => {
    const key = (subkey) => t(`policy.create.${subkey}`)
    const errorPolicyName = selectedPolicy && !policyName
    return (
      <div className="create-modal-form">
        <LabelTextBox
          required={true}
          className="marginBottom16"
          label={key('step1.textboxLabel')}
          id="textboxLabel"
          value={policyName}
          onChange={(value) => {
            setPolicyName(value)
            onNameChange(value)
          }}
          placeholder={key('step1.textboxPlaceholder')}
          error={errorPolicyName}
          helperText={key(
            errorPolicyName ? 'step1.textboxError' : 'step1.textboxPlaceholder'
          )}
        />
        <LabelTextBox
          label={key('step1.textAreaLabel')}
          id="textAreaLabel"
          value={policyDescription}
          onChange={(value) => {
            setPolicyDescription(value)
            onDescriptionChange(value)
          }}
          placeholder={key('step1.textAreaPlaceholder')}
          className="textAreaHeight"
          textArea={true}
        />
      </div>
    )
  }

  return <Card content={inputForm()} border="dropShadow" />
}

export default PolicyDetailsEditForm
