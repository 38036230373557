import React from 'react'
import { GrantControllerSvcClient } from '@jarvis/web-stratus-client'
import GrantsContext from 'context/grants/grantsContext'

const GrantsProvider = (props) => {
  const { stack, authProvider } = props
  const grantClient = new GrantControllerSvcClient(stack, authProvider)

  const hasEntitlement = async (
    grant: string,
    setResult: (result: boolean) => never
  ) => {
    grantClient
      .getGrants({ grant })
      .then((grants) =>
        setResult(grants?.status == 200 && grants.data?.contents?.length > 0)
      )
      .catch(() => setResult(false))
  }

  return (
    <GrantsContext.Provider
      value={{
        grantClient: grantClient,
        hasEntitlement
      }}
    >
      {props.children}
    </GrantsContext.Provider>
  )
}

export default GrantsProvider
