import React, { useContext, useReducer } from 'react'
import { CollectionClient, Stack } from '@jarvis/web-stratus-client'
import { CollectionsActions } from 'context/types'
import CollectionsContext from 'context/devices/collectionsContext'
import CollectionsReducer from 'context/devices/CollectionsReducer'
import ConfigContext from 'context/config/configContext'
import { errorProcessor } from 'context/policies/errorProcessor'

const CollectionsProvider = (props) => {
  const { t } = useContext(ConfigContext)
  const initialState = {
    collections: null,
    paginatedDevices: null
  }
  const [state, dispatch] = useReducer(CollectionsReducer, initialState)
  const stackFromShell = props.stack ? props.stack : Stack.dev

  const collectionClient = new CollectionClient(
    stackFromShell,
    props.authProvider
  )

  const offset = 0
  const limit = -1

  const displayCollections = (payload) => {
    dispatch({ type: CollectionsActions.GET_ALL_COLLECTION, payload })
  }

  const displayPaginatedDevices = (payload) => {
    dispatch({
      type: CollectionsActions.GET_COLLECTION_PAGINATED_DEVICES,
      payload
    })
  }

  const getAllCollection = async (displayError) => {
    try {
      const res = await collectionClient.getCollections(
        offset,
        limit,
        undefined,
        'All',
        undefined,
        undefined,
        undefined,
        true
      )
      if (res.status && res.status === 200) {
        displayCollections(res.data.contents)
      }
    } catch (error) {
      displayCollections({ contents: [] })
      displayError(errorProcessor(error, t).error.message)
    }
  }

  const getPaginatedDevicesByCollection = async (
    collectionId,
    currentPage,
    pageSize,
    sortBy,
    filter,
    displayError
  ) => {
    displayPaginatedDevices(null)
    try {
      const res = await collectionClient.getTypedCollectionById(
        collectionId,
        'devices',
        currentPage,
        pageSize,
        sortBy,
        filter,
        true
      )
      if (res.status && res.status === 200) {
        displayPaginatedDevices(res.data.contents)
      }
    } catch (error) {
      displayPaginatedDevices({ contents: [] })
      displayError(errorProcessor(error, t).error.message)
    }
  }

  return (
    <CollectionsContext.Provider
      value={{
        collections: state.collections,
        paginatedDevices: state.paginatedDevices,
        getAllCollection,
        getPaginatedDevicesByCollection
      }}
    >
      {props.children}
    </CollectionsContext.Provider>
  )
}

export default CollectionsProvider
